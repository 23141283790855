import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CashControl from './CashControl';
import InvestorProfileControl from './InvestorProfileControl';
import PensionConfirmation from './PensionConfirmation';
import PortfolioCurrencySelect from './PortfolioCurrencySelect';
import PortfolioNameChange from './PortfolioNameChange';
import SkipRebalancing from './SkipRebalancing';

import styles from './PortfolioSettings.module.scss';

export default function PortfolioSettings({
	country,
	currencies,
	currencyChange,
	isChild,
	openTopUpModal,
	portfolio,
	portfolioSettingsAnchor,
	setCheckPortfolio,
	setCurrencyChange,
}) {
	const [t] = useTranslation();

	if (portfolio === null) {
		return null;
	}

	const isPension = portfolio.categoryName === 'pension';

	return (
		<>
			<h2 className={styles.title} id={portfolioSettingsAnchor}>{t('portfolio.settings.title')}</h2>
			<div className={styles.box}>
				<PortfolioNameChange
					portfolioId={portfolio.id}
					portfolioName={portfolio.name}
					setCheckPortfolio={setCheckPortfolio}
				/>
				<InvestorProfileControl
					isChild={isChild}
					portfolio={portfolio}
					setCheckPortfolio={setCheckPortfolio}
				/>
				<CashControl
					closed={portfolio.keepCashRatioClosedChange}
					isChild={isChild}
					planned={portfolio.keepCashRatioPlanned}
					portfolioId={portfolio.id}
					ratio={portfolio.keepCashRatio}
					setCheckPortfolio={setCheckPortfolio}
				/>
				{currencies !== null && (
					<PortfolioCurrencySelect
						currencies={currencies}
						currencyChange={currencyChange}
						isChild={isChild}
						openTopUpModal={openTopUpModal}
						portfolioId={portfolio.id}
						setCheckPortfolio={setCheckPortfolio}
						setCurrencyChange={setCurrencyChange}
					/>
				)}
				<SkipRebalancing
					closed={portfolio.skipRebalancingClosedChange}
					isChild={isChild}
					portfolioId={portfolio.id}
					skipRebalancing={portfolio.skipRebalancing}
				/>
				{isPension
					&& country === 'CZ'
					&& (
						<PensionConfirmation
							hasEmployerDeposit={portfolio.pensionEmployerDepositConfirmation}
							portfolioId={portfolio.id}
						/>
					)}
			</div>
		</>
	);
}

PortfolioSettings.propTypes = {
	country: PropTypes.string.isRequired,
	currencies: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}).isRequired),
	currencyChange: PropTypes.bool,
	isChild: PropTypes.bool,
	openTopUpModal: PropTypes.func.isRequired,
	portfolio: PropTypes.shape({
		categoryName: PropTypes.string.isRequired,
		faceImageFile: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
		investorProfileClosedChange: PropTypes.bool.isRequired,
		investorProfileId: PropTypes.number.isRequired,
		investorProfilePlannedId: PropTypes.number.isRequired,
		keepCashRatio: PropTypes.number.isRequired,
		keepCashRatioClosedChange: PropTypes.bool.isRequired,
		keepCashRatioPlanned: PropTypes.number.isRequired,
		name: PropTypes.string,
		pensionEmployerDepositConfirmation: PropTypes.bool.isRequired,
		skipRebalancing: PropTypes.bool.isRequired,
		skipRebalancingClosedChange: PropTypes.bool.isRequired,
	}),
	portfolioSettingsAnchor: PropTypes.string.isRequired,
	setCheckPortfolio: PropTypes.func,
	setCurrencyChange: PropTypes.func,
};

PortfolioSettings.defaultProps = {
	currencies: null,
	currencyChange: false,
	isChild: false,
	portfolio: null,
	setCheckPortfolio: null,
	setCurrencyChange: null,
};
