/* global document, window */
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { ErrorBoundary } from '@sentry/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import {
	Navigate,
	Route,
	Routes,
	useLocation,
	useSearchParams,
} from 'react-router-dom';

import {
	ChildAgeCheck,
	CookieConsent,
	Footer,
	Header,
	Meta,
	PersonalInformationCheck,
	Survey,
	UserCheck,
	VisitorCountryCheck,
} from './components/common';
import { clientZonePages } from './components/common/UserCheck';
import { useCountry, useLanguage } from './contexts/LocaleContext';
import { PageProvider } from './contexts/PageContext';
import { UserProvider } from './contexts/UserContext';
import {
	Account,
	Blog,
	ChildProduct,
	ClassicProduct,
	ClientCheck,
	Contact,
	Cookies,
	Dashboard,
	DocumentsPage,
	EmailAlias,
	EmailVerification,
	EmailVerificationResponse,
	Error,
	Error404,
	EsgProduct,
	Faq,
	Fees,
	ForgotPassword,
	History,
	Homepage,
	HowItWorks,
	Login,
	LoginSocial,
	Newspaper,
	Offers,
	Onboarding,
	OnboardingChild,
	OnboardingParent,
	OurApproach,
	PensionProduct,
	Portfolio,
	PrivacyPolicy,
	Referrals,
	Registration,
	ResetPassword,
	SignAgreement,
	SignNewAgreement,
	StudentProduct,
	Taxes,
	Unsubscribe,
} from './pages';
import childRoute from './utils/childRoute';
import { getCookieConsentValue } from './utils/cookieConsent';
import GTMProvider, { TrackGTMPageview } from './utils/GTMProvider';
import { pageRoute, pageUrl, subpageUrl } from './utils/routes';
import SmoothScroll from './utils/SmoothScroll';
import { validateEmail } from './utils/validators';

const cookieConsentActive = getCookieConsentValue() === null;

const pages = (
	baseUrl,
	url,
	subUrl,
	t,
	blogData,
	code,
	email,
	social,
	affiliate,
	referral,
	setShowCookies,
) => ({
	account: (
		<Account
			childOnboardingUrl={url('onboardingChild')}
			clientCheckUrl={url('clientCheck')}
			faqUrl={url('faq')}
			portfolioUrl={url('portfolio')}
			conditionsUrl={t('documentUrls.conditions')}
			termsUrl={t('documentUrls.terms')}
			personalInfoAgreementUrl={t('documentUrls.personalInfoAgreement')}
			informationSheetUrl={t('documentUrls.informationSheet')}
			complaintsProcedureUrl={t('documentUrls.complaintsProcedure')}
			annualReportUrl={t('documentUrls.annualReport')}
			annualReportTemplatesUrl={t('documentUrls.annualReportTemplates')}
			esgInfolistUrl={t('documentUrls.esgInfolist')}
			esgSustainabilityUrl={t('documentUrls.esgSustainability')}
			registrationUrl={url('registration')}
		/>
	),
	blog: url('blog') !== null ? (
		<Blog
			baseUrl={baseUrl}
			blogUrl={url('blog')}
			blogPostUrl={(slug) => url('blog', slug)}
			blogPostETFUrl={subUrl('blog', 'etf')}
			feesUrl={url('fees')}
			homepageUrl={url('homepage')}
			howItWorksUrl={url('howItWorks')}
			prefetchedData={blogData}
			registrationUrl={url('registration')}
		/>
	) : null,
	childProduct: (
		<ChildProduct
			blogPostEsgUrl={subUrl('blog', 'esg')}
			blogPostEsgComparisonUrl={subUrl('blog', 'esgComparison')}
			blogPostEsgVolatilityUrl={subUrl('blog', 'esgVolatility')}
			blogPostInflationUrl={subUrl('blog', 'inflation')}
			classicProductUrl={url('classicProduct')}
			esgProductUrl={url('esgProduct')}
			howItWorksUrl={url('howItWorks')}
			pensionProductUrl={url('pensionProduct')}
			registrationUrl={url('registration')}
			studentProductUrl={url('studentProduct')}
		/>
	),
	classicProduct: (
		<ClassicProduct
			blogPostEsgUrl={subUrl('blog', 'esg')}
			blogPostEsgComparisonUrl={subUrl('blog', 'esgComparison')}
			blogPostEsgVolatilityUrl={subUrl('blog', 'esgVolatility')}
			blogPostInflationUrl={subUrl('blog', 'inflation')}
			childProductUrl={url('childProduct')}
			esgProductUrl={url('esgProduct')}
			howItWorksUrl={url('howItWorks')}
			pensionProductUrl={url('pensionProduct')}
			registrationUrl={url('registration')}
			studentProductUrl={url('studentProduct')}
		/>
	),
	clientCheck: (
		<ClientCheck
			accountUrl={url('account')}
			blogPostEsgRegulationUrl={subUrl('blog', 'esgRegulation')}
			faqUrl={url('faq')}
		/>
	),
	contact: (
		<Contact
			personalInfoAgreementUrl={t('documentUrls.personalInfoAgreement')}
			registrationUrl={url('registration')}
			termsUrl={t('documentUrls.terms')}
		/>
	),
	cookies: (
		<Cookies
			personalInfoAgreementUrl={t('documentUrls.personalInfoAgreement')}
			registrationUrl={url('registration')}
			setShowCookies={setShowCookies}
		/>
	),
	dashboard: (
		<Dashboard
			accountUrl={url('account')}
			childOnboardingUrl={url('onboardingChild')}
			conditionsUrl={t('documentUrls.conditions')}
			faqUrl={url('faq')}
			parentOnboardingUrl={url('onboardingParent')}
			portfolioUrl={url('portfolio')}
			signNewAgreementUrl={url('signNewAgreement')}
		/>
	),
	documents: (
		<DocumentsPage
			conditionsUrl={t('documentUrls.conditions')}
			termsUrl={t('documentUrls.terms')}
			personalInfoAgreementUrl={t('documentUrls.personalInfoAgreement')}
			informationSheetUrl={t('documentUrls.informationSheet')}
			complaintsProcedureUrl={t('documentUrls.complaintsProcedure')}
			esgInfolistUrl={t('documentUrls.esgInfolist')}
			esgSustainabilityUrl={t('documentUrls.esgSustainability')}
			registrationUrl={url('registration')}
		/>
	),
	emailAlias: social !== '' ? (
		<EmailAlias
			dashboardUrl={url('dashboard')}
			registrationUrl={url('registration')}
			social={social}
		/>
	) : <Navigate to={url('registration')} />,
	emailVerification: code !== '' && email !== '' ? (
		<EmailVerificationResponse
			code={code}
			dashboardUrl={url('dashboard')}
			email={email}
			registrationCompletionUrl={url('onboarding')}
		/>
	) : <EmailVerification />,
	esgProduct: (
		<EsgProduct
			blogPostEsgUrl={subUrl('blog', 'esg')}
			blogPostEsgComparisonUrl={subUrl('blog', 'esgComparison')}
			blogPostEsgVolatilityUrl={subUrl('blog', 'esgVolatility')}
			blogPostInflationUrl={subUrl('blog', 'inflation')}
			childProductUrl={url('childProduct')}
			classicProductUrl={url('classicProduct')}
			howItWorksUrl={url('howItWorks')}
			pensionProductUrl={url('pensionProduct')}
			registrationUrl={url('registration')}
			studentProductUrl={url('studentProduct')}
		/>
	),
	faq: <Faq />,
	fees: <Fees
		blogPostETFUrl={subUrl('blog', 'etf')}
		registrationUrl={url('registration')}
	/>,
	forgotPassword: <ForgotPassword />,
	history: (
		<History
			accountUrl={url('account')}
			childOnboardingUrl={url('onboardingChild')}
			conditionsUrl={t('documentUrls.conditions')}
			faqUrl={url('faq')}
			parentOnboardingUrl={url('onboardingParent')}
			signNewAgreementUrl={url('signNewAgreement')}
		/>
	),
	homepage: (
		<Homepage
			childProductUrl={url('childProduct')}
			classicProductUrl={url('classicProduct')}
			esgProductUrl={url('esgProduct')}
			howItWorksUrl={url('howItWorks')}
			pensionProductUrl={url('pensionProduct')}
			registrationUrl={url('registration')}
			studentProductUrl={url('studentProduct')}
		/>
	),
	howItWorks: <HowItWorks
		blogPostETFUrl={subUrl('blog', 'etf')}
		childProductUrl={url('childProduct')}
		classicProductUrl={url('classicProduct')}
		esgProductUrl={url('esgProduct')}
		ourApproachUrl={url('ourApproach')}
		registrationUrl={url('registration')}
	/>,
	login: social !== '' ? (
		<LoginSocial
			dashboardUrl={url('dashboard')}
			social={social}
		/>
	) : (
		<Login
			dashboardUrl={url('dashboard')}
			forgotPasswordUrl={url('forgotPassword')}
			registrationUrl={url('registration')}
		/>
	),
	newspaper: <Newspaper baseUrl={baseUrl} />,
	offers: <Offers />,
	onboarding: (
		<Onboarding
			blogPostEsgRegulationUrl={subUrl('blog', 'esgRegulation')}
			conditionsUrl={t('documentUrls.conditions')}
			dashboardUrl={url('dashboard')}
			documentsUrl={url('documents')}
			emailVerificationUrl={url('emailVerification')}
			faqUrl={url('faq')}
			personalInfoAgreementUrl={t('documentUrls.personalInfoAgreement')}
		/>
	),
	onboardingChild: (
		<OnboardingChild
			blogPostEsgRegulationUrl={subUrl('blog', 'esgRegulation')}
			conditionsUrl={t('documentUrls.conditions')}
			dashboardUrl={url('dashboard')}
			documentsUrl={url('documents')}
			faqUrl={url('faq')}
			personalInfoAgreementUrl={t('documentUrls.personalInfoAgreement')}
		/>
	),
	onboardingParent: (
		<OnboardingParent
			blogPostEsgRegulationUrl={subUrl('blog', 'esgRegulation')}
			dashboardUrl={url('dashboard')}
			faqUrl={url('faq')}
		/>
	),
	ourApproach: (
		<OurApproach
			howItWorksUrl={url('howItWorks')}
			registrationUrl={url('registration')}
		/>
	),
	pensionProduct: (
		<PensionProduct
			blogPostEsgUrl={subUrl('blog', 'esg')}
			blogPostEsgComparisonUrl={subUrl('blog', 'esgComparison')}
			blogPostEsgVolatilityUrl={subUrl('blog', 'esgVolatility')}
			blogPostInflationUrl={subUrl('blog', 'inflation')}
			blogPostLongTermUrl={subUrl('blog', 'longTerm')}
			blogPostPensionUrl={subUrl('blog', 'pension')}
			childProductUrl={url('childProduct')}
			classicProductUrl={url('classicProduct')}
			esgProductUrl={url('esgProduct')}
			howItWorksUrl={url('howItWorks')}
			registrationUrl={url('registration')}
			studentProductUrl={url('studentProduct')}
		/>
	),
	portfolio: (
		<Portfolio
			accountUrl={url('account')}
			childOnboardingUrl={url('onboardingChild')}
			conditionsUrl={t('documentUrls.conditions')}
			faqUrl={url('faq')}
			parentOnboardingUrl={url('onboardingParent')}
			signNewAgreementUrl={url('signNewAgreement')}
		/>
	),
	privacyPolicy: (
		<PrivacyPolicy
			registrationUrl={url('registration')}
		/>
	),
	referrals: (
		<Referrals
			baseUrl={baseUrl}
			homepageUrl={url('homepage')}
		/>
	),
	registration: (
		<Registration
			affiliateCode={affiliate && affiliate.code}
			affiliateSource={affiliate && affiliate.source}
			blogPostETFUrl={subUrl('blog', 'etf')}
			conditionsUrl={t('documentUrls.conditions')}
			emailAliasUrl={url('emailAlias')}
			emailVerificationUrl={url('emailVerification')}
			personalInfoAgreementUrl={t('documentUrls.personalInfoAgreement')}
			referral={referral}
			social={social}
		/>
	),
	resetPassword: code !== '' && email !== '' ? (
		<ResetPassword
			code={code}
			dashboardUrl={url('dashboard')}
			email={email}
			forgotPasswordUrl={url('forgotPassword')}
		/>
	) : <Navigate to={url('forgotPassword')} />,
	signAgreement: (
		<SignAgreement
			accountUrl={url('account')}
			blogPostEsgRegulationUrl={subUrl('blog', 'esgRegulation')}
			faqUrl={url('faq')}
		/>
	),
	signNewAgreement: (
		<SignNewAgreement
			dashboardUrl={url('dashboard')}
		/>
	),
	studentProduct: (
		<StudentProduct
			blogPostCompoundUrl={subUrl('blog', 'compound')}
			blogPostEtfUrl={subUrl('blog', 'etf')}
			blogPostEsgUrl={subUrl('blog', 'esg')}
			blogPostEsgComparisonUrl={subUrl('blog', 'esgComparison')}
			blogPostEsgVolatilityUrl={subUrl('blog', 'esgVolatility')}
			blogPostFinancesUrl={subUrl('blog', 'finances')}
			blogPostInflationUrl={subUrl('blog', 'inflation')}
			childProductUrl={url('childProduct')}
			classicProductUrl={url('classicProduct')}
			esgProductUrl={url('esgProduct')}
			howItWorksUrl={url('howItWorks')}
			pensionProductUrl={url('pensionProduct')}
			registrationUrl={url('registration')}
		/>
	),
	taxes: (
		<Taxes
			blogPostTaxesUrl={subUrl('blog', 'taxes')}
		/>
	),
	unsubscribe: (
		<Unsubscribe
			code={code}
			email={email}
			accountUrl={url('account')}
			loginUrl={url('login')}
		/>
	),
});

const pageChildren = {
	blog: true,
};

const header = (baseUrls, url, t, isActive, clientZone = false) => (
	<Header
		accountUrl={url('account')}
		baseUrls={baseUrls}
		childOnboardingUrl={url('onboardingChild')}
		conditionsUrl={t('documentUrls.conditions')}
		clientZone={clientZone}
		parentOnboardingUrl={url('onboardingParent')}
		signNewAgreementUrl={url('signNewAgreement')}
		urls={[
			'account',
			'blog',
			!clientZone && 'childProduct',
			!clientZone && 'classicProduct',
			clientZone && 'clientCheck',
			!clientZone && 'contact',
			clientZone && 'dashboard',
			!clientZone && 'esgProduct',
			!clientZone && 'fees',
			clientZone && 'history',
			'homepage',
			!clientZone && 'howItWorks',
			'login',
			'newspaper',
			clientZone && 'offers',
			'onboarding',
			!clientZone && 'ourApproach',
			!clientZone && 'pensionProduct',
			'portfolio',
			clientZone && 'referrals',
			'registration',
			clientZone && 'signAgreement',
			clientZone && 'signNewAgreement',
			!clientZone && 'studentProduct',
			clientZone && 'taxes',
		].filter(Boolean).reduce((acc, page) => {
			const result = acc;
			result[page] = { url: url(page), active: isActive(page) };
			return result;
		}, {})}
	/>
);

const footer = (url, t, clientZone = false) => (
	<>
		{createPortal(
			<Footer
				blogUrl={url('blog')}
				childProductUrl={url('childProduct')}
				classicProductUrl={url('classicProduct')}
				clientZone={clientZone}
				contactUrl={url('contact')}
				cookiesUrl={url('cookies')}
				documentsUrl={url('documents')}
				esgProductUrl={url('esgProduct')}
				faqUrl={url('faq')}
				feesUrl={url('fees')}
				homepageUrl={url('homepage')}
				howItWorksUrl={url('howItWorks')}
				ourApproachUrl={url('ourApproach')}
				pensionProductUrl={url('pensionProduct')}
				privacyPolicyUrl={url('privacyPolicy')}
				studentProductUrl={url('studentProduct')}
			/>,
			document.body,
		)}
	</>
);

const noFooter = {
	newspaper: true,
};

export default function App({
	affiliate,
	baseUrls,
	blogData,
	geoIpCountry,
	gtmId,
	publicUrl,
	referral,
}) {
	const [t] = useTranslation();
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const country = useCountry();
	const language = useLanguage();
	const [showCookies, setShowCookies] = useState([cookieConsentActive, false]);

	const baseUrl = (baseUrls && baseUrls[country]) ?? `${window.location.origin}${publicUrl}`;
	const url = (page, slug = null) => pageUrl(country, language, page, slug);
	const subUrl = (page, subpage) => subpageUrl(country, language, page, subpage);
	const isActive = (page) => location.pathname === url(page);

	const code = searchParams.get('code') ?? '';
	const email = searchParams.get('email') ?? '';
	const social = searchParams.get('social') ?? '';
	const validCodeAndEmail = code !== '' && email !== '' && validateEmail(email);

	const pageElements = pages(
		baseUrl,
		url,
		subUrl,
		t,
		blogData,
		validCodeAndEmail ? code : '',
		validCodeAndEmail ? email : '',
		social,
		affiliate,
		referral,
		setShowCookies,
	);

	const headerElement = header(baseUrls, url, t, isActive);
	const clientZoneHeaderElement = header(baseUrls, url, t, isActive, true);
	const footerElement = footer(url, t);
	const clientZoneFooterElement = footer(url, t, true);
	const visitorCountryCheck = geoIpCountry !== null
		? <VisitorCountryCheck baseUrls={baseUrls} country={geoIpCountry} />
		: null;

	const createErrorElement = (ErrorComponent, page = null, resetError = null) => (
		<PageProvider page={null}>
			<UserCheck baseUrls={baseUrls}>
				<Meta baseUrl={baseUrl} referral={!!referral} />
				<TrackGTMPageview />
				{header(baseUrls, url, t, () => false)}
				{visitorCountryCheck}
				<ErrorComponent homepageUrl={url('homepage')} page={page} resetError={resetError} />
				{footerElement}
			</UserCheck>
		</PageProvider>
	);

	return (
		<GTMProvider id={gtmId}>
			<CookieConsent
				cookiesUrl={url('cookies')}
				personalInfoAgreementUrl={t('documentUrls.personalInfoAgreement')}
				showCookies={showCookies}
				setShowCookies={setShowCookies}
			/>
			<UserProvider>
				<SmoothScroll />
				<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}>
					<Routes>
						{Object.keys(pageElements).map((page) => {
							const clientZone = !!clientZonePages[page];
							const route = pageRoute(country, language, page);
							const childrenSuffix = (pageChildren[page] ?? false) ? '/*' : '';

							return childRoute(page, route !== null ? `${route}${childrenSuffix}` : null, (
								<ErrorBoundary
									fallback={({ resetError }) => createErrorElement(Error, page, resetError)}
								>
									<PageProvider page={page}>
										<UserCheck baseUrls={baseUrls}>
											<Meta baseUrl={baseUrl} referral={!!referral} />
											<TrackGTMPageview />
											{clientZone ? clientZoneHeaderElement : headerElement}
											{clientZone && <Survey />}
											{clientZone && <PersonalInformationCheck clientCheckUrl={url('clientCheck')} />}
											{clientZone && <ChildAgeCheck />}
											{!clientZone && visitorCountryCheck}
											{pageElements[page]}
											{!noFooter[page] && (
												clientZone ? clientZoneFooterElement : footerElement
											)}
										</UserCheck>
									</PageProvider>
								</ErrorBoundary>
							));
						})}
						<Route
							element={createErrorElement(Error404)}
							path="/*"
						/>
					</Routes>
				</GoogleReCaptchaProvider>
			</UserProvider>
		</GTMProvider>
	);
}

App.propTypes = {
	affiliate: PropTypes.shape({
		code: PropTypes.string,
		source: PropTypes.string,
	}),
	baseUrls: PropTypes.objectOf(PropTypes.string.isRequired),
	blogData: PropTypes.objectOf(PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object.isRequired)).isRequired),
	geoIpCountry: PropTypes.string,
	gtmId: PropTypes.string,
	publicUrl: PropTypes.string.isRequired,
	referral: PropTypes.string,
};

App.defaultProps = {
	affiliate: null,
	baseUrls: null,
	blogData: null,
	geoIpCountry: null,
	gtmId: '',
	referral: null,
};
