import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import {
	cancelPhoneNumberChange,
	fetchCountryNationality,
	saveProfilePersonalInformation,
	sendPhoneChangeCode,
	validatePhoneNumberChange,
} from '../../../utils/api';
import useFetchAuth from '../../../utils/useFetchAuth';
import useRequestAuth from '../../../utils/useRequestAuth';
import {
	checkStreetName,
	checkStreetNumber,
	validateCode,
	validatePersonalInformation,
	validatePhoneWarning,
} from '../../../utils/validators';
import {
	CheckboxField,
	DobField,
	PhoneField,
	PostalCodeField,
	SelectField,
	TextField,
} from '../../forms';
import { Col, Row } from '../../layout';
import {
	Alert,
	Button,
	EmailLink,
	Modal,
} from '../../ui';

import styles from './PersonalInformationForm.module.scss';

const useFetchCountryNationality = useFetchAuth(fetchCountryNationality);

export default function PersonalInformationForm({
	childId,
	reloadUser,
	setShowNameModal,
	user,
}) {
	const [addressChange, setAddressChange] = useState(false);
	const [codeError, setCodeError] = useState(false);
	const [codeSendError, setCodeSendError] = useState(false);
	const [codeSendSuccess, setCodeSendSuccess] = useState(false);
	const [dataChangeModal, setDataChangeModal] = useState(false);
	const [disabledSms, setDisabledSms] = useState(false);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const smsTimeoutIdRef = useRef(null);
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const [nationality, loadingNationality] = useFetchCountryNationality(null, country, language);
	const cancelPhoneNumberChangeAuth = useRequestAuth(cancelPhoneNumberChange);
	const saveProfilePersonalInformationAuth = useRequestAuth(saveProfilePersonalInformation);
	const validatePhoneNumberChangeAuth = useRequestAuth(validatePhoneNumberChange);

	if (user === null) {
		return null;
	}

	useEffect(() => () => {
		if (smsTimeoutIdRef.current !== null) {
			clearTimeout(smsTimeoutIdRef.current);
			smsTimeoutIdRef.current = null;
		}
	}, []);

	const initialValues = {
		firstName: user.firstName,
		lastName: user.lastName,
		phonePrefix: user.phonePrefix ?? {
			CZ: '+420',
			PL: '+48',
			SK: '+421',
		}[country] ?? '+420',
		phoneNumber: user.phoneNumber ?? '',
		streetName: user.streetName ?? '',
		streetNumber: user.streetNumber ?? '',
		city: user.city ?? '',
		postalCode: user.postalCode ?? '',
		addressCountryId: user.addressCountryId ?? '',
		contactAddressStreetName: user.contactAddressStreetName ?? '',
		contactAddressStreetNumber: user.contactAddressStreetNumber ?? '',
		contactAddressCity: user.contactAddressCity ?? '',
		contactAddressPostalCode: user.contactAddressPostalCode ?? '',
		contactAddressCountryId: user.contactAddressCountryId ?? '',
		confirmAddressChange: false,
	};
	const phoneChangeActive = user.phoneNumberPlanned || user.phonePrefixPlanned;
	const phoneRequired = user.phonePrefix !== null && user.phoneNumber !== null;

	const resetSmsTimeout = () => {
		setDisabledSms(true);

		if (smsTimeoutIdRef.current !== null) {
			clearTimeout(smsTimeoutIdRef.current);
		}

		smsTimeoutIdRef.current = setTimeout(() => {
			if (smsTimeoutIdRef.current !== null) {
				smsTimeoutIdRef.current = null;
				setDisabledSms(false);
			}
		}, 60000);
	};

	if (loadingNationality) {
		return null;
	}

	const countryOptions = nationality.map((item) => ({
		label: item.name,
		value: item.id,
	}));

	return (
		<div className={styles.wrap}>
			{!phoneChangeActive ? (
				<Formik
					enableReinitialize
					initialValues={initialValues}
					onSubmit={async (values, { setErrors }) => {
						setError(false);
						setSuccess(false);
						resetSmsTimeout();

						try {
							const { nameChange } = await saveProfilePersonalInformationAuth(
								values.firstName,
								values.lastName,
								values.phonePrefix,
								values.phoneNumber,
								values.streetName,
								values.streetNumber,
								values.city,
								values.postalCode,
								values.addressCountryId,
								values.contactAddressStreetName,
								values.contactAddressStreetNumber,
								values.contactAddressCity,
								values.contactAddressPostalCode,
								values.contactAddressCountryId,
								childId,
							);

							reloadUser();
							setSuccess(true);
							if (nameChange) {
								setShowNameModal(true);
							}
						} catch (e) {
							const fieldErrors = {};
							const errorMessage = e.responseJson?.message;
							if (typeof errorMessage === 'string') {
								if (errorMessage.indexOf('phone prefix validity') !== -1) {
									fieldErrors.phoneNumber = 'forms.fields.phoneNumber.invalid';
								}
							}

							const hasFieldErrors = Object.keys(fieldErrors).length > 0;
							if (hasFieldErrors) {
								setErrors(fieldErrors);
							}
							setError(!hasFieldErrors);
						}
					}}
					validate={(values) => validatePersonalInformation(values, country, phoneRequired)}
				>
					{({
						errors,
						handleBlur,
						handleChange,
						handleSubmit,
						isSubmitting,
						setFieldValue,
						touched,
						values,
					}) => {
						const dataChange = Object.keys(initialValues).some((key) => values[key] !== initialValues[key])
							&& (!addressChange || values.confirmAddressChange);

						return (
							<form onSubmit={handleSubmit}>
								{error && (
									<Alert type="danger">
										{t('forms.error')}
									</Alert>
								)}
								<TextField
									error={
										errors.firstName
										&& touched.firstName
										&& t(errors.firstName)
									}
									id="firstName"
									label={t('forms.fields.firstName.label')}
									name="firstName"
									onBlur={handleBlur}
									onChange={handleChange}
									required
									type="text"
									value={values.firstName}
								/>
								<TextField
									error={
										errors.lastName
										&& touched.lastName
										&& t(errors.lastName)
									}
									id="lastName"
									label={t('forms.fields.lastName.label')}
									name="lastName"
									onBlur={handleBlur}
									onChange={handleChange}
									required
									type="text"
									value={values.lastName}
								/>
								<div className={styles.addressWrap}>
									<h2 className={styles.addressTitle}>
										{t('forms.fields.contactAddress.label')}
									</h2>
									<Row>
										<Col xs={8}>
											<TextField
												error={
													errors.contactAddressStreetName
													&& touched.contactAddressStreetName
													&& t(errors.contactAddressStreetName)
												}
												id="contactAddressStreetName"
												label={t('forms.fields.streetName.label')}
												name="contactAddressStreetName"
												onBlur={handleBlur}
												onChange={handleChange}
												required
												type="text"
												value={values.contactAddressStreetName}
												warning={
													touched.contactAddressStreetName
													&& !errors.contactAddressStreetName
													&& !checkStreetName(values.contactAddressStreetName, country)
														? t('forms.fields.streetName.warning')
														: ''
												}
											/>
										</Col>
										<Col xs={4}>
											<TextField
												error={
													errors.contactAddressStreetNumber
													&& touched.contactAddressStreetNumber
													&& t(errors.contactAddressStreetNumber)
												}
												id="contactAddressStreetNumber"
												label={t('forms.fields.streetNumber.label')}
												name="contactAddressStreetNumber"
												onBlur={handleBlur}
												onChange={handleChange}
												required
												type="text"
												value={values.contactAddressStreetNumber}
												warning={
													touched.contactAddressStreetNumber
													&& !errors.contactAddressStreetNumber
													&& !checkStreetNumber(values.contactAddressStreetNumber, country)
														? t('forms.fields.streetNumber.warning')
														: ''
												}
											/>
										</Col>
									</Row>
									<Row>
										<Col xs={4}>
											<TextField
												error={
													errors.contactAddressCity
													&& touched.contactAddressCity
													&& t(errors.contactAddressCity)
												}
												id="contactAddressCity"
												label={t('forms.fields.city.label')}
												name="contactAddressCity"
												onBlur={handleBlur}
												onChange={handleChange}
												required
												type="text"
												value={values.contactAddressCity}
											/>
										</Col>
										<Col xs={4}>
											<PostalCodeField
												country={country}
												error={
													errors.contactAddressPostalCode
													&& touched.contactAddressPostalCode
													&& t(errors.contactAddressPostalCode)
												}
												id="contactAddressPostalCode"
												label={t('forms.fields.postalCode.label')}
												name="contactAddressPostalCode"
												onBlur={handleBlur}
												onChange={setFieldValue}
												required
												type="text"
												value={values.contactAddressPostalCode}
											/>
										</Col>
										<Col xs={4}>
											<SelectField
												error={
													errors.contactAddressCountryId
													&& touched.contactAddressCountryId
													&& t(errors.contactAddressCountryId)
												}
												id="contactAddressCountryId"
												label={t('forms.fields.country.label')}
												name="contactAddressCountryId"
												onChange={setFieldValue}
												options={countryOptions}
												required
												value={values.contactAddressCountryId}
											/>
										</Col>
									</Row>
									<a
										className={styles.addressChange}
										href="#addressChange"
										onClick={(e) => {
											e.preventDefault();
											setAddressChange(!addressChange);
										}}
										title={t('forms.fields.addressChange.change')}
									>
										{t('forms.fields.addressChange.change')}
									</a>
									<div className={`${styles.addressChangeWrap} ${addressChange ? styles.isOpen : ''}`.trim()}>
										<h2 className={styles.addressTitle}>
											{t('forms.fields.address.change')}
										</h2>
										<Row>
											<Col xs={8}>
												<TextField
													error={
														errors.streetName
														&& touched.streetName
														&& t(errors.streetName)
													}
													id="streetName"
													label={t('forms.fields.streetName.label')}
													name="streetName"
													onBlur={handleBlur}
													onChange={handleChange}
													required
													type="text"
													value={values.streetName}
													warning={
														touched.streetName
														&& !errors.streetName
														&& !checkStreetName(values.streetName, country)
															? t('forms.fields.streetName.warning')
															: ''
													}
												/>
											</Col>
											<Col xs={4}>
												<TextField
													error={
														errors.streetNumber
														&& touched.streetNumber
														&& t(errors.streetNumber)
													}
													id="streetNumber"
													label={t('forms.fields.streetNumber.label')}
													name="streetNumber"
													onBlur={handleBlur}
													onChange={handleChange}
													required
													type="text"
													value={values.streetNumber}
													warning={
														touched.streetNumber
														&& !errors.streetNumber
														&& !checkStreetNumber(values.streetNumber, country)
															? t('forms.fields.streetNumber.warning')
															: ''
													}
												/>
											</Col>
										</Row>
										<Row>
											<Col xs={4}>
												<TextField
													error={
														errors.city
														&& touched.city
														&& t(errors.city)
													}
													id="city"
													label={t('forms.fields.city.label')}
													name="city"
													onBlur={handleBlur}
													onChange={handleChange}
													required
													type="text"
													value={values.city}
												/>
											</Col>
											<Col xs={4}>
												<PostalCodeField
													country={country}
													error={
														errors.postalCode
														&& touched.postalCode
														&& t(errors.postalCode)
													}
													id="postalCode"
													label={t('forms.fields.postalCode.label')}
													name="postalCode"
													onBlur={handleBlur}
													onChange={setFieldValue}
													required
													type="text"
													value={values.postalCode}
												/>
											</Col>
											<Col xs={4}>
												<SelectField
													error={
														errors.addressCountryId
														&& touched.addressCountryId
														&& t(errors.addressCountryId)
													}
													id="addressCountryId"
													label={t('forms.fields.country.label')}
													name="addressCountryId"
													onChange={setFieldValue}
													options={countryOptions}
													required
													value={values.addressCountryId}
												/>
											</Col>
										</Row>
										<CheckboxField
											checked={values.confirmAddressChange}
											error={
												errors.confirmAddressChange
												&& touched.confirmAddressChange
												&& t(errors.confirmAddressChange)
											}
											id="confirmAddressChange"
											label={t('forms.fields.addressChange.confirm')}
											name="confirmAddressChange"
											onBlur={handleBlur}
											onChange={handleChange}
											required={addressChange}
										/>
									</div>
								</div>
								<PhoneField
									error={(
										errors.phonePrefix
										&& touched.phonePrefix
										&& t(errors.phonePrefix)
									) || (
										errors.phoneNumber
										&& touched.phoneNumber
										&& t(errors.phoneNumber)
									)}
									helper={t('forms.fields.phoneNumber.helper')}
									id="phoneNumber"
									label={t('forms.fields.phoneNumber.label')}
									name="phoneNumber"
									onBlur={handleBlur}
									onChange={setFieldValue}
									phonePrefixId="phonePrefix"
									phonePrefixName="phonePrefix"
									phonePrefixValue={values.phonePrefix}
									required={phoneRequired}
									value={values.phoneNumber}
									warning={
										values.phoneNumber !== '' && !validatePhoneWarning(values.phonePrefix, values.phoneNumber)
											? t('forms.fields.phoneNumber.warning') : ''
									}
								/>
								<TextField
									disabled
									id="email"
									label={t('forms.fields.email.label')}
									name="email"
									readonly
									type="text"
									value={user.email ?? ''}
								/>
								<DobField
									disabled
									id="birthDate"
									label={t('forms.fields.birthDate.label')}
									name="birthDate"
									readonly
									value={user.birthDate ?? ''}
								/>
								{success && (
									<Alert
										autoClose
										close
										onClose={() => setSuccess(false)}
										type="success"
									>
										{t('forms.success')}
									</Alert>
								)}
								<Button
									disabled={isSubmitting}
									isSubmit={!dataChange}
									label={t('account.personalData.button')}
									onClick={dataChange ? () => { setDataChangeModal(true); } : null}
								/>
								<Modal
									isVisible={dataChange && dataChangeModal}
									onClose={() => setDataChangeModal(false)}
									title={t('account.personalData.dataChange.title')}
								>
									<div className={styles.modal}>
										<p className={styles.text}>
											{t('account.personalData.dataChange.text')}
										</p>
										<ul className={styles.list}>
											{Object.keys(initialValues)
												.filter((key) => key !== 'confirmAddressChange' && values[key] !== initialValues[key])
												.map((key) => (
													<li key={key} className={styles.listItem}>
														{t(`account.personalData.dataChange.fields.${key}`)}
														:
														{' '}
														{key === 'addressCountryId' || key === 'contactAddressCountryId'
															? countryOptions.find(
																(item) => item.value === values[key],
															)?.label
															: values[key]}
													</li>
												))}
										</ul>
										<p className={styles.text}>
											{t('account.personalData.dataChange.subText')}
										</p>
										<div className={styles.modalControl}>
											{error && (
												<Alert type="danger">
													{t('forms.error')}
												</Alert>
											)}
											<div className={styles.modalControlItem}>
												<Button
													disabled={isSubmitting}
													isSubmit={dataChange}
													label={t('account.personalData.dataChange.confirm')}
												/>
											</div>
											<div className={styles.modalControlItem}>
												<Button
													label={t('account.personalData.dataChange.cancel')}
													onClick={() => setDataChangeModal(false)}
													outline
												/>
											</div>
										</div>
									</div>
								</Modal>
							</form>
						);
					}}
				</Formik>
			) : (
				<div className={styles.phoneWrap}>
					<Formik
						initialValues={{
							code: '',
						}}
						onSubmit={async (values) => {
							setCodeError(false);
							setError(false);

							try {
								await validatePhoneNumberChangeAuth(values.code, childId);
								reloadUser();
							} catch (e) {
								const errorMessage = e.responseJson?.message;
								if (typeof errorMessage === 'string' && errorMessage.indexOf('Bad SMS code') !== -1) {
									setCodeError(true);
								} else {
									setError(true);
								}
							}
						}}
						validate={(values) => validateCode(values)}
					>
						{({
							errors,
							handleBlur,
							handleChange,
							handleSubmit,
							isSubmitting,
							touched,
							values,
						}) => (
							<form onSubmit={handleSubmit}>
								{error && (
									<Alert type="danger">
										{t('forms.error')}
									</Alert>
								)}
								{codeSendError && (
									<Alert type="danger">
										{t('account.personalData.phoneChange.codeSendError')}
									</Alert>
								)}
								{codeSendSuccess && (
									<Alert type="success">
										{t('account.personalData.phoneChange.codeSendSuccess')}
									</Alert>
								)}
								<Row middle>
									<Col>
										<p className={styles.phoneTitle}>
											{t('account.personalData.phoneChange.title')}
										</p>
									</Col>
									<Col lg={6}>
										<TextField
											error={
												errors.code
												&& touched.code
												&& t(errors.code)
											}
											id="code"
											label={t('onboarding.steps.agreement.formCode.code.label')}
											name="code"
											onBlur={handleBlur}
											onChange={handleChange}
											required
											type="text"
											value={values.code}
										/>
										{codeError && (
											<Alert type="danger">
												<Trans i18nKey="onboarding.steps.agreement.formCode.error">
													<EmailLink />
												</Trans>
											</Alert>
										)}
									</Col>
									<Col lg={6}>
										<Button
											disabled={isSubmitting}
											isSubmit
											label={t('account.personalData.phoneChange.confirm')}
										/>
									</Col>
									<Col lg={12}>
										<p className={styles.phoneText}>
											<Trans i18nKey="account.personalData.phoneChange.confirmText">
												<strong />
												{{ phoneNumber: `${user.phonePrefixPlanned} ${user.phoneNumberPlanned}` }}
											</Trans>
										</p>
										<p className={styles.phoneText}>
											<Trans i18nKey="account.personalData.phoneChange.cancelText">
												<a
													href="#phoneChange"
													onClick={(e) => {
														e.preventDefault();
														cancelPhoneNumberChangeAuth(childId).then(() => {
															reloadUser();
														});
													}}
												>
													{t('account.personalData.phoneChange.cancelText')}
												</a>
											</Trans>
										</p>
									</Col>
									<Col lg={6}>
										<Button
											disabled={isSubmitting || disabledSms}
											label={t('account.personalData.phoneChange.sendAgain')}
											onClick={async () => {
												setCodeError(false);
												setCodeSendError(false);
												setCodeSendSuccess(false);
												setError(false);
												resetSmsTimeout();

												try {
													const { success: codeSent } = await sendPhoneChangeCode(childId);

													if (codeSent) {
														setCodeSendSuccess(true);
													} else {
														setCodeSendError(true);
													}
												} catch {
													setError(true);
												}
											}}
										/>
									</Col>
								</Row>
							</form>
						)}
					</Formik>
				</div>
			)}
		</div>
	);
}

PersonalInformationForm.propTypes = {
	childId: PropTypes.number,
	reloadUser: PropTypes.func.isRequired,
	setShowNameModal: PropTypes.func.isRequired,
	user: PropTypes.shape({
		addressCountryId: PropTypes.string,
		birthDate: PropTypes.string,
		city: PropTypes.string,
		contactAddressCity: PropTypes.string,
		contactAddressCountryId: PropTypes.string,
		contactAddressPostalCode: PropTypes.string,
		contactAddressStreetName: PropTypes.string,
		contactAddressStreetNumber: PropTypes.string,
		email: PropTypes.string,
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired,
		phoneNumber: PropTypes.string,
		phoneNumberPlanned: PropTypes.string,
		phonePrefix: PropTypes.string,
		phonePrefixPlanned: PropTypes.string,
		postalCode: PropTypes.string,
		streetName: PropTypes.string,
		streetNumber: PropTypes.string,
	}),
};

PersonalInformationForm.defaultProps = {
	childId: null,
	user: null,
};
