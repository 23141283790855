import { COOKIE_CONSENT_NAME } from '../utils/cookieConsent';
import { countryCheck, languageSwitcher } from './common';

const account = {
	title: 'Nastavení uživatelského účtu',
	subtitle: 'Pokud se některý z\u00a0uvedených údajů změnil, můžete ho aktualizovat přímo na\u00a0této stránce. '
		+ 'V\u00a0případě změny jména od Vás budeme potřebovat nový aktuální doklad s\u00a0novým jménem. Pokud si přejete '
		+ 'aktualizovat e-mail (např. kvůli změně jména), napište nám prosím na <0>info@fondee.cz</0>.',
	tradingDay: 'Nejbližší obchodní den',
	faqLink: 'Jak a kdy probíhá investování',
	bankAccountsControl: {
		title: 'Bankovní účty',
		withdrawal: 'Účet pro výběry',
		withdrawalTooltip: 'Na vybraný účet budeme odesílat prostředky zadané k\u00a0výběru.',
		unconfirmedTooltip: 'Účet čeká na ověření. Abychom mohli ověřit, že bankovní účet opravdu patří vám, '
			+ 'musíte z\u00a0něj nejprve poslat vklad, klidně jen 1\u00a0Kč. Otevřete si okno \u201eChci vložit peníze\u201c '
			+ 'a\u00a0zobrazí se vám platební pokyny.',
		confirmChange: {
			title: 'Určitě chcete změnit účet pro výběry?',
			text: 'Pokud už je zpracováván dříve zadaný výběr, změna se projeví až při dalším výběru.',
			submit: 'Ano, změnit',
			cancel: 'Ne, ponechat',
		},
		addAccount: {
			button: 'Přidat další účet',
			buttonTooltip: [
				'Můžete přidat další bankovní účet, ze kterého budete posílat vklady a\u00a0na který můžete '
				+ 'přijímat prostředky. Účet musí být vedený na Vaše jméno. Pokud si přejete přidat zahraniční '
				+ 'účet, je nutné ho vložit přes IBAN.',
				'$t(account.bankAccountsControl.addAccount.text.1)',
			],
			title: 'Přidat další účet',
			text: [
				'Vyplňte předčíslí účtu (pokud ho účet má), číslo účtu a\u00a0kód banky. Účet musí být vedený na '
				+ 'Vaše jméno. Pokud si přejete přidat zahraniční účet, je nutné ho vložit přes IBAN.',
				'Účet není automaticky aktivní (např. pro zadání výběru), je potřeba ho ověřit skrze ověřovací '
				+ 'platbu. Můžete poslat celý vklad nebo klidně jen pár korun. Pro zadání platby si otevřete okno '
				+ '\u201eChci vložit peníze\u201c a\u00a0zobrazí se Vám platební pokyny.',
			],
			submit: 'Přidat účet',
			cancel: 'Zrušit',
		},
	},
	cashControl: {
		title: 'Poměr hotovosti v portfoliu',
		titleSet: 'Poměr hotovosti nastaven na {{ amount }}.',
		titlePlanned: 'Poměr hotovosti je aktuálně nastaven na {{ current }}. Při nejbližším rebalancingu '
			+ 'bude portfolio nastaveno tak, aby byl poměr hotovosti {{ planned }}.',
		titleTooltip: 'Standardně zůstávají minimálně 2\u00a0% z\u00a0hodnoty Vašeho portfolia v\u00a0hotovosti '
			+ '(u\u00a0menších portfolií může být tento poměr vyšší). Zde nám můžete zadat příkaz, abychom při každém '
			+ 'rebalancingu ponechali větší část Vašeho portfolia v\u00a0hotovosti (minimum jsou\u00a02\u00a0%). Tento '
			+ 'poměr se bude udržovat při každém rebalancingu, dokud nám nedáte příkaz ho změnit. Hotovost je držena '
			+ 'v\u00a0EUR a\u00a0stejně jako Vaše portfolio je vystavena měnovému riziku. Měsíční poplatek je vypočítáván '
			+ 'z\u00a0celkové hodnoty portfolia včetně držené hotovosti.',
		button: 'Upravit poměr hotovosti',
		buttonTooltip: 'Poměr hotovosti bude upraven následující obchodní\u00a0den.',
		closedTooltip: 'V\u00a0tuto chvíli nelze změnit poměr hotovosti, protože právě probíhá úprava rozložení portfolia.',
		modal: {
			title: 'Nastavit nový poměr',
			text: 'Standardně zůstávají minimálně 2\u00a0% z\u00a0hodnoty Vašeho portfolia v\u00a0hotovosti (u\u00a0menších '
				+ 'portfolií může být tento poměr vyšší). Zde nám můžete zadat příkaz, abychom při každém rebalancingu ponechali '
				+ 'větší část Vašeho portfolia v\u00a0hotovosti (minimum jsou 2\u00a0%). Tento poměr se bude udržovat při každém '
				+ 'rebalancingu, dokud nám nedáte příkaz ho změnit. Hotovost je držena v\u00a0EUR a\u00a0stejně jako Vaše portfolio '
				+ 'je vystavena měnovému riziku. Měsíční poplatek je vypočítáván z\u00a0celkové hodnoty portfolia včetně držené hotovosti.',
			maxTooltip: 'V tomto případě nebudete mít žádná ETF a vše zůstane v hotovosti.',
			submit: 'Nastavit nový poměr',
			cancel: 'Neměnit',
		},
	},
	currencySelect: {
		title: 'Preferované zobrazení měny pro\u00a0celý účet',
		subtitle: 'Zobrazení v\u00a0Přehledu',
	},
	depositDefaultPortfolio: {
		title: 'Primární portfolio pro\u00a0vklady',
		subtitle: 'Vyberte portfolio, na\u00a0které máme připsat vklad, pokud u\u00a0vkladu nebude správně uvedena poznámka pro\u00a0příjemce.',
		label: 'Zvolené portfolio',
		noOptionsMessage: 'Žádná portfolia',
	},
	deviceTokens: {
		button: 'Přihlášená mobilní zařízení',
		modal: {
			title: 'Přihlášená mobilní zařízení',
			text: 'Mobilní zařízení, která mají umožněné biometrické přihlášení. Platnost biometrického přihlášení je 3\u00a0měsíce. '
				+ 'Odpojením zařízení vynutíte při dalším přihlášení zadaní hesla.',
			disconnect: 'Odpojit',
			table: {
				device: 'Název zařízení',
				active: 'Naposledy aktivní',
			},
		},
	},
	investorProfileControl: {
		title: 'Aktuální investiční profil:',
		titlePlanned: 'Plánovaný investiční profil:',
		titleTooltip: 'Investiční profil, podle kterého je aktuálně rozloženo Vaše portfolio. Pokud si přejete rozložit '
			+ 'portfolio podle jiného investičního profilu, klikněte na tlačítko Změnit.',
		titlePlannedTooltip: 'Podle tohoto profilu bude Vaše portfolio nově rozloženo při nejbližším rebalancingu. '
			+ 'Pokud si přejete výběr nového profilu změnit, klikněte na tlačítko Změnit.',
		button: 'Změnit',
		closedTooltip: 'V\u00a0tuto chvíli nelze změnit poměr hotovosti, protože právě probíhá úprava rozložení portfolia.',
		modal: {
			title: 'Změnit zvolený profil',
			submit: 'Změnit profil',
			cancel: 'Ponechat stávající profil',
			cancelAllProfilesCheck: 'Ponechat doporučený profil',
		},
		changeWarning: {
			title: 'Změna investičního profilu',
			subtitle: 'Změna investičního profilu může vést k\u00a0daňové povinnosti. Opravdu si přejete změnu provést?',
		},
	},
	passwordChange: {
		button: 'Chci změnit heslo k účtu',
		title: 'Změna hesla',
		success: 'Heslo bylo úspěšně změněno!',
	},
	personalData: {
		button: 'Uložit změny',
		modalInfo: {
			title: 'Kontrola osobních údajů',
			text: '<0>Evidujeme u\u00a0Vás následující osobní údaje:</0>'
				+ '<1><0>{{ firstName }} {{ lastName }}</0>'
				+ '<0>{{ streetName }} {{ streetNumber }}, {{ city }} {{ postalCode }}, {{ country }}</0>'
				+ '<0>{{ phonePrefix }} {{ phoneNumber }}</0></1>'
				+ '<0>Jsou tyto údaje správné?</0>',
			confirm: 'Ano, jsou správné',
			edit: 'Ne, chci je aktualizovat',
		},
		modalNameChange: {
			title: 'Změna jména',
			text: 'Protože jste požádali o\u00a0změnu jména, musíme Vás poprosit o\u00a0kopii aktuálního osobního dokladu.',
			button: 'Nahrát doklad',
		},
		phoneChange: {
			title: 'Ověření telefonního čísla',
			codeSendError: 'Ověřovací kód jsme odeslali. Pokud potřebujete nový, počkejte prosím minutu.',
			codeSendSuccess: 'Ověřovací kód byl odeslán znovu.',
			confirmText: 'Na Vámi zadané telefonní číslo <0>{{ phoneNumber }}</0> jsme odeslali potvrzovací kód. Pro pokračování opište kód '
				+ 'z\u00a0SMS a\u00a0potvrďte tlačítkem níže.',
			cancelText: 'Pokud jste zadali nesprávné telefonní číslo, můžete jeho změnu <0>zrušit</0>. '
				+ 'Nový kód je možné odeslat po uplynutí 1\u00a0minuty.',
			confirm: 'Potvrdit',
			sendAgain: 'Odeslat znovu',
		},
		dataChange: {
			title: 'Potvrzení osobních údajů',
			text: 'Změnili jste následující údaje:',
			subText: 'Změny můžete buď potvrdit nebo se vrátit zpátky.',
			confirm: 'Potvrdit',
			cancel: 'Zpátky',
			fields: {
				firstName: 'Jméno',
				lastName: 'Příjmení',
				phonePrefix: 'Předvolba',
				phoneNumber: 'Telefon',
				streetName: 'Ulice',
				streetNumber: 'Číslo popisné',
				city: 'Město',
				postalCode: 'PSČ',
				addressCountryId: 'Země',
				contactAddressStreetName: 'Kontaktní ulice',
				contactAddressStreetNumber: 'Kontaktní číslo popisné',
				contactAddressCity: 'Kontaktní město',
				contactAddressPostalCode: 'Kontaktní PSČ',
				contactAddressCountryId: 'Kontaktní země',
			},
		},
	},
	profilePreference: {
		title: 'Nastavení zpráv a dotazníků',
		subtitle: 'E-mailové výpisy si přejete zasílat:',
	},
	investingQuestionaire: {
		title: 'Investiční dotazník',
		lastFill: 'Datum posledního vyplnění: ',
		possibleFill: 'Znovu možné vyplnit: ',
		tooltip: 'Investiční dotazník je možné vyplnit maximálně jednou za 6\u00a0měsíců. '
			+ 'Pokud nastala změna ve vašich okolnostech, a\u00a0přejete si vyplnit dotazník dříve, '
			+ 'kontaktujte nás prosím na\u00a0info@fondee.cz.',
		now: 'IHNED',
		fill: 'Vyplnit investiční dotazník',
		modal: {
			title: 'Vyplnit investiční dotazník',
			text: 'Výsledek investičního dotazníku má vliv na\u00a0možnosti nastavení Vašich portfolií. '
				+ 'Dle Vašich odpovědí a vyhodnocení investičního dotazníku Vám můžeme nabídnout i rizikovější profily, než máte nyní, '
				+ 'případně se může Váš profil snížit nebo zůstat stejný.',
			confirm: 'Rozumím, vyplnit dotazník',
		},
	},
	twoFactorAuthenticationControl: {
		title: 'Dvoufaktorové ověření',
		active: 'Momentálně máte aktivní dvoufázové ověřování.',
		disable: 'Deaktivovat',
		disabled: 'Úspěšně deaktivováno',
		info: {
			title: 'Postup:',
			line1: 'Stáhněte si generátor OTP, například Google Authenticator (<0>App Store</0>, <1>Google Play</1>)',
			line2: 'Naskenujte QR\u00a0kód',
			line3: 'Zadejte jednorázový kód z\u00a0aplikace',
			line4: 'Klikněte na tlačítko \u201eAktivovat\u201c',
		},
		code: {
			label: 'Jednorázový kód',
			error: 'Špatný jednorázový kód',
		},
		activate: 'Aktivovat',
		activated: 'Úspěšně aktivováno',
	},
	withdrawals: {
		closureTitle: 'Zrušení účtu',
		activeTitle: 'Zadané příkazy',
		withdrawals: {
			title: 'Výběr hotovosti z investičního účtu',
			disabledMessage: 'Může zadat pouze vlastník zadaného bankovního účtu.',
			part: {
				title: 'Výběr hotovosti',
				text: 'Výběr peněz znamená, že zadáme brokerovi příkaz prodat část Vašeho portfolia, peníze převedeme '
					+ 'na\u00a0požadovanou měnu a\u00a0odešleme na\u00a0Váš bankovní účet označený jako hlavní. ETFka budou '
					+ 'prodána podle jejich poměrného zastoupení v\u00a0portfoliu tak, aby po prodeji bylo Vaše portfolio opět '
					+ 'optimálně rozložené. ETFka jsou kótována v\u00a0eurech a\u00a0pro směnu do\u00a0korun bude využito '
					+ 'aktuálního kurzu v\u00a0době prodeje. Pokud zadaná částka přesáhne aktuální hodnotu ETFek, bude '
					+ 'prodáno celé portfolio a\u00a0hotovost (po odečtení měsíčních poplatků) bude odeslána na\u00a0Váš\u00a0účet.',
				overError: 'Zadaná částka je vyšší, než je aktuální hodnota portfolia.',
				activeText: 'Máte zadaný příkaz k\u00a0výběru {{ sum }}.',
				activeTextTransfer: 'Máte zadaný příkaz k\u00a0převodu {{ sumTransfer }} do\u00a0portfolia {{ portfolio }}.',
				activeTextTransferMultiple: 'Máte zadané příkazy k\u00a0převodu z\u00a0tohoto portfolia:',
				activeTextTransferMultiplePart: '{{ sumTransfer }} do\u00a0portfolia {{ portfolio }}',
			},
			all: {
				title: 'Vybrat vše',
				text: 'Výběr všech dostupných prostředků znamená, že v\u00a0nejbližší obchodní den zadáme brokerovi příkaz '
					+ 'k\u00a0prodeji všech vámi držených ETF (pokud nějaká máte). Následně všechny volné prostředky směníme '
					+ 'a\u00a0odešleme je na Váš bankovní účet. ETFka jsou kótována v\u00a0eurech a\u00a0pro směnu do\u00a0korun '
					+ 'bude využito aktuálního kurzu v\u00a0době prodeje. Při výběru této možnosti vyberete ze svého účtu dostupné '
					+ 'prostředky, ale samotný účet u\u00a0Fondee nebude zrušen.',
				activeText: 'Máte zadaný příkaz k\u00a0odprodeji všech ETF a\u00a0výběru všech prostředků z\u00a0portfolia.',
			},
			confirm: 'Zadat',
			success: 'Výběr úspěšně zadán.',
			cancel: 'Zrušit příkaz',
			cancelOnlyParent: 'Některé příkazy může zrušit pouze zákonný zástupce.',
			currencyWarning: 'Zadaná měna výběru neodpovídá měně portfolia.',
			childPortfolioWarning: 'Když dětské portfolio předčasně zrušíte, nedodržíte tak podmínky pro získání zvýhodněného '
				+ 'poplatku. Z\u00a0vyplacené částky proto strhneme rozdíl mezi poplatkem, který jste dosud zaplatili, '
				+ 'a\u00a0našim běžným poplatkem.',
			confirmPopup: {
				title: 'Potvrzení výběru',
				text: 'Potvrďte prosím, že chcete opravdu uskutečnit zadaný výběr.',
				button: 'Ano, vybrat peníze',
				twoFactorAuthentication: 'Zadejte Váš jednorázový kód',
			},
			allPopup: {
				title: 'Výběr hotovosti z\u00a0investičního účtu',
				text: 'Zadaná částka je velmi blízká celkové hodnotě Vašeho portfolia. Přejete si vybrat všechny Vaše prostředky?',
				button: 'Ano, vybrat vše',
				secondaryButton: 'Ne, vybrat pouze požadovanou částku',
			},
			error: {
				title: 'Upozornění',
				text: 'Výběr nelze zadat, protože už máte zadaný příkaz na\u00a0výběr všech prostředků, zrušení účtu nebo přesun '
					+ 'části portfolia. Aby bylo možné příkaz k\u00a0výběru zadat, musíte nejprve zrušit předchozí příkazy',
				button: 'Zavřít',
			},
			childWarning: '<0>Můžete vybrat maximálně 10\u00a0% ročně z\u00a0celkové hodnoty všech dětských portfolií.</0>',
			childRatioError: 'Můžete vybrat maximálně 10\u00a0% ročně z\u00a0celkové hodnoty všech dětských portfolií.',
			pensionWarningTitle: 'Penzijní portfolio',
			pensionWarning: '<0>Nebyly splněny podmínky pro výběr prostředků z\u00a0penzijního účtu. Pro zadání výběru je nutné '
				+ 'dosáhnout aspoň 60\u00a0let věku a\u00a0zároveň musí uplynout minimálně 10\u00a0let (120\u00a0měsíců) od založení '
				+ 'penzijního portfolia.</0>'
				+ '<0>Jedinou možností je zrušení portfolia, ale upozorňujeme, že v\u00a0takovém případě dojde k\u00a0dorovnání '
				+ 'poplatků do standardní výše.</0>',
		},
		cancel: {
			portfolio: {
				title: 'Zrušení portfolia',
				disabledMessage: 'Portfolio může zrušit pouze zákonný zástupce',
				text: 'Pokud chcete zrušit toto portfolio, zadáme brokerovi příkaz prodat všechna ETFka z\u00a0tohoto portfolia. '
					+ 'Hotovost bude následně (po odečtení měsíčních poplatků) směněna do\u00a0měny, kterou zvolíte při zadání výběru, '
					+ 'a\u00a0odeslána na\u00a0bankovní účet, který máte označený jako účet pro výběry. K\u00a0odprodeji ETF dojde '
					+ 'v\u00a0nejbližší obchodní den po zadání příkazu. Peníze Vám následně pošleme na\u00a0účet. Celý proces může '
					+ 'trvat až jeden týden.',
				subtext: 'I\u00a0v\u00a0případě, že je Vaše portfolio prázdé, ke\u00a0zrušení dojde až nejbližší obchodní den.',
				note: '* Doporučujeme místo úplného zrušení portfolia zvolit možnost “Vybrat všechny prostředky”. Tím získáte celou '
					+ 'hodnotu portfolia, ale pokud někdy v\u00a0budoucnu budete chtít znovu investovat s\u00a0Fondee, nemusíte si '
					+ 'zakládat nové portfolio.',
				confirm: 'Zrušit portfolio',
				success: 'Příkaz ke zrušení portfolia úspěšně zadán.',
				activeText: 'Máte zadaný příkaz k\u00a0výběru všech prostředků a\u00a0zrušení portfolia.',
				account: {
					title: 'Zadáno zrušení účtu',
					closure: 'Máte zadaný příkaz k\u00a0odprodeji ETF ze všech portfolií, výběru všech prostředků a\u00a0zrušení účtu. '
						+ 'Příkaz můžete zrušit na stránce <0>Můj účet</0>.',
					child: {
						closure: 'Máte zadaný příkaz k\u00a0odprodeji ETF ze všech portfolií, výběru všech prostředků a\u00a0zrušení účtu. '
							+ 'Příkaz může zrušit pouze rodič na své stránce <0>Můj účet</0>.',
					},
				},
				cancel: 'Zrušit příkaz',
				popup: {
					title: 'Zrušení portfolia',
					text: 'Potvrďte prosím, že si opravdu přejete zrušit Vaše portfolio u\u00a0Fondee.',
					button: 'Ano, vybrat peníze a\u00a0zrušit portfolio',
					twoFactorAuthentication: 'Zadejte Váš jednorázový kód',
				},
				error: {
					title: 'Upozornění',
					text: 'Zrušení portfolia nelze zadat, protože už máte zadaný příkaz na\u00a0výběr všech prostředků, zrušení účtu nebo přesun '
						+ 'celého portfolia. Aby bylo možné příkaz k\u00a0výběru zadat, musíte nejprve zrušit předchozí příkazy',
					button: 'Zavřít',
				},
				transferWarning: {
					title: 'Upozornění',
					text: 'Máte zadaný příkaz k\u00a0přesunu hotovosti nebo ETF na\u00a0toto portfolio. Buď zrušte tento příkaz, nebo '
						+ 'zadejte příkaz ke\u00a0zrušení portfolia až po provedení přesunu.',
					button: 'Rozumím',
				},
				defaultPortfolioSelect: {
					title: 'Upozornění',
					text: 'Zadali jste zrušení portfolia, které máte nastavené jako primární portfolio pro vklady. '
						+ 'Vyberte si prosím nové portfolio pro alokaci vkladů.',
					textChild: 'Zadali jste zrušení portfolia, které máte u\u00a0vašeho dítěte nastavené jako primární portfolio pro vklady. '
						+ 'Vyberte si prosím nové portfolio pro alokaci vkladů. Vybírat můžete z\u00a0aktivních portfolií daného uživatele.',
					textBoth: 'Zadali jste zrušení portfolia, které máte vy i\u00a0vaše dítě nastavené jako primární portfolio '
						+ 'pro vklady. Vyberte si prosím nová portfolia pro každého uživatele.',
					button: 'Nastavit',
					label: 'Vyberte si prosím své nové defaultní portfolio pro vklady',
					labelChild: 'Vyberte si prosím nové defaultní portfolio pro dítě',
				},
			},
			title: 'Výběr všech portfolií a\u00a0zrušení účtu',
			text: 'Pokud chcete ukončit investování, zadáme brokerovi příkaz prodat všechna ETFka ve Vašich portfoliích. Pokud '
				+ 'máte aktivní i\u00a0dětská portfolia, i\u00a0tato portfolia budou zrušena. Hotovost bude následně, po odečtení '
				+ 'měsíčních poplatků, odeslána na Váš primární účet pro výběry. Celý proces může trvat až jeden týden. '
				+ 'V\u00a0případě, že si přejete prostředky vybrat v\u00a0Kč, bude zůstatek převeden podle aktuálního kurzu. Pokyn '
				+ 'k\u00a0prodeji můžete dát kdykoli, investice prodáme nejbližší obchodní den.',
			textChild: 'Zrušení investičního účtu může zadat pouze rodič. Při dosažení plnoletosti přechází všechna práva na '
				+ 'Vás, takže budete mít nad účtem plnou kontrolu, včetně jeho zrušení.',
			note: 'Doporučujeme místo úplného zrušení účtu vybrat z\u00a0portfolia (nebo portfolií) všechny peníze. Příkaz '
				+ 'jednoduše zadáte v\u00a0detailu konkrétního <0>portfolia</0>. Tím získáte celou hodnotu portfolia, ale pokud '
				+ 'někdy v\u00a0budoucnu budete chtít znovu investovat s\u00a0Fondee, nemusíte si znovu zakládat účet.',
			confirm: 'Potvrdit',
			success: 'Příkaz ke\u00a0zrušení účtu úspěšně zadán.',
			activeTitle: 'Zadáno zrušení účtu',
			activeText: 'Máte zadaný příkaz k\u00a0odprodeji ETF ze\u00a0všech portfolií, výběru všech prostředků a\u00a0zrušení účtu.',
			activeTextChild: 'Máte zadaný příkaz k\u00a0odprodeji ETF ze\u00a0všech portfolií, výběru všech prostředků a\u00a0zrušení účtu. '
				+ 'Příkaz může zrušit pouze rodič na své stránce Můj účet.',
			cancel: 'Zrušit příkaz',
			childClosure: 'Když máte zadaný příkaz ke zrušení dětského účtu, nemůžete zadat zrušení svého účtu.',
			popup: {
				title: 'Zrušení účtu',
				text: 'Potvrďte prosím, že si opravdu přejete zrušit Váš investiční účet u\u00a0Fondee.',
				button: 'Ano, vybrat peníze a\u00a0zrušit účet',
				twoFactorAuthentication: 'Zadejte Váš jednorázový kód',
			},
		},
		transfer: {
			title: 'Přesun mezi portfolii',
			portfolioLabel: 'Převést do\u00a0portfolia:',
			direct: {
				title: 'Odprodej a\u00a0přesun hotovosti',
				text: 'Zde můžete zadat částku v\u00a0eurech, kterou chcete přesunout do\u00a0jiného svého portfolia. Znamená to, '
					+ 'že při nejbližším obchodním dni předáme brokerovi příkaz k\u00a0prodeji patřičné části ETFek a\u00a0zadanou částku '
					+ 'v\u00a0eurech převedeme k\u00a0jinému vašemu vybranému portfoliu. ETFka budou prodána podle jejich poměrného zastoupení '
					+ 'v\u00a0portfoliu tak, aby po prodeji bylo vaše portfolio opět optimálně rozložené. Částku převedeme v\u00a0eurech, '
					+ 'aby zbytečně nedocházelo ke směně do\u00a0českých korun a\u00a0zpátky. Pokud zadaná částka přesáhne aktuální '
					+ 'hodnotu ETFek, bude prodáno celé portfolio a\u00a0hotovost převedena na\u00a0vybrané portfolio.',
				transferWarning: 'Máte zadaný příkaz k\u00a0odprodeji a\u00a0přesunu hotovosti. Nelze již zadat příkaz k\u00a0přesunu '
					+ 'části portfolia bez prodeje ETF. Pro zadání přesunu bez prodeje ETF zrušte existující příkaz.',
			},
			all: {
				title: 'Odprodej všeho a\u00a0přesun hotovosti',
				text: 'Odprodej všeho a\u00a0přesun hotovosti znamená, že při nejbližším obchodním dni předáme brokerovi příkaz '
					+ 'k\u00a0prodeji všech ETFek v\u00a0tomto portfoliu a\u00a0hotovost v\u00a0eurech převedeme k\u00a0jinému vašemu '
					+ 'vybranému portfoliu. Částku převedeme v\u00a0eurech, aby zbytečně nedocházelo ke\u00a0směně do\u00a0českých '
					+ 'korun a\u00a0zpátky.',
				allPopup: {
					title: 'Přesun hotovosti mezi portfolii',
					text: 'Zadaná částka je velmi blízká celkové hodnotě Vašeho portfolia. Přejete si přesunout všechny Vaše prostředky?',
					button: 'Ano, přesunout vše',
					secondaryButton: 'Ne, přesunout pouze požadovanou částku',
				},
				activeText: 'Máte zadaný příkaz k\u00a0odprodeji všech ETF v\u00a0portfoliu a\u00a0přesun hotovosti do portfolia {{ portfolio }}',
			},
			part: {
				title: 'Přesun části portfolia bez prodeje ETF',
				text: 'Přesun části portfolia bez prodeje ETF znamená, že při nejbližším obchodním dni vypočítáme pro každé ETF, '
					+ 'které máte v\u00a0portfoliu, počet kusů odpovídající zadanému poměru (zaokrouhleno dolů). Dále vypočítáme poměr '
					+ 'hotovosti v\u00a0portfoliu (pro každou měnu) odpovídající zadanému poměru (zaokrouhleno na\u00a0setiny směrem nahoru). '
					+ 'Vypočítané poměry ETF a\u00a0hotovosti přesuneme na\u00a0vybrané portfolio. Nedojde tedy k\u00a0prodejům ETF.',
				activeText: 'Máte zadaný příkaz k\u00a0přesunu {{ sum }} ETF a\u00a0hotovosti z\u00a0tohoto portfolia do portfolia {{ portfolio }}.',
				error: {
					title: 'Upozornění',
					text: 'Zadaný poměr portfolia je příliš vysoký. Zadejte nižší poměr nebo zrušte dříve zadané příkazy '
						+ 'k\u00a0přesunu části portfolia.',
					button: 'Zavřít',
				},
				transferWarning: 'Máte zadaný příkaz k\u00a0přesunu části portfolia bez prodeje ETF. Nelze již zadat příkaz '
					+ 'k\u00a0odprodeji a\u00a0přesunu hotovosti. Pro zadání přesunu hotovosti zrušte existující příkaz.',
			},
			activeTitle: 'Přesun prostředků do\u00a0jiného portfolia',
			cancel: 'Zrušit příkaz',
			confirm: 'Zadat',
			childWarning: 'Prostředky můžete přesouvat pouze mezi portfolii dítěte. Není možné vybrat peníze z\u00a0dětského '
				+ 'portfolia na svůj osobní účet.',
			popup: {
				title: 'Upozornění',
				text: 'Potvrďte prosím, že chcete opravdu uskutečnit zadaný přesun.',
				textChild: 'Potvrďte prosím, že chcete opravdu uskutečnit zadaný přesun.\n'
					+ 'Upozorňujeme, že prostředky přesunuté na dětské portfolio nelze odeslat zpět na portfolio rodiče. Z dětských '
					+ 'portfolií je možno zadávat výběry na bankovní účet do 10 % hodnoty portfolia za rok. Pokud si přejete vybrat '
					+ 'celou částku, je nutné dětské portfolio zrušit.',
				button: 'Ano, zadat přesun',
				twoFactorAuthentication: 'Zadejte Váš jednorázový kód',
			},
			error: {
				title: 'Upozornění',
				text: 'Přesun nelze zadat, protože už máte zadaný příkaz na\u00a0výběr všech prostředků, zrušení účtu nebo přesun '
					+ 'celého portfolia. Aby bylo možné příkaz k\u00a0přesunu zadat, musíte nejprve zrušit předchozí příkazy.',
				button: 'Zavřít',
			},
		},
	},
	childList: {
		title: 'Moje děti',
		text: 'Zde můžete spravovat své dětské účty, dokončit rozpracovanou registraci nebo ji případně zrušit.',
		finishOnboarding: 'Dokončit registraci',
		accountClosure: 'Máte zadané zrušení účtu, nelze stornovat zrušení účtu dítěte.',
		personalInformation: {
			title: 'Kontaktní údaje dítěte',
			confirm: 'Uložit změny',
			cancelAccount: 'Zrušit účet dítěte',
		},
	},
	childClosure: {
		cancel: {
			title: 'Zrušení účtu',
			text: '<0>Potvrďte prosím, zda si opravdu přejete zrušit dětský investiční účet u\u00a0Fondee. Při předčasném '
				+ 'zrušení nedodržíte podmínky pro zvýhodněný poplatek. Z\u00a0částky před vyplacením proto dle smluvních podmínek '
				+ 'strhneme rozdíl mezi poplatkem, který jste dosud zaplatili, a\u00a0našim běžným poplatkem.</0>'
				+ '<0>Po odprodání Vašich ETF bude hotovost následně odeslána na Váš primární účet pro výběry. Celý proces může '
				+ 'trvat až jeden týden. V\u00a0případě, že si přejete prostředky vybrat v\u00a0Kč, bude zůstatek převeden '
				+ 'podle aktuálního kurzu. Pokyn ke zrušení účtu můžete dát kdykoli, investice prodáme nejbližší obchodní den. '
				+ 'Doporučujeme místo úplného zrušení účtu vybrat z\u00a0portfolia (nebo portfolií) všechny peníze. Příkaz jednoduše '
				+ 'zadáte v\u00a0detailu konkrétního <0>portfolia</0>. Tím získáte celou hodnotu portfolia, ale pokud někdy v\u00a0budoucnu '
				+ 'budete chtít znovu investovat s\u00a0Fondee, nemusíte si znovu zakládat účet.</0>',
			confirm: 'Rozumím, zrušit dětský účet',
			warning: 'Bylo zažádáno o\u00a0zrušení účtu',
		},
	},
	onboardingClosure: {
		title: 'Zrušení registrace',
		text: 'Potvrďte prosím, zda si opravdu přejete zrušit onboardingu dítěte.',
		cancel: 'Zrušit účet dítětě',
		confirm: 'Rozumím, zrušit',
	},
	employerList: {
		title: 'Moji zaměstnavatelé přispívající do DIP',
	},
};

const ageCheckBlock = {
	title: 'Upozornění',
	text: 'Je nám to líto, ale nesplňujete věkovou hranici pro investování s\u00a0Fondee. Rádi Vás opět '
		+ 'přivítáme, až dosáhnete osmnácti\u00a0let. Pokud si zatím přejete registraci zrušit, můžete tak '
		+ 'učinit přes odkaz\u00a0níže.',
	button: 'Zrušit účet',
	aborted: 'Váš učet byl zrušen.',
};

const allPortfoliosCheck = {
	legend: {
		negative: 'Aktuální profil',
		positive: 'Plánovaná změna při nejbližším obchodování',
	},
	table: {
		title: 'Název portfolia',
	},
	recommend: 'Profily v\u00a0<0>zeleném</0> intervalu jsme na základě Vámi vyplněného '
		+ 'investičního dotazníku vyhodnotili jako vhodné pro Vás.',
	recommendWarning: 'Profily v\u00a0šedém intervalu pro Vás nejsou dle vyhodnocení investičního '
		+ 'dotazníku vhodné.',
	confirm: 'Potvrdit',
	reset: 'Přeji si zkontrolovat odpovědi v\u00a0investičním dotazníku',
};

const anchors = {
	about: 'co-je-to-fondee',
	accountClosure: 'zruseni-uctu',
	chart: 'graf',
	comparison: 'porovnani',
	contactForm: 'kontaktujte-nas',
	employerList: 'moji-zamestnavatele',
	faq: 'nejcastejsi-dotazy',
	inflation: 'inflace',
	ourApproachTeam: 'koho-byste-vybrali',
	portfolio: 'portfolio',
	portfolioSettings: 'nastaveni-portfolia',
	portfolioWithdrawals: 'zadane-prikazy',
	products: 'produkty',
	rebalancing: 'rebalancovani',
	studentConfirmation: 'potvrzeni-studenta',
};

const appBanner = {
	title: 'Fondee',
	author: 'Fondee a.s.',
	downloadGoogle: 'ZDARMA - na\u00a0Google Play',
	downloadIOS: 'ZDARMA - v\u00a0App Store',
	visit: 'Zobrazit',
};

const blog = {
	meta: {
		title: 'Blog | Vše, co potřebujete vědět o investování',
		description: 'Praktické rady, tipy a novinky. Srozumitelně vysvětlujeme vše, co jste kdy chtěli vědět '
			+ 'o\u00a0financích a investování, ale báli jste se zeptat.',
	},
	back: '\u2190\u00a0Zpět',
	comingSoon: 'Brzy se sem vraťte, budou tu na Vás čekat nové články.',
	title: 'Fondee blog',
	text: 'Všechno, co jste kdy chtěli vědět o\u00a0investování, ale báli jste se zeptat.',
	menu: {
		title: 'Filtrace článků',
		search: {
			label: 'Hledat článek',
			empty: 'Nenašli jsme žádný článek',
		},
		tags: {
			title: 'Filtrovat podle tagů',
		},
		topRead: {
			title: 'Nejčtenejší články',
			button: 'Zobrazit nejčtenější',
		},
	},
};

const blogPost = {
	meta: {
		titleSuffix: ' - Fondee blog',
	},
	updated: 'Aktualizováno',
	share: {
		title: 'Líbil se vám článek?',
		description: 'Sdílejte ho se svými přáteli',
		button: 'Sdílet na ',
	},
	list: {
		button: 'Zobrazit další články',
	},
	recommended: {
		title: 'Další navazující články',
	},
	references: {
		title: 'Zdroje:',
	},
	content: {
		title: 'Obsah článku',
	},
};

const campaign = {
	calculation: {
		fondee: {
			enumeration: 'zhodnocení na Fondee\u00a0- míra inflace\u00a0= reálné zhodnocení',
			enumarationResult: '6\u00a0%\u00a0- 3,5\u00a0%\u00a0= 2,5\u00a0%',
			comparation: 'Za stejnou dobu by se Váš vklad na spořáku kvůli nízkému úroku ztenčil až o\u00a011\u00a0500\u00a0Kč.',
			info: 'Každý rok tak ve skutečnosti zhodnotíte své úspory o\u00a02,5\u00a0%. Při vkladu 100\u00a0000\u00a0Kč to za 5\u00a0let průměrně dělá: <0>+13\u00a0500\u00a0Kč</0>',
			link: 'Ukázat výpočet pro spořák',
		},
		account: {
			enumeration: 'úrok na spořáku\u00a0- míra inflace\u00a0= reálné zhodnocení',
			enumarationResult: '1\u00a0%\u00a0- 3,5\u00a0%\u00a0= -2,5\u00a0%',
			comparation: 'Za stejnou dobu by Váš vklad na Fondee mohl díky vyššímu úroku vyrůst průměrně o\u00a013\u00a0140\u00a0Kč.',
			info: 'Každý rok tak ve skutečnosti přicházíte o\u00a02,5\u00a0% svých úspor. Při vkladu 100\u00a0000\u00a0Kč to za 5\u00a0let dělá až: <0>-11\u00a0500\u00a0Kč</0>',
			link: 'Ukázat výpočet pro Fondee',
		},
		title: 'Jednoduchá matematika',
		button: 'Chci vyzkoušet Fondee ZDARMA',
	},
	calculator: {
		button: 'Spočítat',
		fields: {
			deposit: {
				label: 'Vklad',
				error: 'Hodnota vkladu musí být vyplněna.',
				errorRange: 'Hodnota vkladu musí být v\u00a0rozmezí od 1\u00a0000\u00a0Kč do 10\u00a0000\u00a0000\u00a0Kč.',
			},
			inflationRate: {
				label: 'Míra inflace',
				error: 'Hodnota míry inflace musí být vyplněna.',
				errorRangeMin: 'Není moc reálné, že by míra inflace klesla pod 1\u00a0%.',
				errorRangeMax: 'Na tuto hodnotu snad inflace nestoupne. Doufejme.',
			},
			interestRate: {
				fondee: {
					label: 'Zhodnocení',
					error: 'Hodnota zhodnocení je neměnná a\u00a0rovna 8\u00a0%.',
				},
				account: {
					label: 'Úrok na spořicím účtu',
					error: 'Hodnota úroku na spořicím účtu musí být vyplněna.',
					errorRange: 'Hodnota úroku na spořicím účtu musí být v\u00a0rozmezí 0 až 10\u00a0%.',
				},
			},
			years: {
				fondee: {
					label: 'Doba investování (roky)',
					error: 'Hodnota doby investování musí být vyplněna.',
					errorRange: 'Hodnota doby investování musí být v\u00a0rozmezí od 1\u00a0roku do 50\u00a0let.',
					errorFormat: 'Hodnota doby investování musí být celočíselná.',
				},
				account: {
					label: 'Doba spoření (roky)',
					error: 'Hodnota doby spoření musí být vyplněna.',
					errorRange: 'Hodnota doby spoření musí být v\u00a0rozmezí od 1\u00a0roku do 50\u00a0let.',
					errorFormat: 'Hodnota doby investování musí být celočíselná.',
				},
			},
		},
		how: 'Jak to?',
		result: {
			info: 'Reálná hodnota peněz:',
			value: '{{ value }}',
			fondee: 'Hurá! Váš vklad vyroste v\u00a0průměru o\u00a0<0>{{ value }}</0>.',
			fondeeComparation: 'Zatímco na spořáku vám až {{ value }} zmizí v\u00a0nenávratnu.',
			account: 'Na <0>spořicím účtu</0> se Váš vklad <0>znehodnotí</0> až o\u00a0<0>{{ value }}</0>.',
			accountComparation: 'S\u00a0<0>Fondee</0> by se naopak zhodnotil zhruba o\u00a0<1>{{ value }}</1>, '
				+ 'a\u00a0to i\u00a0po započtení vlivu inflace!',
		},
		subtitle: 'Jak se Vaše uložené peníze zhodnocují doopravdy?',
		title: 'Upřímná kalkulačka úspor',
		toggle: {
			fondee: 'Na Fondee',
			account: 'Na spořáku',
		},
	},
	explanation: {
		account: {
			info: 'Důvodem je nižší úročení na spořicích účtech, než je míra inflace. Proto na Vašem spořicím účtu peníze ztrácejí hodnotu.',
			title: 'Jak to, že ze spořáku o\u00a0své peníze přicházíte?',
			vocabulary: {
				title: 'Jednoduchý slovníček',
				collapse: [
					{
						name: 'Úrok na spořáku',
						description: 'O\u00a0kolik\u00a0% vyroste Váš vklad na spořicím účtu za 1\u00a0rok',
					},
					{
						name: 'Míra inflace',
						description: 'O\u00a0kolik\u00a0% se sníží hodnota měny za 1\u00a0rok',
					},
				],
			},
		},
		fondee: {
			info: 'Důvodem je vyšší zhodnocování investic s\u00a0Fondee, než je míra inflace. Proto vám s\u00a0Fondee peníze den co den přibývají.',
			title: 'Jak to, že na Fondee své peníze zhodnocujete?',
			vocabulary: {
				title: 'Jednoduchý slovníček',
				collapse: [
					{
						name: 'Zhodnocení na Fondee',
						description: 'Zhodnocení 6\u00a0% za rok je příklad na základě historických dat zhruba odpovídající akciovým portfoliím. '
							+ 'Na konkrétní portfolia a\u00a0jejich historická zhodnocení se můžete podívat <0>zde</0>.',
					},
					{
						name: 'Míra inflace',
						description: 'O\u00a0kolik % se sníží hodnota měny za 1\u00a0rok',
					},
				],
			},
		},
	},
	hero: {
		button: 'Založte si Fondee',
		subtitle: 'Vaše úspory nemusí ztrácet hodnotu na\u00a0spořicích\u00a0účtech.',
		title: 'Nespořte. Investujte!',
	},
	investWithFondee: {
		button: 'Chci vyzkoušet Fondee ZDARMA',
		list: [
			'<0>Historicky vyšší úrok</0>, než na spořicích účtech',
			'<0>Zvládne to úplně každý</0>, protože do akcií a\u00a0dluhopisů investujeme za Vás',
			'<0>3\u00a0měsíce</0> bez poplatků',
		],
		more: 'Více o\u00a0Fondee',
		title: 'Investujte s\u00a0Fondee',
	},
};

const childAgeCheck = {
	modal: {
		title: 'Blíží se plnoletost Vašeho dítěte',
		text: 'Dovršením plnoletosti dítěte dojde k\u00a0převedení jeho portfolia pod vlastní správu. K\u00a0tomu potřebujeme '
			+ 'doplnit osobní údaje vypsané níže. Pokud údaje nevyplníte, zůstane portfolio pod vaší plnou správou.',
		checkbox: 'Potvrzuji, že kontaktní údaje mého dítěte jsou správné a\u00a0aktuální.',
		button: 'Potvrdit údaje',
	},
};

const clientCheck = {
	description: '<0>Na této stránce kromě vlastních pravidelných kontrol najdete i\u00a0kontroly Vašich dětí, které je jednou '
		+ 'za čas potřeba provést.</0><0> Pokud zůstane kontrola nedokončena, omezíme Váš přístup do klientské zóny, stejně '
		+ 'tak jako přístup dítěte (pokud ho má). V\u00a0případě jakýchkoliv dotazů nás neváhejte kontaktovat '
		+ 'na\u00a0<0>info@fondee.cz</0>.</0>',
	childDescription: 'Pro obnovení přístupu do klientské zóny je potřeba dokončit kontrolu dětského účtu. Kontrolu musí '
		+ 'provést rodič ve své klientské zóně na\u00a0stránce Pravidelné kontroly.',
	questionnaire: {
		aml: {
			title: 'Kontrola původu peněz',
			text: 'Abychom mohli majetek Váš i\u00a0ostatních klientů nadále chránit, musíme jednou za čas prověřit, že všechny '
				+ 'údaje, které jste nám poskytli, jsou aktuální. Pojďme na to, proces zabere jen pár minut.',
			start: 'Vyplnit dotazník',
			closeAccount: {
				title: 'Zrušení účtu',
				text: 'Vyberte měnu',
				button: 'Zrušit účet',
			},
		},
		investment: {
			title: 'Kontrola investičního dotazníku',
			text: 'Čas od času musíme prověřit, zda se u Vás nezměnily investiční okolnosti. '
				+ 'V případě že ano, může to mít dopad na volbu Vašeho ideálního investičního profilu. '
				+ 'Upozorňujeme, že na základě nových odpovědí Vám může vyjít jiný profil, než máte nyní zvolený. '
				+ 'Odpovědi můžete před dokončením kdykoliv změnit.',
			warning: 'Upozorňujeme, že pro úspěšné dokončení kontroly je potřeba změny potvrdit. Pokud změny nepotvrdíte, '
				+ 'bude Vám po 30\u00a0dnech od spuštění kontroly zamknuta Klientská zóna. Pokud jste v\u00a0dotazníku udělali '
				+ 'chybu, můžete se k\u00a0odpovědím vrátit.',
			start: 'Vyplnit dotazník',
			confirmStart: {
				title: 'Varování',
				text: '<0><0>POZOR:</0> Odpovědi <0>ovlivní investiční profil</0> Vašeho stávajícího portfolia!</0>'
					+ '<0>Pokud zvolíte konzervativnější odpovědi, než odpovídají Vaší aktuální rizikovosti, bude nutné změnit '
					+ 'investiční profil Vašeho portfolia.</0>'
					+ '<0>Vyplňte prosím dotazník maximálně pečlivě.</0>',
				button: 'Rozumím',
			},
			confirmProfile: {
				title: 'Potvrzení investičního profilu',
				text: 'Chystáte se <0>potvrdit stávající investiční profil</0> Vašeho portfolia:',
				more: 'Chcete pokračovat?',
				portfolio: 'Portfolio',
				confirm: 'Pokračovat',
				back: 'Zpět k\u00a0dotazníku',
			},
			changeProfile: {
				title: 'Změna investičního profilu',
				text: 'Chystáte se <0>změnit investiční profil</0> u\u00a0následujících portfolií:',
				portfolio: 'Portfolio',
				change: 'Z {{ from }} na {{ to }}',
				secondaryText: 'Tato změna spustí automatické rebalancování během nejbližšího obchodního dne (může být i\u00a0dnes), '
					+ 'což zahrnuje prodej a\u00a0nákup ETF.',
				more: 'Chcete pokračovat?',
				confirm: 'Pokračovat',
				back: 'Zpět k\u00a0dotazníku',
				support: 'Kontaktovat podporu',
				success: 'Děkujeme, e-mail byl odeslán. Budeme Vás kontaktovat co nejdříve.',
			},
		},
	},
	documents: {
		error: 'Omlouváme se, při nahrávání dokladů došlo k chybě.',
		title: 'Nahrání platného dokladu',
		text: 'Nahrajte prosím svůj aktuální osobní doklad. Po kliknutí na tlačítko Vás přesměrujeme k\u00a0naší partnerské '
			+ 'službě, která pro nás zpracování dokladů zajišťuje. Proces probíhá šifrovaně, abychom zajistili bezpečí osobních údajů.',
		textChild: 'Nahrajte prosím osobní doklad Vašeho dítěte. Po kliknutí na tlačítko Vás přesměrujeme k\u00a0naší partnerské '
			+ 'službě, která pro nás zpracování dokladů zajišťuje. Proces probíhá šifrovaně, abychom zajistili bezpečí osobních údajů.',
	},
	personalInformation: {
		title: 'Kontrola osobních údajů',
	},
	checkAt: 'Provedená kontrola: <0>{{ date }}</0>',
	checkNext: 'Plánovaná kontrola: <0>{{ date }}</0>',
	profileSelect: {
		prev: 'Předchozí',
		next: 'Následující',
		myAccount: 'Můj účet',
	},
	disabledTooltip: 'Akce se odemkne po dokončení kontroly',
};

const clientHeader = {
	faqLink: 'Jak a kdy probíhá investování',
	nextTradingDay: 'Nejbližší obchodní den',
	titles: {
		deposit: 'Vklad',
		depositAndWithdrawal: 'Vklad a výběr',
		trading: 'Obchodování',
		withdrawal: 'Výběr',
	},
};

const contact = {
	title: 'Zeptejte se',
	text: 'Přečtěte si nejčastější otázky nebo nám napište pomocí formuláře níže',
	contactFormAnchor: 'Přejít k\u00a0formuláři',
	faqAnchor: 'Přejít k\u00a0nejčastějším dotazům',
	faqTitle: 'Nejčastější dotazy',
};

const contactForm = {
	button: 'Nechte nám kontakt<0></0>a\u00a0my vám zavoláme',
	modal: {
		title: 'Chcete se na něco zeptat?',
		subtitle: 'Nechte nám na sebe číslo, ozveme se\u00a0vám',
		submit: 'Zavolejte mi',
		success: 'Děkujeme, brzy se vám\u00a0ozveme!',
	},
};

const contactUs = {
	title: 'Nenašli jste odpověď na své\u00a0otázky?',
	text: {
		label: 'Váš dotaz',
		error: 'Vaše otázka je nutná',
	},
	name: {
		label: 'Jméno a příjmení',
		error: 'Prosím, zadejte Vaše jméno.',
	},
	email: {
		label: 'E-mail',
		error: 'Prosím, zadejte Vaši e-mailovou adresu.',
	},
	conditions: {
		label: 'Souhlasím se <0>zpracováním osobních údajů</0> a\u00a0<1>pravidly webu</1>',
		error: 'Bez tohoto souhlasu to bohužel nepůjde.',
	},
	submit: 'Odeslat dotaz',
	success: 'Děkujeme za Váš dotaz, brzy se vám ozveme!',
};

const cookieConsent = {
	message: '<0>Fondee\u00a0a.s. používá soubory cookie, aby své internetové stránky lépe přizpůsobila '
		+ 'zákazníkům a\u00a0usnadnila jejich používání. Nezbytné cookies zpracováváme vždy i\u00a0bez Vašeho '
		+ 'souhlasu, tím zajišťujeme správné fungování internetových stránek.</0><0>Pokud kliknete na tlačítko '
		+ '„Přijmout vše“, dáte tím Fondee\u00a0a.s. souhlas s\u00a0ukládáním analytických a\u00a0marketingových '
		+ 'souborů cookie. Pokud kliknete na tlačítko „Přijmout pouze nezbytné“, dáte tím Fondee\u00a0a.s. '
		+ 'souhlas s\u00a0ukládáním pouze funkčních souborů cookie. Pokud kliknete na možnost „Detailní nastavení“, '
		+ 'můžete zvolit, které účely zpracování cookie chcete povolit.</0><0>Pomocí souborů cookie můžeme my '
		+ 'i\u00a0třetí strany shromažďovat informace o\u00a0Vás, a\u00a0sledovat tak '
		+ 'Vaše chování při procházení našich i\u00a0případně jiných stránek. Na\u00a0základě těchto informací '
		+ 'můžeme své stránky, svou komunikaci i\u00a0své reklamy přizpůsobit podle Vašich zájmů a\u00a0profilu. '
		+ 'Navíc Vám tyto soubory cookie umožňují sdílení informací na\u00a0sociálních médiích.</0><0>'
		+ 'Více informací naleznete v\u00a0našich <0>Informacích o\u00a0zpracování osobních údajů</0> '
		+ 'a\u00a0v\u00a0<1>Zásadách používání souborů cookie</1>.</0>',
	controls: {
		details: 'Detailní nastavení',
		necessary: 'Přijmout nezbytné',
		selected: 'Přijmout pouze vybrané',
		all: 'Přijmout vše',
	},
	categories: {
		functionality: {
			title: 'Funkční cookies',
			text: 'Používáme především proto, abychom zajistili, že naše webové stránky budou řádně fungovat, '
				+ 'abychom zjistili, jaké jsou Vaše preference a\u00a0aby si webová stránka pamatovala, že jste '
				+ 'se přihlásili, a\u00a0dále ke sledování uživatelů za účelem jejich registrace. Nezbytné cookies '
				+ 'se dále používají k\u00a0optimalizaci našich webových stránek v reálném čase podle toho, jak stránky '
				+ 'používáte a\u00a0k\u00a0rovnoměrnému načítání webových stránek. Bez těchto souborů nemohou Internetové '
				+ 'stránky řádně fungovat.',
		},
		analytics: {
			title: 'Analytické cookies',
			text: 'Slouží k\u00a0analýze používání Internetových stránek, k\u00a0počítání návštěvníků, ke sledování doby '
				+ 'návštěvnosti a\u00a0zjišťování, jak lze Internetové stránky vylepšit. Statistiky používání Internetových stránek '
				+ 'a\u00a0další zprávy nespojujeme s\u00a0konkrétními lidmi.',
		},
		marketing: {
			title: 'Cookies pro profilování a\u00a0remarketing',
			text: 'Slouží ke shromažďování osobních údajů o\u00a0uživateli z\u00a0marketingového hlediska. Např. shromažďují '
				+ 'informace za účelem přizpůsobení nabízené reklamy zájmům zákazníka, propojení se sociálními sítěmi, předávání '
				+ 'informací mezi affiliate partnery\u00a0atd.',
		},
	},
};

const cookies = {
	title: 'Zásady používání souborů cookie',
	intro: '<0>Toto jsou zásady používání souborů cookie (dále jen „<0>Zásady</0>“) na\u00a0internetových stránkách '
		+ 'společnosti <0>Fondee\u00a0a.s.</0>, se sídlem Rumunská\u00a022/28, Vinohrady, 120\u00a000 Praha\u00a02, '
		+ 'IČO:\u00a006691862, zapsané v\u00a0obchodním rejstříku vedeném Městským soudem v\u00a0Praze pod '
		+ 'sp.\u00a0zn.\u00a0B\u00a027322 (dále jen „<0>Společnost</0>“ nebo „<0>Správce</0>“) a\u00a0v\u00a0rámci aplikace '
		+ 'Správce nazvané „Fondee mobilní aplikace“ (dále jen „<0>Aplikace</0>“) a\u00a0vztahují se na '
		+ 'všechny uživatele internetových stránek provozovaných Společností (dále jen „<0>Internetové stránky</0>“) '
		+ 'a\u00a0na Aplikaci tam, kde je to relevantní.</0>'
		+ '<0>Účelem těchto Zásad je náležitě informovat uživatele Internetových stránek a/nebo Aplikace o\u00a0používání souborů cookie '
		+ 'a\u00a0o\u00a0možnosti jejich používání zakázat, jakož i\u00a0je jejich účelem poskytnutí informací, jakým '
		+ 'způsobem jsou zpracovávány a\u00a0používány údaje uživatelů Internetových stránek a/nebo Aplikace a\u00a0jak pomocí souborů '
		+ 'cookie Společnost zpracovává informace o\u00a0uživatelích Internetových stránek.</0>'
		+ '<0>Společnost nepoužívá soubory cookie, aby zjistila osobní údaje uživatelů, nicméně osobní údaje, které Společnost '
		+ 'jejich prostřednictvím získá, může případně spojit s\u00a0jinými osobními údaji uživatelů, které uživatelé '
		+ 'Společnosti poskytli nebo je Společnost získala jiným způsobem. Společnost zpracovává osobní údaje uživatelů, '
		+ 'včetně osobních údajů získaných díky používání souborů cookie, v\u00a0souladu s\u00a0Nařízením Evropského parlamentu '
		+ 'a\u00a0Rady\u00a0(EU) č.\u00a02016/679 ze dne 27.\u00a0dubna\u00a02016, o\u00a0ochraně fyzických osob '
		+ 'v\u00a0souvislosti se zpracováním osobních údajů a\u00a0o\u00a0volném pohybu těchto údajů, a\u00a0souvisejícími '
		+ 'právními předpisy na\u00a0ochranu osobních údajů a\u00a0používání souborů\u00a0cookie.</0>',
	about: {
		title: 'Co jsou soubory cookie',
		text: '<0>Cookies představují krátké textové soubory, jež obvykle obsahují jedinečný identifikátor '
			+ 'a\u00a0které jsou zaslány do prohlížeče Vašeho koncového zařízení a\u00a0jsou umístěny v\u00a0úložišti Vašeho '
			+ 'koncového zařízení. Uvedený soubor obsahuje některé anonymní informace jako je identifikátor, název webové '
			+ 'stránky, případně dobu trvání (okamžik expirace). Daný soubor může být dočasný a\u00a0existuje v\u00a0době Vašeho '
			+ 'přihlášení na naši webovou stránku, anebo může mít trvalou povahu a\u00a0zůstává v\u00a0úložišti Vašeho koncového '
			+ 'zařízení po dobu stanovené expirace nebo dokud není odstraněn uživatelem.</0>',
		app: {
			title: 'Využití cookies v\u00a0Aplikaci',
			text: '<0>Aplikace používá funkční cookies. Funkční cookies jsou nezbytně nutné k\u00a0tomu, aby Aplikace správně '
				+ 'fungovala a\u00a0zároveň aby si pamatovala, že jste se přihlásili. Právě díky funkčním cookies můžeme monitorovat '
				+ 'bezchybný chod aplikace a\u00a0připravovat nové verze aplikace apod. Údaje získané díky funkčním cookies zpracováváme '
				+ 'vždy a\u00a0pouze v\u00a0agregované podobě, tedy bez identifikace konkrétního uživatele. Údaje zpracovávané díky '
				+ 'funkčním cookies nebudou využity k\u00a0marketingovému cílení.</0>'
				+ '<0>V\u00a0Aplikaci používáme také základní analytické nástroje. Do Vašeho koncového zařízení však nejsou ukládány '
				+ 'žádné informace, jako je tomu u\u00a0analytických cookies na Internetových stránkách. Tyto informace se týkají pouze '
				+ 'zobrazení jednotlivých stránek v\u00a0Aplikaci a\u00a0jsou plně anonymizované a\u00a0agregované. Žádné informace '
				+ 'o\u00a0konkrétním uživateli nejsou sbírány ani ukládány.</0>'
				+ '<0>Instalací a\u00a0využíváním Aplikace souhlasíte se zpracováním funkčních cookies v\u00a0rozsahu popsaném výše.</0>',
		},
	},
	types: {
		title: 'Rozdělení souborů cookie',
		texts: [
			{
				text: '<0>Podle zdroje a\u00a0kontroly rozlišujeme následující soubory cookie:</0>',
				list: [
					'<0>Cookies první strany</0>, které jsou vytvářeny Internetovými stránkami. Při návštěvě Internetových stránek '
					+ 'jsou tyto soubory cookie pod kontrolou a\u00a0správou Společnosti.',
					'<0>Cookies třetích stran</0>, které jsou vytvářeny ostatními weby, které na\u00a0Internetových stránkách vlastní '
					+ 'určitý obsah a\u00a0tyto soubory cookie nejsou pod kontrolou Společnosti.',
				],
			},
			{
				text: '<0>Podle doby uložení na Vašem koncovém zařízení se dělí soubory cookie\u00a0na:</0>',
				list: [
					'<0>Dočasné</0>, které jsou po skončení Vaší návštěvy Internetových stránek smazány.',
					'<0>Trvalé</0>, které zůstávají uloženy i\u00a0po ukončení Vaší návštěvy Internetových stránek.',
				],
			},
			{
				text: '<0>V\u00a0závislosti na\u00a0funkci a\u00a0účelu, za kterými soubory cookie používáme, je dělíme '
					+ 'na\u00a0následující:</0>',
				list: [
					'<0>Funkční cookies</0>, které používáme především proto, abychom zajistili, že naše Internetové stránky '
					+ 'a\u00a0Aplikace budou řádně fungovat, abychom zjistili, jaké jsou Vaše preference a\u00a0aby si Internetová stránka '
					+ 'a\u00a0Aplikace pamatovala, že jste se přihlásili, a\u00a0dále ke sledování uživatelů za účelem jejich registrace. '
					+ 'Nezbytné cookies se dále používají k\u00a0optimalizaci našich Internetových stránek a\u00a0Aplikace v\u00a0reálném čase '
					+ 'podle toho, jak stránky používáte a\u00a0k\u00a0rovnoměrnému načítání Internetových stránek a\u00a0Aplikace. '
					+ 'Bez těchto souborů nemohou Internetové stránky a\u00a0Aplikace řádně fungovat.',
					'<0>Analytické cookies</0>, které slouží k\u00a0analýze používání Internetových stránek, k\u00a0počítání '
					+ 'návštěvníků, ke\u00a0sledování doby návštěvnosti a\u00a0k\u00a0zjišťování, jak lze Internetové stránky vylepšit. '
					+ 'Statistiky používání Internetových stránek a\u00a0další zprávy nespojujeme s\u00a0konkrétními lidmi.',
					'<0>Marketingové cookies</0>, které slouží ke shromažďování osobních údajů o\u00a0uživateli z\u00a0marketingového '
					+ 'hlediska. Tato cookies např. shromažďují informace za účelem přizpůsobení nabízené reklamy zájmům zákazníka, propojení '
					+ 'se sociálními sítěmi, předávání informací mezi affiliate partnery\u00a0atd.',
				],
			},
		],
	},
	table: {
		title: 'Přehled konkrétních cookies používaných na\u00a0Internetových stránkách',
		head: [
			'Cookies',
			'Doména nebo\u00a0nástroj',
			'První\u00a0strany /\u00a0Třetí\u00a0strany',
			'Účel',
			'Kategorie',
			'Doba',
		],
		rows: [
			{
				name: '_ga',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Počítá a\u00a0ukládá počet zobrazení stránek.',
				category: 'Analytické',
				period: '2\u00a0roky',
			},
			{
				name: '_gid',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Počítá a\u00a0ukládá počet zobrazení stránek.',
				category: 'Analytické',
				period: '1\u00a0den',
			},
			{
				name: '_gat_UA-*',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Omezuje objem dat, které Google ukládá na\u00a0stránkách s\u00a0vysokým objemem návštěv.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: 'YSC',
				domain: 'youtube.com',
				party: 'Třetí strany',
				purpose: 'Monitoruje a\u00a0sbírá informace o\u00a0akcích uživatele u\u00a0videí z\u00a0YouTube.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: 'PHPSESSID',
				domain: 'api.fondee.cz',
				party: 'První strany',
				purpose: 'Zajišťuje základních funkčnost webových stránek.',
				category: 'Funkční',
				period: '14\u00a0dní',
			},
			{
				name: '_nss',
				domain: 'api.fondee.cz',
				party: 'První strany',
				purpose: 'Kontroluje, co spustilo načtení stránky.',
				category: 'Funkční',
				period: 'Session',
			},
			{
				name: '_nss',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Kontroluje, co spustilo načtení stránky.',
				category: 'Funkční',
				period: 'Session',
			},
			{
				name: 'LaUserDetails',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Ukládá data pro identifikaci uživatelů.',
				category: 'Funkční',
				period: 'Session',
			},
			{
				name: 'LaSID',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Identifikátor session návštěvníka.',
				category: 'Funkční',
				period: 'Session',
			},
			{
				name: 'LaVisitorId_*',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Identifikátor návštěvníka na\u00a0každé stránce, kde je integrován chatovací widget.',
				category: 'Funkční',
				period: 'Session',
			},
			{
				name: 'LaVisitorNew',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Informace, zda se jedná o\u00a0nového nebo vracejícího se návštěvníka.',
				category: 'Funkční',
				period: '1\u00a0den',
			},
			{
				name: COOKIE_CONSENT_NAME,
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Ukládá souhlas se\u00a0zpracováním cookies.',
				category: 'Funkční',
				period: '1\u00a0rok',
			},
			{
				name: 'PHPSESSID',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Zajišťuje základních funkčnost webových stránek.',
				category: 'Funkční',
				period: '30\u00a0dní',
			},
			{
				name: 'VISITOR_INFO1_LIVE',
				domain: 'youtube.com',
				party: 'Třetí strany',
				purpose: 'Umožňuje odhadnout rychlost internetu uživatele pro přehrání Youtube videa.',
				category: 'Funkční',
				period: '6\u00a0měsíců',
			},
			{
				name: 'fondee_infobox',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Sledování, zda uživatel odkliknul oznámení na\u00a0Internetových stránkách.',
				category: 'Funkční',
				period: '1\u00a0rok',
			},
			{
				name: 'seen_find_out_profile_popup',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Používá se pro sledování uživatelů za účelem registrace.',
				category: 'Funkční',
				period: 'Trvalé',
			},
			{
				name: '_fbp',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Sleduje a\u00a0ukládá návštěvy napříč stránkami.',
				category: 'Marketingové',
				period: '3\u00a0měsíce',
			},
			{
				name: '_gcl_au',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Sleduje a\u00a0ukládá konverze ulživatelů.',
				category: 'Marketingové',
				period: 'Trvalé',
			},
			{
				name: 'IDE',
				domain: 'doubleclick.net',
				party: 'Třetí strany',
				purpose: 'Sledování uživatele za účelem zobrazování reklam.',
				category: 'Marketingové',
				period: '13\u00a0měsíců',
			},
			{
				name: 'test_cookie',
				domain: 'doubleclick.net',
				party: 'Třetí strany',
				purpose: 'Sledování uživatele za účelem zobrazování reklam.',
				category: 'Marketingové',
				period: '15\u00a0minut',
			},
			{
				name: 'sid',
				domain: 'seznam.cz',
				party: 'Třetí strany',
				purpose: 'Sledování uživatele za účelem zobrazování reklam.',
				category: 'Marketingové',
				period: '30\u00a0dní',
			},
			{
				name: 'ehubSession_*',
				domain: 'ehub',
				party: 'Třetí strany',
				purpose: 'Sledování registrací z\u00a0affiliate programů.',
				category: 'Marketingové',
				period: '30\u00a0dní',
			},
			{
				name: 'ehubVisitorId',
				domain: 'ehub',
				party: 'Třetí strany',
				purpose: 'Sledování registrací z\u00a0affiliate programů.',
				category: 'Marketingové',
				period: '30\u00a0dní',
			},
			{
				name: '_hjSessionUser{site_id}',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Přiděluje jednoznačné ID každému návštěvníkovi stránek.',
				category: 'Analytické',
				period: '365\u00a0dní',
			},
			{
				name: '_hjSession{site_id}',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Ukládá aktuální data ze Session.',
				category: 'Analytické',
				period: '30\u00a0minut',
			},
			{
				name: '_hjClosedSurveyInvites',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Přiděluje se ve chvíli, kdy uživatel provede akci s\u00a0oknem dotazníku.',
				category: 'Analytické',
				period: '365\u00a0dní',
			},
			{
				name: '_hjDonePolls',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Přiděluje se ve chvíli, kdy uživatel dokončí dotazník přes widget na Internetových stránkách.',
				category: 'Analytické',
				period: '365\u00a0dní',
			},
			{
				name: '_hjMinimizedPolls',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Přiděluje se ve chvíli, kdy uživatel minimalizuje widget na Internetových stránkách.',
				category: 'Analytické',
				period: '365\u00a0dní',
			},
			{
				name: '_hjShownFeedbackMessage',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Přiděluje se ve chvíli, kdy uživatel minimalizuje nebo dokončí zpětnou vazbu.',
				category: 'Analytické',
				period: '365\u00a0dní',
			},
			{
				name: '_hjSessionTooLarge',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Aktivuje se ve chvíli, kdy velikost dat v\u00a0Session je příliš velká.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: '_hjSessionRejected',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Přiděluje se ve chvíli, kdy externí server odmítl komunikaci z\u00a0důvodu přetíženosti.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: '_hjSessionResumed',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Přiděluje se ve chvíli, kdy bylo po výpadku obnoveno spojení se serverem.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: '_hjid',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Přiděluje jednoznačné ID každému návštěvníkovi stránek.',
				category: 'Analytické',
				period: '365\u00a0dní',
			},
			{
				name: '_hjRecordingLastActivity',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Používá se pro sledování akcí uživatelů.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: '_hjTLDTest',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Používá se v\u00a0případě, že je cookies nutno sdílet mezi subdoménami.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: '_hjUserAttributesHash',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Enkryptováné atributy návštěvníků posílané přes Hotjar API.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: '_hjCachedUserAttributes',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Uložené enkryptováné atributy návštěvníků.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: '_hjLocalStorageTest',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Slouží k určení, zda Hotjar Tracking Script může použít lokální úložiště.',
				category: 'Analytické',
				period: 'Méně než 100\u00a0ms',
			},
			{
				name: '_hjIncludedInPageviewSample',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Slouží k\u00a0určení, zda je uživatel součástí trackovacího vzorku dle zobrazení stránek.',
				category: 'Analytické',
				period: '30\u00a0minut',
			},
			{
				name: '_hjIncludedInSessionSample',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Slouží k\u00a0určení, zda je uživatel součástí trackovacího vzorku dle omezení délky Session.',
				category: 'Analytické',
				period: '30\u00a0minut',
			},
			{
				name: '_hjAbsoluteSessionInProgress',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Identifikuje první Session daného uživatele podle zobrazení stránek.',
				category: 'Analytické',
				period: '30\u00a0minut',
			},
			{
				name: '_hjFirstSeen',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Identifikuje první Session daného uživatele.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: '_hjViewportId',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Ukládá informace o\u00a0viewportu uživatele, např. rozlišení a\u00a0velikost.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: '_hjRecordingEnabled',
				domain: 'fondee.cz',
				party: 'První strany',
				purpose: 'Používá se pro kontrolu, zde je návštěvník již součástí nějakého sledování či nikoliv.',
				category: 'Analytické',
				period: 'Session',
			},
		],
	},
	options: {
		title: 'Vaše možnosti při správě souborů cookie',
		text: '<0>Při návštěvě Internetových stránek se Vám zobrazí tzv.\u00a0cookie banner s\u00a0možností nastavení '
			+ 'volitelných cookies a\u00a0s\u00a0možností jejich přijetí nebo odmítnutí. V\u00a0rámci cookie banneru máte '
			+ 'možnost zvolit si své preference a\u00a0potvrdit jejich výběr, který můžete kdykoli změnit níže na\u00a0této stránce.</0>'
			+ '<0>Cookies můžete rovněž zakázat změnou nastavení Vašeho prohlížeče, které umožňuje odmítnout používání všech nebo '
			+ 'jen vybraných cookies. Rádi bychom Vás však upozornili, že pokud nastavíte prohlížeč tak, že vypnete všechny cookie '
			+ 'včetně nezbytných, nemusí se Vám podařit získat přístup na\u00a0Internetové stránky a/nebo může být omezena jejich funkčnost.</0>'
			+ '<0>Zakázáním cookies se cookies z\u00a0prohlížeče neodstraní a\u00a0je potřeba je odstranit nezávisle v\u00a0prohlížeči. '
			+ 'Rovněž tak můžete odstranit dosavadní cookies, které jsou již umístěné v\u00a0úložišti Vašeho koncového zařízení.</0>'
			+ '<0>Prostřednictvím nastavení Vašeho prohlížeče můžete povolit všechny soubory cookie, blokovat všechny soubory cookie '
			+ 'nebo jen soubory cookie třetích stran a\u00a0rovněž můžete požadovat upozornění týkající se ukládaných cookies. '
			+ 'Podrobnější informace o\u00a0nastavení cookies jsou k\u00a0dispozici přímo v\u00a0nastavení Vašeho prohlížeče, případně '
			+ 'nahlédněte do nápovědy Vašeho prohlížeče.</0>',
	},
	management: {
		title: 'Správa cookies třetích stran',
		text: '<0>Pro podrobnější informace o\u00a0tom, jak jsou spravovány a\u00a0nastaveny cookies třetích stran, '
			+ 'doporučujeme přečíst příslušné zásady používání cookies:</0>',
		list: [
			{
				title: 'Youtube',
				link: 'https://www.youtube.com/account_privacy',
			},
			{
				title: 'Hotjar',
				link: 'https://www.hotjar.com/legal/policies/privacy/',
			},
			{
				title: 'Google Analytics',
				link: 'https://www.cookiepolicygenerator.com/cookies-policy-google-analytics/',
			},
			{
				title: 'Live Agent',
				link: 'https://support.liveagent.com/709382-Cookies-used-by-LiveAgent',
			},
			{
				title: 'Facebook',
				link: 'https://www.facebook.com/policy/cookies/',
			},
			{
				title: 'LinkedIn',
				link: 'https://cz.linkedin.com/legal/cookie-policy',
			},
			{
				title: 'Google',
				link: 'https://policies.google.com/technologies/cookies?hl=cs',
			},
			{
				title: 'Seznam',
				link: 'https://www.seznam.cz/reklama/cz/obsahovy-web/pravidla-reklamy/gdpr/#data-ktera-jsou-potrebna',
			},
		],
	},
	final: {
		title: 'Závěrečná ustanovení',
		text: '<0>Informace o\u00a0zpracování osobních údajů jsou k\u00a0dispozici <0>zde</0>.</0>'
			+ '<0>S\u00a0ohledem na to, že svět informačních technologií se pořád vyvíjí, vyhrazujeme si právo kdykoli tyto '
			+ 'Zásady změnit.</0>'
			+ '<0>Máte-li jakýkoli dotaz v\u00a0souvislosti se soubory cookie, obraťte se na nás na\u00a0<1>info@fondee.cz</1>.</0>',
	},
	edit: 'Nastavení cookies',
};

const dashboard = {
	filter: {
		title: 'Zhodnocení za',
		options: {
			day: 'den',
			week: 'týden',
			month: 'měsíc',
			threemonths: '3 měsíce',
			sixmonths: '6 měsíců',
			year: 'rok',
			fromstart: 'od počátku',
		},
	},
	chart: {
		loadmore: 'Načíst další',
		loadall: 'Načíst vše',
		toggle: {
			value: 'Hodnota',
			evaluation: 'Zhodnocení',
		},
		tooltip: {
			value: 'Hodnota',
			evaluation: 'Pohyb hodnoty portfolia',
			nonGrowthPlus: 'Vklady/bonusy',
			nonGrowthMinus: 'Výběry/přesuny mezi portfolii',
			growthPlus: 'Dividendy/vratky',
			growthMinus: 'Poplatky',
		},
	},
};

const documents = {
	title: 'Dokumenty',
	subtitle: 'Smlouvy a\u00a0jiné dokumenty',
	secondTitle: 'Povinně uveřejňované informace',
	reportsTitle: 'Výroční zprávy',
	esgTitle: 'Informace související s\u00a0udržitelností',
	otherTitle: 'Jiné dokumenty',
	contract: 'Podepsaná smlouva s\u00a0Fondee',
	conditions: 'Obchodní podmínky',
	terms: 'Pravidla používání webových stránek',
	personalInfoAgreement: 'Ochrana osobních údajů',
	informationSheet: 'Informace pro zákazníky',
	agreementTemplate: {
		standard: 'Vzorová smlouva',
		child: 'Vzorová smlouva - nezletilí',
		pension: 'Vzorová smlouva - DIP',
	},
	complaintsProcedure: 'Reklamační řád',
	annualReport2021: '2021 Výroční zpráva',
	annualReportTemplates2021: '2021 Šablony pro uveřejňování informací OCP',
	annualReport2022: '2022 Výroční zpráva',
	annualReportTemplates2022: '2022 Šablony pro uveřejňování informací OCP',
	annualReport2023: '2023 Výroční zpráva',
	annualReportTemplates2023: '2023 Šablony pro uveřejňování informací OCP',
	esgInfolist: 'ESG infolist',
	esgSustainability: 'Prohlášení o\u00a0politikách udržitelnosti a\u00a0nezohledňování nepříznivých dopadů investičních rozhodnutí',
	legalRepresentativeDeclaration: 'Prohlášení zákonného zástupce',
	pensionConfirmationAccount: 'Potvrzení pro zaměstnavatele o\u00a0sjednání DIP',
	pensionConfirmationDeposit: 'Potvrzení o\u00a0Vašich vkladech na DIP',
	pensionConfirmationEmployerDeposit: 'Potvrzení o\u00a0vkladech zaměstnavatele na DIP',
	tradingVenues: 'Seznam převodních míst',
	ordersExecution: 'Shrnutí a\u00a0závěry analýzy kvality provádění pokynů za\u00a0rok 2023',
};

const documentUrls = {
	conditions: `${process.env.PUBLIC_URL}/files/Fondee_Obchodni_podminky.pdf`,
	terms: `${process.env.PUBLIC_URL}/files/fondee-podminky-pouzivani-webovych-stranek.pdf`,
	personalInfoAgreement: `${process.env.PUBLIC_URL}/files/fondee-souhlas-se-zpracovanim-osobnich-udaju.pdf`,
	informationSheet: `${process.env.PUBLIC_URL}/files/Fondee_informacni_list.pdf`,
	agreementTemplate: {
		standard: `${process.env.PUBLIC_URL}/files/Fondee_Smlouva-vzor.pdf`,
		child: `${process.env.PUBLIC_URL}/files/Fondee_smlouva_nezletili.pdf`,
		pension: `${process.env.PUBLIC_URL}/files/Fondee_smlouva_DIP.pdf`,
	},
	complaintsProcedure: `${process.env.PUBLIC_URL}/files/Fondee_Reklamacni_rad.pdf`,
	annualReport2021: `${process.env.PUBLIC_URL}/files/Fondee_vyrocni_zprava_2021.pdf`,
	annualReportTemplates2021: `${process.env.PUBLIC_URL}/files/Fondee_sablony_pro_uverejnovani_informaci_OCP_2021.xlsx`,
	annualReport2022: `${process.env.PUBLIC_URL}/files/Fondee_vyrocni_zprava_2022.pdf`,
	annualReportTemplates2022: `${process.env.PUBLIC_URL}/files/Fondee_sablony_pro_uverejnovani_informaci_OCP_2022.xlsx`,
	annualReport2023: `${process.env.PUBLIC_URL}/files/Fondee_vyrocni_zprava_2023.pdf`,
	annualReportTemplates2023: `${process.env.PUBLIC_URL}/files/Fondee_sablony_pro_uverejnovani_informaci_OCP_2023.xlsx`,
	esgInfolist: `${process.env.PUBLIC_URL}/files/Fondee_ESG_infolist.pdf`,
	esgSustainability: `${process.env.PUBLIC_URL}/files/Fondee_Prohlaseni_o_nezohlednovani.pdf`,
	feesIllustratedUrl: `${process.env.PUBLIC_URL}/files/Fondee_Ilustrativni_dopad_poplatku.pdf`,
	tradingVenues: `${process.env.PUBLIC_URL}/files/Seznam_prevodnich_mist.pdf`,
	ordersExecution: `${process.env.PUBLIC_URL}/files/Fondee_zavery_analyzy_provadeni_pokynu_2023.pdf`,
	priceList: `${process.env.PUBLIC_URL}/files/Fondee_cenik.pdf`,
};

const email = {
	address: 'info@fondee.cz',
};

const emailUs = {
	title: 'Pište na',
};

const emailVerification = {
	default: {
		title: 'Potvrzení e-mailové adresy',
		text: 'Proč? Abychom si byli jistí, že je email skutečně Váš (a nepsali někomu jinému :-)). Stačí v něm kliknout na tlačítko “ověřit”.',
		listTitle: 'E-mail nedorazil, co s tím?',
		list: '<0>koukněte do spamu</0>'
			+ '<0>nevloudil se překlep?</0>'
			+ '<0>zkuste registraci znovu</0>'
			+ '<0>ani jedno nepomohlo? Napište nám na <0>info@fondee.cz</0> nebo na chatu.</0>',
	},
	response: {
		button: 'Pokračovat v registraci',
		title: {
			error: 'E-mailová adresa nebyla ověřena.',
			success: 'E-mailová adresa byla úspěšně ověřena.',
		},
		content: {
			error: 'Nepodařilo se ověřit e-mailovou adresu. Zkuste aktualizovat stránku a\u00a0v\u00a0případě přetrvávajících problémů nás kontaktujte na <0>$t(email.address)</0>.',
			success: 'Děkujeme za ověření. Nyní můžete pokračovat v\u00a0registraci.',
		},
	},
};

const employer = {
	create: {
		open: 'Přidat zaměstnavatele',
		title: 'Přidat zaměstnavatele',
		text: 'Pro vyhledání zaměstnavatele je zapotřebí zadat platné IČO, které dohledáte např. '
			+ 've vaší smlouvě s\u00a0zaměstnavatelem.',
		disabled: 'Nelze přidat dalšího zaměstnavatele. Kontaktujte, prosíme, zákaznickou podporu na\u00a0e-mailu info@fondee.cz.',
		form: {
			label: 'IČO',
			placeholder: 'Vyplňte platné IČO',
			submit: 'Ověřit',
			found: 'Nalezena společnost dle IČO',
			name: 'Název:',
			address: 'Adresa:',
			confirm: 'Potvrdit správnost údajů',
			aresError: 'IČO se nepovedlo ověřit. Kontaktujte nás, prosím, na\u00a0info@fondee.cz',
			employerExistsError: 'Tohoto zaměstnavatele již máte v\u00a0seznamu',
			invalidBusinessId: 'IČO není platné, zkuste to prosím znovu',
		},
	},
	list: {
		info: 'Pokud vám váš zaměstnavatel/ka přispívá nebo bude přispívat na penzijní portfolio v rámci DIP, '
			+ 'přidejte údaje níže. Pokud jste změnili zaměstnání nebo zaměstnavatel/ka už nebude přispívat, '
			+ 'můžete již zadané údaje odebrat.',
		tooltip: {
			title: 'Platné',
			text: 'Pokud záznam platí, zaměstnavatel bude přispívat na penzijní portfolio. '
				+ 'Pokud už u daného zaměstnavatele nepracujete, zaměstnavatel už nebude přispívat '
				+ 'nebo došlo ke změně údajů, označte záznam jako neplatný.',
		},
	},
};

const error = {
	title: 'Něco se nepovedlo',
	subtitle: 'Omlouváme se, na stránce došlo k chybě.',
	link: 'Zpět na hlavní stránku',
};

const error404 = {
	title: '404',
	subtitle: 'Omlouváme se, ale požadovaná stránka nebyla nalezena.',
	link: 'Zpět na hlavní stránku',
};

const faq = {
	title: 'Jak a kdy probíhá investování',
	search: 'Najít otázku…',
};

const feesChart = {
	comparisonAnchor: 'Porovnání poplatků',
	title: 'Jak jsme na tom v\u00a0porovnání<0></0>s\u00a0podílovým fondem?',
	subtitle: 'V\u00a0porovnání s\u00a0podílovým fondem ušetříte desítky tisíc\u00a0Kč',
	info: '<0>Například při počáteční investici 100\u00a0tisíc\u00a0Kč ušetříte <0>za 15\u00a0let investování přes 50\u00a0tisíc\u00a0Kč</0></0>'
		+ '<0>Počítáme s průměrným ročním poplatkem <0>1,09\u00a0%</0> u\u00a0Fondee a <0>2,7\u00a0%</0> u\u00a0podílového fondu.</0>'
		+ '<0>Předpokládáme průměrný roční výnos akciového portfolia <0>7\u00a0% u\u00a0obou</0>. Po 15\u00a0letech máte ve Fondee '
		+ 'o\u00a051\u00a0700 Kč více než v\u00a0podílovém fondu!</0>'
		+ '<0>Pokud vás zajímá detailnější dopad poplatků na zhodnocení, můžete se podívat <1>sem</1>.</0>',
	chart: {
		currencySuffix: '\u00a0Kč',
		currencyThousandsSuffix: '\u00a0tis.\u00a0Kč',
		years_one: '{{ count }} rok',
		years_few: '{{ count }} roky',
		years_other: '{{ count }} let',
		curveLabel: 'podílové fondy',
	},
};

const feesComparison = {
	title: 'Srovnání poplatků u akciového portfolia',
	footerNote: '\u00b9 Při investování nad 5 let neplatíte výstupní poplatek.',
	footerTextNote1: 'Srovnání za leden\u00a02025',
	footerTextNote2: 'Hodnoty jsme převzali z Klíčových informací pro investory dostupných na webových stránkách '
		+ 'jednotlivých fondů. Vstupní a výstupní poplatky mohou být nižší nebo nulové, pokud je aktuální ceník daného '
		+ 'distributora nižší než poplatky deklarované v Klíčových informacích pro investory.',
	footerTextNotePart2: 'Konkrétně se průměrná roční nákladovost vypočítá následovně: celková roční nákladovost '
		+ 'za poslední rok dle Klíčových informacích pro investory +\u00a0(vstupní +\u00a0výstupní poplatek '
		+ 'relevantní pro dané období) /\u00a0počet let investování.',
	footerTextNote3: 'Výpočet je proveden z hodnoty 100 000 Kč a předpokládá, že klient má tuto hodnotu portfolia po '
		+ 'celou dobu investice. V reálu tato hodnota může jít nahoru nebo dolu, a podle toho se budou poplatky '
		+ 'relativně zvyšovat nebo snižovat.',
	footerTextNotePart3: 'Konkrétně se poplatky podílových fondů vypočítají následovně: 100\u00a0000\u00a0Kč *\u00a0průměrná roční '
		+ 'nákladovost *\u00a0počet let investování.',
	footerTextNote4: 'Taky vám připadá, že je tady absurdně moc hvězdiček? U podílových fondů to tak bývá. Vždy si '
		+ 'zkontrolujte všechny podmínky služby!',
};

const feesHero = {
	title: 'Poplatky? Nižší, než byste čekali…',
	description: '…a než znáte odjinud. O to více peněz pro vás! S Fondee přesně víte, kolik platíte.',
	info: 'Roční poplatky, které si účtují <0>ETFka</0>, se liší podle složení portfolia. V\u00a0průměru jsou 0,15\u00a0%.',
	anchor: 'Porovnání s podílovým fondem',
	period: 'ročně',
	list: [
		{
			title: 'Poplatky Fondee',
			value: '0,9\u00a0%',
		},
		{
			title: 'Poplatky za <0>ETF</0> v\u00a0průměru',
			value: '0,15\u00a0%',
		},
		{
			title: 'Celkové průměrné poplatky',
			value: '1,05\u00a0%',
		},
	],
	benefitsList: [
		'Žádné vstupní poplatky',
		'Žádné skryté poplatky',
		'Žádné poplatky za bankéře',
		'Žádné provize poradcům',
	],
};

const feesProducts = {
	title: 'Kolik stojí investování přes Fondee?',
	toggleOptions: [
		{
			label: 'Klasické',
			value: 'classic',
		},
		{
			label: 'ESG',
			value: 'esg',
		},
		{
			label: 'Dětské',
			value: 'child',
		},
		{
			label: 'Penzijní',
			value: 'pension',
		},
		{
			label: 'Studentské',
			value: 'student',
		},
	],
	products: {
		child: {
			title: 'Dětské účty',
			text: 'Jak na bohatší budoucnost pro děti? Nenechte úspory ležet v\u00a0bance, kde ztrácejí hodnotu. Založte '
				+ 'dětský investiční účet <0>se sníženým poplatkem.</0>',
			button: 'Chci dětský účet',
			transparent: {
				fondee: '0,2\u00a0%',
				vat: '0,04\u00a0%',
				total: '0,24\u00a0%',
				description: '<0>Poplatek Fondee <0>0,2\u00a0%</0> ročně</0>'
					+ '<1>Náklady na investování pro děti jsme stáhli na minimum. Poplatek zahrnuje kompletní správu dětského portfolia.</1>'
					+ '<0>DPH 0,04\u00a0% ročně</0>'
					+ '<1>Podle zákona naše činnost spadá pod finanční služby platící DPH.</1>'
					+ '<1>Poplatek je zaokrouhlený, přesnou sazbu najdete v\u00a0<0>ceníku</0>.</1>',
			},
		},
		classic: {
			title: 'Na míru',
			text: 'Investujte <0>do stovek akcií a\u00a0dluhopisů z\u00a0celého světa</0> skrze ETF\u00a0–\u00a0fondy obchodované na burze. '
				+ 'Jednoduše, automatizovaně a\u00a0srozumitelně.',
			button: 'Chci klasické portfolio',
			transparent: {
				fondee: '0,9\u00a0%',
				vat: '0,19\u00a0%',
				total: '1,09\u00a0%',
				description: '<0>Poplatek Fondee <0>0,9\u00a0%</0> ročně</0>'
					+ '<1>S Fondee vás nic nepřekvapí. Účtujeme pouze jeden poplatek, který zahrnuje kompletní správu vašich investic. '
					+ 'Investujte levněji než přes podílové fondy!</1>'
					+ '<0>DPH 0,19\u00a0% ročně</0>'
					+ '<1>Podle zákona naše činnost spadá pod finanční služby platící DPH.</1>'
					+ '<1>Poplatek je zaokrouhlený, přesnou sazbu najdete v\u00a0<0>ceníku</0>.</1>',
			},
		},
		esg: {
			title: 'ESG',
			text: 'Investujte udržitelně s\u00a0ESG portfolii, která v\u00a0posledních letech porážejí ve výnosnosti klasické investice. '
				+ 'Uvidíte, že <0>hodnoty a\u00a0výnosy si rozumí</0>.',
			button: 'Chci ESG portfolio',
			transparent: {
				fondee: '0,9\u00a0%',
				vat: '0,19\u00a0%',
				total: '1,09\u00a0%',
				description: '<0>Poplatek Fondee <0>0,9\u00a0%</0> ročně</0>'
					+ '<1>S Fondee vás nic nepřekvapí. Účtujeme pouze jeden poplatek, který zahrnuje kompletní správu vašich investic. '
					+ 'Investujte levněji než přes podílové fondy!</1>'
					+ '<0>DPH 0,19\u00a0% ročně</0>'
					+ '<1>Podle zákona naše činnost spadá pod finanční služby platící DPH.</1>'
					+ '<1>Poplatek je zaokrouhlený, přesnou sazbu najdete v\u00a0<0>ceníku</0>.</1>',
			},
		},
		pension: {
			title: 'Penzijní účty',
			text: 'Vezměte budoucnost do vlastních rukou a\u00a0řekněte sbohem drahým penzijním spořením s\u00a0mizernými výnosy. Otevřete si penzijní portfolio <0>se zvýhodněným poplatkem</0>.',
			button: 'Chci penzijní portfolio',
			transparent: {
				fondee: '0,5\u00a0%',
				vat: '0,11\u00a0%',
				total: '0,61\u00a0%',
				description: '<0>Poplatek Fondee <0>0,5\u00a0%</0> ročně</0>'
					+ '<1>Investice na důchod levnější než v\u00a0bance? Máte je mít! Zvýhodněný poplatek zahrnuje kompletní správu penzijního portfolia.</1>'
					+ '<0>DPH 0,11\u00a0% ročně</0>'
					+ '<1>Podle zákona naše činnost spadá pod finanční služby platící DPH.</1>'
					+ '<1>Poplatek je zaokrouhlený, přesnou sazbu najdete v\u00a0<0>ceníku</0>.</1>',
			},
		},
		student: {
			title: 'Studentské účty',
			text: 'Využijte čas ve svůj prospěch! Investujte už při škole přes Fondee účet pro studenty '
				+ '<0>se sníženým poplatkem</0>. Stačí potvrzení o\u00a0studiu nebo ISIC a\u00a0je váš až do 26\u00a0let.',
			button: 'Chci studentský účet',
			transparent: {
				fondee: '0,5\u00a0%',
				vat: '0,11\u00a0%',
				total: '0,61\u00a0%',
				description: '<0>Poplatek Fondee <0>0,5\u00a0%</0> ročně</0>'
					+ '<1>Jedno potvrzení o\u00a0studiu nebo kartička ISIC a\u00a0až do 26.\u00a0narozenin investujete '
					+ 's\u00a0poplatkem o\u00a0půl procenta nižším než v\u00a0„dospěláckých“ portfoliích.</1>'
					+ '<0>DPH 0,11\u00a0% ročně</0>'
					+ '<1>Podle zákona naše činnost spadá pod finanční služby platící DPH.</1>'
					+ '<1>Poplatek je zaokrouhlený, přesnou sazbu najdete v\u00a0<0>ceníku</0>.</1>',
			},
		},
	},
	transparent: {
		title: 'Poplatky transparentně:',
		fondee: 'Poplatek Fondee',
		vat: 'DPH',
		total: 'Celkový poplatek',
	},
};

const findOutInfoBox = {
	text: 'Zjistěte, který investiční profil odpovídá Vašemu přístupu k\u00a0riziku. '
		+ 'Vyhodnocení je v\u00a0investičním dotazníku hned v\u00a0prvním kroku registrace.',
	button: 'Chci zkusit',
};

const footer = {
	primary: {
		nav: {
			title: 'Informace',
			homepage: 'Úvod',
			howItWorks: 'Jak to funguje',
			ourApproach: 'Náš přístup',
			fees: 'Poplatky',
			faq: 'Časté dotazy',
			contact: 'Zeptejte se',
			documents: 'Dokumenty',
			cookies: 'Cookies',
			blog: 'Blog',
			privacyPolicy: 'Ochrana osobních údajů',
		},
		products: {
			title: 'Produkty',
			classic: 'Klasická portfolia',
			esg: 'ESG portfolia',
			children: 'Dětské účty',
			pension: 'Penzijní účty',
			student: 'Studentské účty',
		},
		contact: {
			title: 'Chcete se zeptat?',
		},
		app: 'Stáhnout mobilní aplikaci',
		googlePlay: 'Nyní na\u00a0Google Play',
		appStore: 'Stáhnout v\u00a0App Store',
		socialUrl: {
			facebook: 'https://www.facebook.com/FondeeCZ',
			instagram: 'https://www.instagram.com/fondee_cz/',
			linkedin: 'https://www.linkedin.com/company/fondeecz/',
			twitter: 'https://twitter.com/fondee_cz',
		},
	},
	secondary: {
		title: 'Fondee\u00a0a.s.',
		info: 'Hodnota investice může v průběhu času kolísat, návratnost investované částky není zaručena. '
			+ 'S investicemi jsou spojena rizika. U investic s Fondee se investor vystavuje především tržním rizikům '
			+ 'spočívajícím v možnosti nepříznivého vývoje cen aktiv (akcií, dluhopisů, atd.), úvěrovým rizikům, riziku '
			+ 'inflace a rizikům nepříznivého vývoje směnných kurzů.',
		investing: 'Historická výnosnost negarantuje výnosnost budoucí. Tuto stránku pro vás vytvořilo Fondee a\u00a0jedná '
			+ 'se o\u00a0propagační sdělení.',
		address: 'IČ\u00a006691862<0></0>'
			+ 'Rumunská\u00a022/28,<0></0>'
			+ 'Vinohrady\u00a0-\u00a0Praha\u00a02, 120\u00a000',
		legal: 'Fondee\u00a0a.s. je zapsaná v\u00a0Obchodním rejstříku vedeném u\u00a0Městského soudu '
			+ 'v\u00a0Praze, spisová značka B\u00a027322',
		love: 'Made with\u00a0<0></0>\u00a0Fondee',
	},
};

const footerCta = {
	title: 'Budoucnost? Udělejte si vlastní!',
	text: 'Nechte své peníze pracovat pro vás.',
	button: 'Chci investovat',
};

const forgotPassword = {
	title: 'Obnovit zapomenuté heslo',
	form: {
		text: 'Po zadání e-mailové adresy Vám zašleme odkaz pro obnovu hesla.',
		button: 'Zaslat odkaz',
		success: 'Na Vaši emailovou adresu byl právě odeslán odkaz pro reset starého hesla.',
	},
	reset: 'Změnit heslo',
};

const forms = {
	error: 'Něco se nepovedlo. Zkuste to prosím znovu.',
	success: 'Uloženo',
	save: 'Uložit',
	fields: {
		email: {
			label: 'E-mail',
			helper: 'Slibujeme, že nebudeme posílat nesmyslný spam',
			error: 'Zapomněli jste vyplnit svou emailovou adresu.',
			invalid: 'Zadaná emailová adresa je neplatná. Zkontrolujte si, prosím, zda jste v emailu neudělali překlep, nechybí znak @ apod.',
			unique: 'Tento email je již v naší databázi zaregistrován. Prosíme, použijte jinou emailovou adresu.',
			closed: 'Váš účet byl zrušen. Pokud chcete účet obnovit, obraťte se, prosíme, na <0>$t(email.address)</0>.',
		},
		esgPreferenceDeclaration: {
			error: 'Prosím zaškrtněte toto políčko',
		},
		password: {
			label: 'Heslo',
			helper: 'Minimálně 8 znaků, jedno velké a malé písmeno a číslo',
			error: 'Zapomněli jste vyplnit své heslo.',
			incorrect: 'Bohužel, tohle heslo není správné.',
			invalid: 'Zadané heslo nesplňuje podmínky.',
		},
		passwordConfirm: {
			label: 'Potvrzení hesla',
			error: 'Zapomněli jste vyplnit potvrzení hesla.',
			invalid: 'Zadaná hesla se neshodují.',
		},
		oldPassword: {
			label: 'Původní heslo',
			error: 'Zapomněli jste vyplnit původní heslo.',
		},
		newPassword: {
			label: 'Nové heslo',
			error: 'Zapomněli jste vyplnit nové heslo.',
		},
		firstName: {
			label: 'Jméno',
			error: 'Prosím, zadejte Vaše křestní jméno.',
			warning: 'Ujistěte se, že zadané jméno je správné. Pokud ano, pokračujte dál.',
		},
		lastName: {
			label: 'Příjmení',
			error: 'Prosím, zadejte Vaše příjmení.',
			warning: 'Ujistěte se, že zadané přijmení je správné. Pokud ano, pokračujte dál.',
		},
		fullName: {
			label: 'Jméno',
			error: 'Prosím, zadejte Vaše jméno.',
		},
		phone: {
			label: 'Telefon',
			error: 'Prosím, nezapomeňte vyplnit Váš telefon.',
			invalid: 'Zadané telefonní číslo je ve špatném formátu.',
		},
		phoneNumber: {
			label: 'Telefon',
			helper: 'Zadejte bez mezer',
			error: 'Zapomněli jste vyplnit telefonní číslo.',
			invalid: 'Zadané telefonní číslo je neplatné. Zkontrolujte si, prosím, zda jste ho nepsali správně a bez mezer.',
			warning: 'Ujistěte se prosím, že zadané číslo je správné.',
		},
		hasReferenceCode: {
			label: 'Mám referenční kód',
		},
		referenceCode: {
			label: 'Referenční kód',
			error: 'Zapomněli jste vyplnit Váš referenční kód.',
			invalid: 'Tento referenční kód jsme v naší databázi nenalezli. Prosíme zkontrolujte si, že jste napsali referenční kód správně.',
		},
		personalInfoAgreed: {
			label: 'Souhlasím se zpracováním <0>osobních údajů</0>',
			error: 'Pro pokračování je nutné zaškrtnout, že souhlasíte se zpracováním osobních údajů.',
		},
		conditionsAgreed: {
			label: 'Souhlasím s <0>obchodními podmínkami</0> Fondee',
			error: 'Pro pokračování je nutné zaškrtnout, že jste byli seznámeni s podmínkami podnikání Fondee.',
		},
		streetName: {
			label: 'Ulice',
			error: 'Zapomněli jste vyplnit ulici.',
			warning: 'Zkontrolujte si prosím, že zadaná ulice je správně.',
		},
		streetNumber: {
			label: 'Č.\u00a0p./č.\u00a0o.',
			error: 'Zapomněli jste vyplnit číslo popisné.',
			warning: 'Zkontrolujte si prosím, že zadané číslo popisné je správně.',
		},
		city: {
			label: 'Město',
			error: 'Zapomněli jste vyplnit město.',
		},
		postalCode: {
			label: 'PSČ',
			nonNumeric: 'Nesprávný formát. Uveďte pouze číslice.',
		},
		country: {
			label: 'Země',
			error: 'Zapomněli jste vyplnit zemi.',
		},
		personalIdentificationNumber: {
			label: 'Rodné číslo',
			error: 'Nesprávný formát. Uveďte pouze číslice a bez lomítka.',
			warning: 'Zkontrolujte si prosím, že zadané rodné číslo je správně.',
		},
		birthDate: {
			label: 'Datum narození',
			nonNumeric: 'Nesprávný formát. Uveďte pouze číslice.',
			minority: 'Dítěti už bylo 18, je potřeba vytvořit samostatnou registraci',
			majority: 'Ještě vám nebylo 18, registraci za Vás musí dokončit zákonný zástupce',
			day: {
				label: 'Den',
				invalid: 'Uveďte správné číslo dne.',
			},
			month: {
				label: 'Měsíc',
				invalid: 'Uveďte správné číslo měsíce.',
			},
			year: {
				label: 'Rok',
				invalid: 'Uveďte správné číslo roku.',
			},
		},
		placeOfBirth: {
			label: 'Místo narození',
			error: 'Nesprávný formát. Uveďte pouze písmena nebo číslice.',
			warning: 'Zkontrolujte si prosím, že zadané místo narození je správně.',
		},
		bankAccount: {
			label: 'Bankovní účet',
			labelPrefix: 'Předčíslí',
			labelAccount: 'Číslo účtu',
			labelCode: 'Kód banky',
			errorAccount: 'Zapomněli jste vyplnit číslo účtu.',
			errorCode: 'Zapomněli jste vyplnit kód banky.',
			invalidCode: 'Neplatný kód banky.',
			length: 'Zkontrolujte si prosím, že číslo účtu má 26\u00a0znaků.',
			nonNumeric: 'Nesprávný formát. Uveďte pouze číslice.',
		},
		bankAccountIBAN: {
			label: 'IBAN',
			error: 'Zapomněli jste vyplnit číslo IBAN účtu.',
			invalid: 'Číslo IBAN účtu smí obsahovat pouze číslice a\u00a0písmena. Nemělo by překročit délku 34\u00a0znaků.',
			warning: 'Zkontrolujte si prosím, že číslo IBAN účtu má 24\u00a0znaků.',
		},
		statementPeriodicity: {
			monthly: 'Měsíčně',
			quarterly: 'Čtvrtletně',
			yearly: 'Ročně',
			none: 'Nikdy',
		},
		survey: {
			label: 'Zobrazovat dotazníky v klientské zóně',
		},
		marketingEmailsUnsubscribe: {
			label: 'Odhlásit se z odběru marketingových mailů',
		},
		nationality: {
			label: 'Státní občanství',
			error: 'Zapomněli jste vyplnit státní občanství.',
		},
		rebalancingActive: {
			label: 'Zapnout možnost automatického rebalancování portfolia',
		},
		toggle: {
			local: 'Lokální č. účtu',
			iban: 'IBAN',
		},
		address: {
			change: 'Změna trvalé adresy',
		},
		contactAddress: {
			label: 'Kontaktní adresa',
		},
		addressChange: {
			change: 'Přeji si změnit adresu trvalého pobytu',
			confirm: 'Prohlašuji, že zadaná adresa je správná.',
		},
		businessId: {
			warning: 'Zadané IČO není platné.',
		},
		incomeExpense: {
			warning: 'Zkontrolujte si, prosím, že zadaná částka je správně. Částka musí být uvedena celým číslem v\u00a0Kč (nikoli např. v\u00a0tisících).',
		},
		numberISIC: {
			format: 'Nesprávný formát.',
		},
		child: {
			firstName: {
				label: 'Jméno dítěte',
				error: 'Prosím, vyplňte jméno dítěte.',
				warning: 'Zkontrolujte si prosím, že zadané jméno dítěte je správně.',
			},
			lastName: {
				label: 'Příjmení dítěte',
				error: 'Prosím, vyplňte příjmení dítěte.',
				warning: 'Zkontrolujte si prosím, že zadané příjmení dítěte je správně.',
			},
			email: {
				label: 'E-mail (nepovinné)',
				unique: 'Zadaný e-mail již v naší databázi evidujeme u jiného uživatele. Pokud dítě vlastní e-mail nemá, můžete pole nechat prázdné.',
				warning: 'Zkontrolujte si prosím, že zadaný e-mail je správně.',
			},
			phone: {
				label: 'Telefon (nepovinné)',
				warning: 'Zkontrolujte si prosím, že zadané telefonní číslo je správně.',
			},
			referenceCode: {
				label: 'Referenční kód (nepovinné)',
				invalid: 'Tento referenční kód jsme v naší databázi nenalezli. Prosíme zkontrolujte si, že jste napsali referenční kód správně.',
				tooltip: 'Upozorňujeme, že lze zadat pouze kódy na jednorázový peněžní bonus. Bonusy pro odpuštění poplatků se řídí vždy podle účtu rodiče a není možné je zde zadávat.',
			},
		},
		file: {
			deleteAttachment: 'Smazat přílohu',
			sizeError: 'Maximální velikost souboru je {{ value }} MB.',
		},
	},
	twoFactorAuthentication: {
		title: 'Dvoufaktorové ověření',
		text: 'Zadejte ověřovací kód z Vaší aplikace',
		button: 'Zadat kód',
		error: 'Špatný kód',
	},
};

const general = {
	copied: 'Zkopírováno',
	esgWarningModal: {
		title: 'Upozornění',
		text: 'Nabízená ESG portfolia nemusí odpovídat Vaším preferencím.',
		button: 'Beru na vědomí',
	},
};

const header = {
	blog: 'Blog',
	clientCheck: 'Pravidelné kontroly',
	contact: 'Zeptejte se',
	dashboard: 'Přehled',
	fees: 'Poplatky',
	history: 'Historie',
	howItWorks: 'Jak to funguje',
	login: 'Přihlásit se',
	logout: 'Odhlásit se',
	menu: 'Menu',
	myAccount: 'Můj účet',
	offers: 'Něco navíc',
	onboarding: 'Dokončení registrace',
	ourApproach: 'O nás',
	ownPortfolio: 'Portfolia',
	portfolio: 'Portfolia',
	products: 'Produkty',
	productsList: {
		classicProduct: {
			title: 'Investice na míru',
			subTitle: 'Vhodná pro každého investora',
		},
		esgProduct: {
			title: 'Udržitelné investice',
			subTitle: 'Příroda je na prvním místě',
		},
		childProduct: {
			title: 'Dětské investiční účty',
			subTitle: 'Myslím na budoucnost svých dětí',
		},
		pensionProduct: {
			title: 'Investice na důchod (DIP)',
			subTitle: 'Zařiďte si stáří po svém',
		},
		studentProduct: {
			title: 'Investice pro studenty',
			subTitle: 'Začněte už při škole',
		},
		button: 'Zpět',
	},
	referrals: 'Doporučení',
	register: 'Chci investovat',
	signAgreement: 'Podpis smlouvy',
	signNewAgreement: 'Podpis smlouvy',
	taxes: 'Daně 2024',
};

const historyChart = {
	loading: 'Stahuji data pro tuto stránku',
	legend: {
		title: 'Legenda',
		evaluation: 'Kladné zhodnocení',
		devaluation: 'Záporné zhodnocení',
		value: 'Celková hodnota portfolia',
		increase: 'Vklad / Bonus / Dividenda',
		decrease: 'Výběr / Poplatek',
	},
	tooltip: {
		value: 'Hodnota',
		evaluation: 'Pohyb hodnoty portfolia',
		nonGrowthPlus: 'Vklady/bonusy',
		nonGrowthMinus: 'Výběry/přesuny mezi portfolii',
		growthPlus: 'Dividendy/vratky',
		growthMinus: 'Poplatky',
	},
	toggle: {
		value: 'Hodnota',
		evaluation: 'Zhodnocení',
	},
};

const historyFilter = {
	aggregation: 'Po',
	fromDate: 'Od',
	toDate: 'Do',
	last: 'Posledních ',
	days: 'dnů',
	beginning: 'Počátek',
	collapse: 'Změnit rozsah dat',
};

const historyOverview = {
	deposits: {
		title: 'Vklady',
		tooltip: 'Součet všech vkladů a\u00a0případných odměn za vybrané\u00a0období.',
	},
	withdrawals: {
		title: 'Výběry',
		tooltip: 'Součet všech výběrů za vybrané\u00a0období.',
	},
	appreciation: {
		title: 'Zhodnocení',
		tooltip: 'Vývoj hodnoty portfolia ve vybraném období očištěný o\u00a0vklady, výběry a\u00a0odměny.',
	},
};

const historyProgress = {
	title: 'Vývoj hodnoty portfolia',
	downloadPdf: 'Stáhnout v\u00a0PDF',
	noResult: 'Nic k\u00a0zobrazení',
	table: {
		columns: {
			date: 'Datum',
			externalTransactionTotal: 'Vklad/výběr/odměna',
			internalTransactionTotal: 'Bonus/poplatek/dividenda',
			value: 'Hodnota portfolia',
			valueTooltip: 'Hodnota portfolia představuje souhrnnou hodnotu držených produktů a\u00a0hotovosti '
				+ 'přepočítané na zvolenou měnu podle kurzu dostupného v\u00a0danou chvíli. Hodnota ETF se vypočítává '
				+ 'podle cen ETF platných pro konec daného obchodního dne, případně nejbližší dostupné ceny.',
			valueProgress: 'Pohyb hodnoty portfolia od předchozího dne',
			valueProgressTooltip: 'Pohyb hodnoty portfolia vyjadřuje změny hodnoty nakoupených produktů '
				+ 'a\u00a0změny kurzů měn. Produkty jsou obchodované v\u00a0eurech, na jejich aktuální hodnotu '
				+ 'tedy mohou mít vliv pohyby kurzu. Pohyb hodnoty portfolia se vypočítává jako rozdíl hodnoty '
				+ 'portfolia od předchozího dne bez započtení vkladů, výběrů, bonusů a\u00a0poplatků.',
		},
		rows: {
			employerDeposit: 'Vklad od zaměstnavatele',
			for: 'za',
			rate: 'Kurz:',
			transfer: 'Přesun',
			fromPortfolio: 'z portfolia',
			toPortfolio: 'do portfolia',
			investorProfile: 'Investiční profil:',
			skipRebalancing: 'Vypnutý rebalancing',
			cashOver: 'Poměr hotovosti upraven na',
		},
		loadMore: 'Zobrazit další pohyby',
	},
};

const historyTransactions = {
	title: 'Přehled transakcí',
	downloadPdf: 'Stáhnout v\u00a0PDF',
	text: 'Podrobnosti o\u00a0způsobu zobrazení transakcí',
	textTooltip: 'Datum provedení transakce odpovídá datu zaknihování u\u00a0brokera.',
	noResult: 'Žádné transakce k\u00a0zobrazení',
	filter: {
		placeHolder: 'Filtr transakcí',
		noOption: 'Žádné filtry',
	},
	table: {
		columns: {
			date: 'Datum',
			type: 'Typ změny',
			change: 'Změna',
		},
		rows: {
			more: 'Více',
			hide: 'Skrýt',
			employerDeposit: 'Vklad od zaměstnavatele',
			trading: 'Obchodování',
			fromEmployer: 'Od',
			for: 'za',
			rate: 'Kurz:',
			fromPortfolio: 'z portfolia',
			toPortfolio: 'do portfolia',
		},
		loadMore: 'Zobrazit další pohyby',
	},
};

const homepageAbout = {
	title: 'Co je Fondee?',
	text: 'Chcete zhodnocovat své volné peníze v\u00a0akciích a\u00a0dluhopisech? '
		+ 'Můžete si vybrat ze 7\u00a0modelových portfolií, která sdružují akcie a\u00a0dluhopisy různého zaměření. '
		+ 'Pak stačí jen poslat vklad, který si přejete  zainvestovat, a\u00a0my se postaráme o\u00a0všechny příkazy '
		+ 'a\u00a0udržování portfolia podle vámi zvoleného modelového\u00a0portfolia.',
	button: 'Chci se dozvědět více',
};

const homepageHero = {
	title: 'Na budoucnost nenaspoříte.',
	subtitle: 'Investujte!',
	text: 'Vyplňte investiční dotazník a\u00a0Fondee vám navrhne portfolio na míru. Investujte automatizovaně '
		+ '<0>už od 1\u00a0000 Kč</0>. S\u00a0nízkými poplatky a\u00a0kompletně online.',
	aboutButton: 'Zjistit více',
	productsButton: 'Naše produkty',
	future: {
		title: 'Budoucnost nezná nikdo.',
		subtitle: 'Zajistit se může každý.',
		text: 'Zhodnocujte své peníze dlouhodobě. Jednoduše, transparentně a\u00a0s\u00a0nízkými poplatky.',
	},
};

const homepageJT = {
	text: '<0>Stojí za námi J&T Banka. Finančně podpořila Fondee a\u00a0předala nám cenné zkušeností z\u00a0oboru. '
		+ 'Díky za důvěru!</0>'
		+ '<0>Se silnou finanční skupinou v\u00a0zádech můžeme investiční platformu stále zlepšovat a\u00a0pracovat na '
		+ 'nových funkcích pro naše investory.</0>',
};

const homepageProducts = {
	title: 'Jak zhodnotíte své peníze?',
	button: 'Více informací',
	list: [
		{
			title: 'Investice na míru',
			text: '<0>Na budoucnost nestačí spořit. Zato stačí jeden bankovní příkaz na\u00a0Fondee a\u00a0investujete <1>do stovek '
				+ 'akcií a\u00a0dluhopisů z\u00a0celého světa</1> – jednorázově nebo pravidelně a\u00a0už od 1\u00a0000 Kč.</0>'
				+ '<0>Žádné zbytečné poplatky ani složité podmínky. Podle vašich cílů a\u00a0postoje k\u00a0riziku vám navrhneme '
				+ 'portoflio složené z\u00a0ETF. Vklady <1>automaticky zainvestujeme</1>, a\u00a0vy se tak nemusíte o\u00a0nic starat.</0>',
			button: 'Chci klasické portfolio',
		},
		{
			title: 'Udržitelné investice',
			text: '<0>Investujte odpovědně a založte si udržitelné portfolio poskládané z ETF, <1>která vynikají v ESG '
				+ 'ratingu</1>. Ten hodnotí vliv investice na životní prostředí (E), společnost (S) a kvalitu řízení (G).</0>'
				+ '<0>Vyberte si ze <1>7\u00a0ESG portfolií</1>, jejichž zhodnocení v\u00a0posledních letech poráží klasické investice. '
				+ 'Uvidíte, že <1>hodnoty a\u00a0výnosy si rozumí</1>.</0>',
			button: 'Chci ESG portfolio',
		},
		{
			title: 'Investice pro děti',
			text: '<0>Jak finančně zajistit ty nejmenší? Dobře, že se ptáte, <1>v\u00a0budoucnu vám poděkují</1>! Pár stovek do investic '
				+ 'pro děti teď = pěkný balík v\u00a0dospělosti.</0>'
				+ '<0>Sjednejte svým dětem investiční účet <1>se sníženým poplatkem</1>.</0>',
			button: 'Chci dětský účet',
		},
		{
			title: 'Penzijní investiční účet (DIP)',
			text: '<0>Vezměte budoucnost <1>do vlastních rukou</1>! Nespoléhejte na stát a\u00a0řekněte sbohem '
				+ 'drahým penzijním připojištěním s\u00a0mizernými výnosy.</0>'
				+ '<0>Založte si penzijní portfolio a\u00a0odkládejte na důchod snadno, s\u00a0možností <1>příspěvků '
				+ 'od zaměstnavatele, daňovým zvýhodněním</1> a\u00a0sníženým poplatkem oproti našim běžným portfoliím.</0>',
			button: 'Chci penzijní portfolio',
		},
		{
			title: 'Investice pro studenty',
			text: '<0>Možnost příspěvků od blízkých, <1>sleva na\u00a0poplatek</1>, žádný minimální vklad a\u00a0100\u00a0Kč '
				+ 'bonus za ISIC –\u00a0to je studentský účet od Fondee. Jednou doložíte, že chodíte do školy, a\u00a0jeho výhody '
				+ 'jsou vaše <1>až do 26\u00a0let</1>.</0>'
				+ '<0>Na investování není nikdy moc brzy a\u00a0každá koruna se počítá. Začněte už při škole '
				+ 'a\u00a0<1>zhodnoťte své peníze v\u00a0ETF portfoliích</1>.</0>',
			button: 'Chci studentský účet',
		},
	],
};

const homepageServices = {
	list: [
		{
			title: 'Jednoduše',
			text: 'Účet si založíte <0>online na\u00a0pár kliknutí</0>, takže už za několik minut začnete zhodnocovat své peníze. '
				+ 'Na\u00a0webu nebo v\u00a0appce sledujete, jak se investici daří.',
		},
		{
			title: 'Chytře',
			text: 'Krátký dotazník vám napoví, jací jste investoři -\u00a0u\u00a0nás si přijdou na své <0>odvážní i\u00a0rozvážní</0>. '
				+ 'Máte více finančních cílů? Otevřete si až 5\u00a0portfolií zároveň!',
		},
		{
			title: 'Bezpečně',
			text: 'Zatímco se staráme o\u00a0vaše investice, dohlíží na\u00a0nás <0>Česká národní banka</0> a\u00a0vaše peníze chrání '
				+ 'Garanční fond obchodníků s\u00a0cennými papíry.',
		},
		{
			title: 'Bez hvězdiček*',
			text: 'Investujte <0>tolik, kolik chcete</0>. Kdykoli se s\u00a0námi můžete rozloučit -\u00a0zařídíte to online '
				+ 'a\u00a0<0>do\u00a0týdne</0> máte peníze na\u00a0účtu. Hvězdičky ani výjimky nevedeme. *Opravdu!',
		},
	],
	button: 'Chci vědět, jak to funguje',
};

const howItWorksHero = {
	title: 'Jak to funguje',
	description: 'Vše, co o investování s námi potřebujete vědět',
	list: [
		{
			name: 'Vyplňte dotazník',
			description: 'Poznávačka na úvod. My jsme Fondee, a\u00a0vy? Díky <0>krátkému dotazníku</0> poznáme vaše investiční cíle '
				+ 'a\u00a0postoj k\u00a0riziku.',
		},
		{
			name: 'Zvolte portoflio',
			description: 'Bez obav, nebudete na to sami. Navrhneme vám <0>vhodný typ strategie</0> podle odpovědí v\u00a0úvodním dotazíku.',
		},
		{
			name: 'Pošlete vklad',
			description: 'Pro dokončení registrace zbývá odeslat první platbu. Investujte už <0>od 1\u00a0000\u00a0Kč</0> '
				+ 'jednorázově nebo pravidelně.',
		},
		{
			name: 'Investujte',
			description: 'Fondee za vaše vklady <0>nakupuje <0>ETFka</0></0> a\u00a0hlídá, aby portfolio stále odpovídalo rozložení, '
				+ 'které jste si zvolili.',
		},
		{
			name: 'Vyberte své peníze',
			description: 'Investici můžete kdykoli prodat a\u00a0<0>do\u00a0týdne máte peníze na\u00a0účtu</0>. Výstupní poplatky? To neznáme.',
		},
	],
	anchor: 'Podívat se na portfolia',
};

const howItWorksInvestment = {
	title: 'Investování s\u00a0klidem v\u00a0duši',
	list: [
		{
			title: 'Bezpečně',
			description: 'Investice jsou uloženy u\u00a0brokera J&T banky na tzv. <0>zákaznickém účtu</0>, který není součástí '
				+ 'insolvenční podstaty. V\u00a0případě úpadku se k\u00a0nim <0>snadno dostanete</0>, takže jsou v\u00a0bezpečí, '
				+ 'i\u00a0kdybychom my nebo broker zkrachovali.',
		},
		{
			title: 'Kontrolovaně',
			description: 'Fondee je regulováno <0>Českou národní bankou</0>. Vaše investice navíc ze zákona spadají pod Garanční fond '
				+ 'obchodníků s\u00a0cennými papíry, který je chrání až <0>do\u00a090\u00a0% hodnoty,</0> (maximálně do výše 20\u00a0000\u00a0eur).',
		},
		{
			title: 'Profesionálně',
			description: 'O\u00a0portfolia se stará <1>tým expertů</1> s\u00a0dlouholetými zkušenostmi, které nasbírali studiem '
				+ 'ekonomických univerzit i\u00a0praxí ve\u00a0finančních institucích po celém světě. Jste zkrátka <0>v\u00a0dobrých rukou</0>.',
		},
	],
};

const howItWorksPrinciples = {
	title: '4 zásady chytrého investora',
	list: [
		{
			title: 'Investice jsou jako horská dráha',
			text: 'Jednou nahoře, jednou dole. Investování přináší rizika a\u00a0není možné zaručit návratnost. Fondee se '
				+ 'nehodí pro krátkodobé spekulace. Investujeme s\u00a0cílem zhodnocení a\u00a0věříme, že <0>hodnota investice '
				+ 'dlouhodobě poroste</0>. Může ale i\u00a0klesnout kvůli propadu na trzích akcií a\u00a0dluhopisů. Investujte pouze, '
				+ 'pokud jste <0>ochotni nést riziko ztráty</0>.',
		},
		{
			title: 'Co bylo včera není dnes',
			text: 'A\u00a0historické výnosy nejsou zárukou výnosů budoucích. Naše investiční portfolia v\u00a0minulosti '
				+ 'dosáhla určitého zhodnocení, ale <0>není možné garantovat, že se jim v\u00a0budoucnu povede stejně</0>. Výnos může být '
				+ 'vyšší i\u00a0nižší. Věšteckou kouli nemáme my ani ostatní –\u00a0a\u00a0když vám někdo slibuje jistý zisk, věší '
				+ 'vám bulíky na nos.',
		},
		{
			title: 'Čím nižší poplatky, tím více peněz pro vás',
			text: 'Nezapomeňte si vždy zkontrolovat poplatky, <0>ujídají totiž hodnotu investice</0>. I\u00a0malý rozdíl '
				+ 'v\u00a0dlouhodobém horizontu znamená hodně. Fondee funguje automatizovaně online bez poboček a\u00a0poradců nebo '
				+ 'bankéřů. Šetříme náklady, a\u00a0díky tomu vám nabízíme <0>jedny z\u00a0nejnižších poplatků na trhu</0>.',
		},
		{
			title: 'Kurz zajímá cestovatele i\u00a0investory',
			text: 'Na zhodnocení vaší investice má vliv taky <0>vývoj měn</0>. Většina ETFek z\u00a0našich portfolií se obchoduje '
				+ 'v\u00a0eurech, některá navíc obsahují i\u00a0dolarové položky. Měnové kurzy <0>se neustále mění a\u00a0s\u00a0nimi '
				+ 'také hodnota vašeho portfolia</0> –\u00a0proto investice ve Fondee může vlivem měn jak růst, tak poklesnout.',
		},
	],
};

const howItWorksRebalancing = {
	title: 'Co je rebalancování portfolia?',
	description: '<0>Každé Fondee portfolio má své procentuální zastoupení akcií a\u00a0dluhopisů. Ceny akcií '
		+ 'a\u00a0dluhopisů se ale každý den mění, a\u00a0proto se časem může jejich poměr <0>lišit od toho, který jste si na '
		+ 'začátku vybrali</0>. Už vám například nemusí vyhovovat, že investice obsahuje více akcií, než chcete.</0>'
		+ '<0>Rebalancování zajišťuje, že se portfolio každý měsíc <0>automaticky upraví, aby jeho rozložení stále odpovídalo '
		+ 'zvolenému poměru</0>. Službu rebalancingu si můžete vypnout – pak se za nové vklady nakupují ETF v\u00a0takovém poměru, '
		+ 'aby cílové rozdělení portfolia dorovnaly.</0>',
	chart: {
		portfolioValueLabel: 'Hodnota portfolia',
		portfolioToday: 'Dnes',
		portfolioAfterMonth: 'Po měsíci',
		portfolioAfterRebalancing: 'Po Rebalancování',
		portfolioValueToday: '100 000,-',
		portfolioValueAfterMonth: '110 000,-',
		portfolioValueAfterRebalancing: '110 000,-',
		bond: 'Dluhopisy',
		share: 'Akcie',
		etf: 'ETF',
		why: 'Proč?',
		how: 'Jak?',
		tooltipWhy: 'Ceny akcií a\u00a0dluhopisů se mění každý den. V\u00a0tomto případě ceny akciového ETF vzrostly o\u00a020\u00a0% a\u00a0ceny dluhopisového ETF se nezměnily.',
		tooltipHow: 'Prodej 5\u00a0% portfolia drženého v\u00a0akciovém ETF a\u00a0nákup dluhopisového ETF.',
	},
};

const howItWorksSchema = {
	title: 'Jak přesně to\u00a0funguje?',
	chart: {
		broker: 'Broker',
		client: 'Klient',
		money: {
			title: 'Peníze',
			tooltip: 'Peníze na\u00a0investice posíláte ze svého běžného účtu na sběrný účet '
				+ 'u\u00a0brokera. Výběr peněz pak jde také přímo z\u00a0účtu u\u00a0brokera zpět '
				+ 'k\u00a0vám.',
		},
		portfolio: {
			title: 'Výběr portfolia',
			tooltip: 'Vyberete si jedno z\u00a0modelových portfolií. Fondee se o\u00a0toto portfolio '
				+ 'stará a\u00a0dává příkazy k\u00a0nákupu nebo prodeji ETFek\u00a0brokerovi.',
		},
		trading: {
			title: 'Příkazy k\u00a0obchodování',
			tooltip: 'Fondee dává brokerovi příkazy k\u00a0obchodování. Peníze jsou zainvestovány '
				+ 'vždy druhý pracovní den po\u00a0přijetí.',
		},
	},
	broker: {
		title: 'Jaká je role brokera?',
		text: 'Fondee je licencované Českou národní bankou k\u00a0činnosti obchodníka '
			+ 's\u00a0cennými papíry, ale samo se nepohybuje na\u00a0burze cenných papírů, kde '
			+ 'obchodování probíhá. Proto jsme se spojili s\u00a0brokerem, přes kterého nakupujeme '
			+ 'a\u00a0prodáváme cenné papíry pro naše\u00a0klienty.',
	},
};

const investing = {
	hero: {
		anchor: 'Jak rostou ceny?',
		title: 'Inflace? Investice?',
		subtitle: 'Nechytáte se?',
		text: 'Vyzkoušejte si, jak funguje inflace a\u00a0jaké investice se zhodnotily v\u00a0posledních letech. '
			+ 'Vyplatilo se investovat do akcií, do zlata nebo do Bitcoinu? O\u00a0kolik stouply ceny bytů? '
			+ 'A\u00a0kolik piv se dalo koupit za 100\u00a0Kč před deseti lety a\u00a0kolik letos?',
	},
	inflation: {
		title: 'Jak rostou ceny?',
		text: 'Zkuste si, jak se měnily ceny za posledních čtrnáct let.',
		flowLabel: 'Pohyb hodnoty',
		range: 'Od <0>{{ start }}</0> do <0>{{ end }}</0>',
	},
};

const investingInflation = {
	title: 'Inflace a\u00a0co s\u00a0ní',
	list: [
		{
			title: 'Zdražuje se',
			text: 'Ceny jídla, služeb nebo bytů se neustále mění. '
				+ 'Obvykle směrem nahoru. Ekonomové tomu říkají inflace.',
		},
		{
			title: 'Ztráta na hodnotě',
			text: 'Co ale dělat, když máte peníze na účtu a\u00a0každý '
				+ 'další rok si za ně můžete koupit méně a\u00a0méně?',
		},
		{
			title: 'Čas to změnit',
			text: 'Pokud chcete udržet nebo i\u00a0zvyšovat hodnotu '
				+ 'Vašich peněz, nezbyde vám než je investovat. '
				+ 'V\u00a0tom případě je dobré vědět, do čeho peníze '
				+ 'investovat a\u00a0jak vám to může vynášet.',
		},
	],
};

const investingPortfolio = {
	income: {
		button: 'Spočítat!',
		label: 'Investovaná částka:',
		error: 'Vyplňte částku, kterou byste investovali.',
		minError: 'Chce to alespoň 1\u00a0000\u00a0Kč investice.',
		maxError: 'Kalkulačka vám víc než miliardu nevezme, ale ve Fondee vám zainvestujeme klidně i\u00a0miliardu!',
		nonNumericError: 'Nesprávný formát. Uveďte pouze číslice.',
	},
	cash: 'Hotovost',
	portfolioComposition: 'Složení portfolia',
	maximumLimit: 'Maximální limit',
	title: 'Do čeho investovat?',
	description: '<0>Zkušení investoři nikdy <0>nesází na\u00a0jednoho koně</0>. '
		+ 'Naopak se snaží rozložit své investice mezi více různých nástrojů.</0>'
		+ '<0><0>Vyzkoušejte si</0>, jak byste rozdělili svou pomyslnou investici několik '
		+ 'let zpátky a\u00a0jak by se vám zhodnotila.</0>'
		+ '<0>Nezapomínejte, že různé produkty se liší svou rizikovostí a\u00a0že historické výnosy '
		+ 'negarantují budoucí výnosy.</0>',
	tabsTitle: 'Složení portfolia',
	rangerTitle: 'Období investování',
	list: [
		{
			title: 'Diverzifikace',
			text: '<0>Rozdělení investic mezi více různých nástrojů se říká <0>diverzifikace '
				+ 'portfolia</0>. Kdybyste měli všechny své peníze investované do akcií '
				+ 'jedné firmy, můžete mít <0>velký zisk</0>, ale nesete <0>velké riziko</0>, kdyby '
				+ 'se s\u00a0danou firmou cokoliv stalo.</0>'
				+ '<0>Proto se portfolia diverzifikují nejen mezi akcie více firem, ale '
				+ 'například i\u00a0mezi akcie a\u00a0dluhopisy, mezi různé oblasti (USA, Evropa, '
				+ 'Asie), komodity, nemovitosti nebo kryptoměny.</0>',
		},
		{
			title: 'Investiční nástroje',
			text: '<0>Při pohledu do historie to může vypadat, že je nejlepší dát všechny peníze '
				+ 'do nástroje, který rostl nejvíc. Samozřejmě, pokud byste v\u00a0roce 2014 měli '
				+ 'křišťálovou kouli, bývalo by nejlepší investovat vše třeba do Bitcoinu.</0>'
				+ '<0>Jenže <0>křišťálovou kouli nikdo nemá</0>. Historický výnos nikdy nezaručuje, '
				+ 'jak se bude cena chovat v\u00a0budoucnu. Konkrétní složení portfolia tak záleží '
				+ 'na\u00a0Vašich preferencích, ale i\u00a0když chcete mít konzervativní portfolio, vyplatí '
				+ 'se rozdělit ho <0>mezi více investičních nástrojů</0>.</0>',
		},
	],
};

const investingTable = {
	empty: 'Vyberte si prosím složení Vašeho portfolia.',
	default: 'Sestavte si rozložení portfólia podle Vašich představ a kliknutím na tlačítko \'Spočítat\' zjistěte, '
		+ 'jak byste mohli zhodnotit Vaši vstupní investici.',
	title: 'Výsledná hodnota Vašeho portfolia na konci období',
	name: 'Název komodity',
	fromTo: 'Období od <0>{{ from }}</0> do <0>{{ to }}</0>',
	begin: 'začátek',
	end: 'konec',
	evaluation: 'zhodnocení',
};

const investingTips = {
	title: 'Co z\u00a0toho plyne?',
	description: 'Pokud uvažujete, co dělat se svými penězi, '
		+ 'pamatujte si pár základních pravidel:',
	list: [
		{
			title: 'Inflace znehodnocuje Vaše peníze',
			text: 'Když necháte peníze ležet, za\u00a05\u00a0nebo\u00a010\u00a0let si '
				+ 'za ně koupíte mnohem méně.',
		},
		{
			title: 'Při investování myslete na dlouhodobý horizont',
			text: 'Cena investičních nástrojů může krátkodobě '
				+ 'skákat nahoru a\u00a0dolů, i\u00a0když dlouhodobě roste.',
		},
		{
			title: 'Svoje investice diverzifikujte',
			text: 'Rozdělte je mezi různé nástroje, abyste snížili '
				+ 'riziko.',
		},
	],
};

const investingTryout = {
	title: 'A\u00a0co můžete udělat hned teď?',
	description: '<0>Vyzkoušejte <0>Fondee</0>, online '
		+ 'platformu, přes kterou můžete '
		+ 'snadno a\u00a0rychle investovat.</0>',
	subtitle: 'Fondee naplňuje všechny potřeby dlouhodobého investora:',
	button: 'Zkusit Fondee',
	list: [
		{
			title: 'Diverzifikace',
			text: 'Investujete do ETF, tedy fondů, které sdružují desítky či stovky akcií a\u00a0dluhopisů z\u00a0celého světa.',
		},
		{
			title: 'Flexibilita',
			text: 'Vaše peníze nejsou vázané jako u\u00a0termínovaných vkladů, takže si je můžete vybrat '
				+ 'do pár dní klidně všechny.',
		},
		{
			title: 'Smysluplný výnos',
			text: 'Za posledních 10\u00a0let dosahovala portfolia, podle kterých jsou složené profily Fondee, zhodnocení '
				+ 'průměrně od 4\u00a0% do 8\u00a0%, podle rizikovosti, tedy víc, než byla inflace.',
		},
	],
};

const investorProfileAvatar = {
	modal: {
		title: 'Změnit obrázek portfolia',
		submit: 'Změnit obrázek',
		cancel: 'Ponechat stávající obrázek',
	},
};

const investorProfileChoice = {
	title: 'Jaký investiční profil mi odpovídá?',
	text: 'Daný investiční profil Vám vyšel na základě výsledků investičního dotazníku. Svůj investiční profil si můžete '
		+ 'kdykoliv v\u00a0budoucnu změnit ve své klientské zóně.',
	riskTitle: 'Vaše tolerance rizika',
	submit: 'Potvrdit výběr',
	redo: 'Přeji si zkontrolovat odpovědi v\u00a0investičním dotazníku',
	tooltip: {
		classic: 'Jedná se o\u00a0naši tradiční nabídku, která nezohledňuje ESG kritéria.',
		esg: 'ESG investice zohledňují dopady na životní prostředí, sociální aspekty a\u00a0zodpovědné řízení firmy.',
	},
	category: {
		classic: 'Jeden vklad = stovky akcií a dluhopisů z celého světa. Investujte do ETF online, jednoduše a srozumitelně.',
		esg: 'Hodnoty a výnosy si rozumí. Investujte udržitelně do portfolií, která v posledních letech porážejí klasické investice.',
	},
	littleExperience: {
		title: 'Investování s\u00a0Fondee pro Vás není vhodné, protože máte málo znalostí a\u00a0zkušeností '
			+ 's\u00a0investováním.',
		message: 'Pokud si chcete přečíst více o\u00a0tom, co je ETF a\u00a0jak funguje Fondee, '
			+ 'podívejte se <0>sem</0>.',
		reset: 'Chci změnit některé odpovědi',
	},
	notSuitable: {
		title: 'Upozornění',
		message: 'Mrzí nás to, ale na\u00a0základě Vašich odpovědí Vám nyní nemůžeme nabídnout naše služby.',
		text: 'Investování s\u00a0Fondee není vhodné pro krátkodobou spekulaci nebo jiné krátkodobé cíle. Každé investování '
			+ 's\u00a0sebou nese riziko ztráty části nebo celé investice, přičemž produkty určené pro krátkodobé investování bývají '
			+ 'zpravidla pro zajištění požadovaného výnosu mnohem rizikovější. Naše služby jsou určeny pro středně až dlouhodobé '
			+ 'investování do diverzifikovaných produktů.',
		back: 'Zpět do dotazníku',
	},
	notRecommended: {
		title: 'Upozornění',
		message: '<0>Tento investiční profil pro Vás není dle vyhodnocení investičního dotazníku vhodný. '
				+ 'S\u00a0investováním na\u00a0kapitálových trzích je spojeno riziko, které, jak vyplývá z\u00a0Vašich '
				+ 'odpovědí, zřejmě nemůžete nebo nechcete podstoupit.</0><0>Pokud jste při vyplňování investičního '
				+ 'dotazníku uvedli informaci, která neodpovídá skutečnosti, nebo jste omylem označili nesprávnou odpověď, '
				+ 'můžete vyplnit investiční dotazník znovu.</0>',
		clientMessage: '<0>Tento investiční profil pro Vás není dle vyhodnocení investičního dotazníku vhodný. '
				+ 'S\u00a0investováním na\u00a0kapitálových trzích je spojeno riziko, které, jak vyplývá z\u00a0Vašich '
				+ 'odpovědí, zřejmě nemůžete nebo nechcete podstoupit.</0>',
		close: 'Chci si vybrat z\u00a0doporučených profilů',
		clientClose: 'Vrátit zpátky',
		redo: 'Chci vyplnit dotazník znovu',
	},
	notConsistent: {
		title: 'Upozornění',
		text: 'Litujeme, ale Vaše odpovědi týkající se délky investičního horizontu jsou v\u00a0rozporu. Jedná se '
			+ 'o\u00a0otázky “Jak dlouho plánujete investovat?” a “Jak plánujete využít své peníze z\u00a0investic?”. Kliknutím '
			+ 'na\u00a0tlačítko se můžete vrátit zpátky do dotazníku a\u00a0své odpovědi opravit.',
	},
};

const investorProfileDetail = {
	title: 'Nastavte si svou ideální investici',
	subtitle1: 'Pro sebe, nebo pro děti? Více akcií, dluhopisů, nebo půl na půl? Klasická, nebo „zelená“? '
		+ '<0>Záleží jen na vás!</0> Vyberte si produkt, typ investice a\u00a0míru rizika –\u00a0podle toho vám navrhneme '
		+ 'vhodné portfolio.',
	investorPostfix: 'investor',
	showProducts: 'Zobrazit přibližné rozložení ETF v\u00a0portfoliu',
	productsTitle: 'Rozložení titulů ETF v\u00a0portfoliu',
	productToggle: 'Chci investiční účet:',
	portfolioToggle: 'Typ portfolia:',
	selectionTitle: 'Zvolte míru rizika',
	ranger: {
		info: 'Obecně platí, že ochota nést vyšší riziko a delší časový investiční '
			+ 'horizont, který překlene krátkodobé turbulence, také vede k\u00a0vyšším výnosům',
		less1: 'Nižší\u00a0riziko',
		less2: 'Nižší\u00a0výnos',
		more1: 'Vyšší\u00a0riziko',
		more2: 'Vyšší\u00a0výnos',
		recommend: 'Profily v\u00a0<0>zeleném</0> intervalu jsme na základě Vámi vyplněného '
			+ 'investičního dotazníku vyhodnotili jako vhodné pro Vás.',
		recommendWarning: 'Profily v\u00a0šedém intervalu pro Vás nejsou dle vyhodnocení investičního '
			+ 'dotazníku vhodné.',
	},
	profileAccountOptions: [
		{
			label: 'Pro sebe nebo pro děti',
			value: 'both',
		},
	],
	profileTypeOptions: [
		{
			label: 'Klasická',
			value: 'classic',
		},
		{
			label: 'ESG',
			value: 'esg',
		},
	],
};

const investorProfilePreview = {
	toggleLabel: 'Zvolte typ portfolia:',
	classicPortfolios: 'Klasická',
	esgPortfolios: 'ESG',
	walletTitle: 'Vaše úspory',
	sliderTitle: 'investor',
	chartOverlayTitle: 'Historický výnos',
	chartOverlayPeriod: 'za rok',
	chartTooltip: 'Průměrné roční zhodnocení portfolia za období 2014-2024 v\u00a0českých korunách. Výpočet vychází ze '
		+ 'simulovaného historického vývoje založeného na cenách jednotlivých ETF nebo jejich podkladových indexů. Jde '
		+ 'o\u00a0hrubé výnosy před započítáním poplatků.',
	disclaimer: {
		text: 'Historickou výnosnost počítáme jako průměrně roční zhodnocení portfolia za období 2014-2024 v\u00a0českých '
			+ 'korunách a\u00a0výpočet vychází ze simulovaného historického vývoje založeného na cenách jednotlivých ETF nebo '
			+ 'jejich podkladových indexů. Výnosnost našich portfolií výše je počítána před započtením poplatků Fondee '
			+ '(1.09\u00a0% ročně).',
	},
	aboutButton: 'Zjistit víc o tomto produktu',
};

const login = {
	title: 'Přihlášení',
	text: 'Tudy do klientské zóny',
	button: 'Přihlásit se',
	forgotPassword: 'Zapomenuté heslo',
	social: {
		twoFactorTitle: 'Přihlášení přes sociální\u00a0sítě',
		or: 'nebo',
		title: 'Přihlaste se přes:',
	},
};

const media = {
	title: 'Fondee v médiích',
};

const meta = {
	title: 'Fondee',
	titleExtended: 'Fondee | Děláme z peněz víc peněz',
	titleSuffix: ' - Fondee',
	description: 'Investice jednoduše a bez nesmyslných poplatků. Vyzkoušejte investiční aplikaci Fondee a zhodnoťte své peníze v ETF portfoliích už od 1 000 Kč.',
	og: {
		title: 'Fondee',
		description: 'Nenechte ležet peníze na účtech, kde ztrácí hodnotu. Zainvestujte je, ať se zhodnocují samy.',
		referralTitle: 'Fondee - s bonus kódem',
		referralDescription: 'Začněte investovat díky bonusu od kamaráda.',
	},
	titles: {
		account: 'Můj účet',
		childProduct: 'Investice pro děti | Jednoduše s nízkými poplatky',
		classicProduct: 'Investice na míru | Chytře a s nízkými poplatky',
		clientCheck: 'Pravidelné kontroly',
		contact: 'Zeptejte se | Vše, co potřebujete vědět o Fondee',
		cookies: 'Cookies',
		dashboard: 'Přehled',
		documents: 'Dokumenty',
		emailAlias: 'Propojení účtů',
		emailVerification: 'Ověření e-mailu',
		esgProduct: 'ESG investování | Udržitelnost a výnosy si rozumí',
		faq: 'Časté dotazy',
		fees: 'Poplatky | Nižší, než byste čekali',
		forgotPassword: 'Obnova hesla',
		history: 'Historie',
		howItWorks: 'Jak funguje Fondee | Začněte investovat online',
		login: 'Přihlášení | Klientská zóna',
		offers: 'Něco navíc',
		onboarding: 'Dokončení registrace',
		onboardingChild: 'Registrace dítěte',
		onboardingParent: 'Registrace rodiče',
		ourApproach: 'O nás | Zjistěte, kdo stojí za Fondee',
		pensionProduct: 'Investice na důchod | Dlouhodobý investiční produkt',
		portfolio: 'Portfolio',
		privacyPolicy: 'Ochrana osobních údajů',
		referrals: 'Doporučení',
		registration: 'Registrace | Začněte investovat s Fondee',
		resetPassword: 'Změna hesla',
		signAgreement: 'Podpis smlouvy',
		signNewAgreement: 'Podpis smlouvy',
		studentProduct: 'Zvýhodněné investice pro studenty | Snadno & online',
		taxes: 'Daně 2024',
		unsubscribe: 'Odhlášení z odběru',
	},
	descriptions: {
		childProduct: 'Jak dětem zajistit bohatší budoucnost? Nenechte úspory ležet v bance, kde ztrácejí hodnotu. Založte investiční portfolio pro děti se sníženým poplatkem.',
		classicProduct: 'Investujte do stovek akcií a dluhopisů z celého světa skrze ETF – fondy obchodované na burze. Jednoduše, automatizovaně a srozumitelně.',
		contact: 'Poplatky, bezpečnost nebo daně. Nejčastější otázky a odpovědi vám pomohou lépe porozumět Fondee a rozhodnout se, zda je pro vás to pravé.',
		esgProduct: 'Investujte udržitelně s ESG portfolii, která v posledních letech porážejí klasické investice. Uvidíte, že hodnoty a výnosy si rozumí. ',
		fees: 'S Fondee přesně víte, kolik platíte – a více zbyde pro vás! Investujte chytře bez vstupních, výstupních a transakčních poplatků.',
		howItWorks: 'Jen pár kroků k první investici. Zjistěte, jak funguje investiční platforma Fondee a zhodnoťte peníze jednoduše online. Stačí jeden bankovní příkaz.',
		login: 'Přihlaste se ke svému Fondee účtu a mějte přehled nad investicemi. Spravujte svá portfolia a sledujte, jak se jim daří – vše na jednom místě.',
		ourApproach: 'Fondee důvěřují tisíce klientů z několika zemí. Dohlíží na nás ČNB a podporuje J&T Banka. Přečtěte si více a podívejte se na vývoj portfolia zakladatelů.',
		pensionProduct: 'Nespoléhejte na neefektivní bankovní produkty a vezměte budoucnost do svých rukou. Investujte dlouhodobě s nízkým poplatkem a rozumnými výnosy.',
		registration: 'Zaregistrujte se ve Fondee a investujte chytře, diverzifikovaně a s nízkými poplatky. Stačí si vybrat portfolio a poslat vklad – zbytek řešíme za vás.',
		studentProduct: 'Investice pro studenty s nízkým poplatkem a možností příspěvků od rodiny a blízkých. Investujte do ETF portfolií online v aplikaci Fondee.',
	},
};

const newspaper = {
	meta: {
		title: 'Zítra dnes –\u00a0noviny z\u00a0budoucnosti',
		description: 'Noviny z\u00a0roku 2050. První český hostinec na asteroidu. Štatlaři drapnó fofršalinu: hyperloop propojil Brno a\u00a0Vídeň. Samořízené taxíky okrádají turisty.',
	},
	title: 'Zprávy z budoucnosti',
	text: 'Přiletěly k\u00a0nám zprávy z\u00a0budoucnosti, to musíte vidět!',
	button: 'Stáhnout noviny jako PDF',
	embed: {
		alt: 'Titulní stránka noviny Zítra dnes',
		title: 'Noviny z\u00a0budoucnosti',
	},
};

const offers = {
	title: 'Co by se vám také mohlo hodit?',
	text: 'Dali jsme to dohromady s několika českými startupy nejen z oboru financí a přinášíme vám tipy na jejich služby.',
	code: 'Referenční kód',
};

const onboarding = {
	title: 'Vítejte ve Fondee!',
	text: 'Teď Vás čeká nudný, ale nutný registrační proces.\nO\u00a0to jednodušší pak bude správa Vašich investic.',
	info: 'Abychom Vám mohli založit investiční účet, potřebujeme Vás lépe poznat. Slibujeme, že to bude rychlejší, než '
		+ 'kdybyste šli do banky.\nNa vše se ptáme, protože jsme finanční instituce regulovaná Českou národní bankou. '
		+ 'Vaše údaje šifrujeme a\u00a0bezpečně uložíme do našeho interního systému.',
	steps: {
		product: {
			title: 'Volba produktu',
			childInfo: 'Než vytvoříme nový účet pro Vaše dítě, musíme nejprve založit účet pro Vás. Chápeme, že je to trochu '
				+ 'otrava, ale musíme si být jistí, že je všechno v\u00a0pořádku.',
		},
		profileType: {
			title: 'Typ portfolia',
		},
		questionnaires: {
			title: 'Dotazníky',
			childTitle: 'Dotazníky dítěte',
			AML: 'AML dotazník',
			ESG: 'ESG dotazník',
			investment: 'Investiční dotazník',
		},
		amlQuestionnaire: {
			title: 'Dotazník AML',
		},
		questionnaire: {
			title: 'Dotazník',
			active: 'Investiční dotazník nám (a\u00a0Vám) pomůže zjistit, jaké portfolio je pro Vás vhodné. Typ portfolia si pak '
				+ 'můžete kdykoliv změnit nebo si založit další. Pokud jste si zvolili naše penzijní portfolio, '
				+ 'pamatujte na to, že produkt je určen na dlouhodobé investování po dobu minimálně 10\u00a0let '
				+ 'a\u00a0peníze lze bez dalších poplatků vybrat až po dosažení 60\u00a0let věku.',
			activeChild: 'Investiční dotazník vyplňujete za dítě. Tam, kde je to možné, vyplňte odpovědi podle situace vašeho dítěte. '
				+ 'Může se jednat např. o\u00a0investiční cíle nebo finanční zázemí. Naopak zkušenosti a\u00a0znalosti vyplňte podle sebe.',
			signAgreementActive: 'Investiční dotazník je trochu rozsáhlejší a\u00a0obsahuje některé nové otázky. Není to '
				+ 'proto, že bychom od Vás chtěli více informací, ale proto, že nám to ukládá legislativa a\u00a0rozšířené '
				+ 'bezpečností předpisy, které nyní musíme splňovat. Všechny odpovědi jsou samozřejmě důvěrné a\u00a0spadají '
				+ 'pod ochranu osobních údajů.',
			done: 'Hotovo, dotazník je úspěšně za Vámi! Investiční profil si můžete změnit nebo si založit další kdykoli po '
				+ 'dokončení registrace.',
			continue: 'Pokračovat na další krok',
			select: 'Vyberte z možností',
			agree: 'Rozumím',
			amlTitle: 'A\u00a0ještě nás čeká pár otázek týkajících se původu peněz, které chcete investovat. Jdeme na to.',
			years_one: '{{ count }} rok a\u00a0méně',
			years_few: '{{ count }} roky',
			years_other: '{{ count }} let a\u00a0více',
			ratio_min: 'Méně než 10\u00a0%',
			currency: 'Kč',
			cancelModal: {
				title: 'Vaše rozhodnutí respektujeme.',
				text: 'Pokud byste se chtěl/a k\u00a0investování s\u00a0Fondee vrátit, neváhejte se nám ozvat na '
					+ '<0>$t(email.address)</0> nebo pomocí chatu na\u00a0těchto webových stránkách.',
				return: 'Zrušit příkaz ke zrušení účtu',
				logout: 'Odhlásit',
			},
			americanModal: {
				title: 'Upozornění',
				text: 'Je nám líto, ale v\u00a0současné době Vám nemůžeme nabídnout naše služby. Pokud se v\u00a0budoucna tato '
					+ 'situace změní, dáme Vám vědět. Pokud si nepřejete být kontaktováni, můžete svou registraci zrušit přes odkaz níže. '
					+ 'V\u00a0případě jakýchkoliv otázek nás neváhejte kontaktovat na info@fondee.cz.',
				link: 'Zrušit registraci',
				button: 'Dejte mi vědět',
				thanks: 'Děkujeme za Váš zájem o\u00a0Fondee!',
			},
			esgRatioModal: {
				title: 'Upozornění',
				text: '<0>Ve Fondee máme nyní v\u00a0nabídce udržitelného investování tzv. „A8\u00a0produkt“ (podíl v\u00a0portfoliu 100\u00a0%).</0>'
					+ '<0>Co to znamená? A8\u00a0obsahuje fondy podporující životní prostředí, sociální aspekty a\u00a0správné řízení společnosti.</0>'
					+ '<0>Pokud máte zájem o\u00a0jiný typ udržitelné investice než A8, bohužel Vám nyní nemůžeme nic nabídnout.</0>'
					+ '<1>Je to trochu zvláštní, ale i\u00a0přestože nyní nabízíme jen jednu kategorii ESG investice (A8), '
					+ 'máme povinnost se Vás zeptat na Vaše preference. Více o\u00a0regulaci udržitelných investic a\u00a0zákonu RTS si '
					+ 'můžete přečíst v\u00a0článku <0>"Vše o evropské ESG regulaci”</0></1>'
					+ '<0>Své odpovědi můžete kdykoliv změnit.</0>',
				button: 'Zpět',
				continue: 'Rozumím a\u00a0chci pokračovat',
			},
			esgFinalQuestion: {
				title: 'Upozornění',
				text: '<0>Vaše preference ohledně ESG investice neodpovídají portfoliím, která Fondee momentálně nabízí '
					+ '(A8\u00a0produkt se zastoupením 100\u00a0%).</0>'
					+ '<0>Fondee ESG portfolia podporují udržitelnost v\u00a0environmentálních a\u00a0sociálních aspektech za předpokladu '
					+ 'správného fungování firmy.</0>'
					+ '<0>Pokud preferujete jiný typ ESG investice než A8\u00a0produkt, nemůžeme Vám nabídnout žádný investiční produkt. '
					+ 'Služeb Fondee můžete využít v\u00a0případě, že upravíte své preference ohledně udržitelnosti.</0>'
					+ '<0>Pokud se tak rozhodnete, uveďte prosím důvod, který Vás k\u00a0tomuto rozhodnutí vedl. Pokud si přejete vrátit '
					+ 'se do dotazníku a\u00a0změnit své odpovědi, pokračujte přes tlačítko níže.</0>',
				button: {
					continue: 'Chci změnit své ESG preference tak, že mou volbou je A8 produkt se zastoupením 100\u00a0%, a\u00a0investovat '
						+ 's\u00a0Fondee do ESG produktů.',
					change: 'Chci se vrátit do ESG dotazníku a\u00a0změnit své odpovědi',
				},
				declaration: '<0>Tímto prohlašuji, že jsem byl společností Fondee informován/a, že mi v\u00a0současné době nemůže '
					+ 'poskytnout investiční službu způsobem, který by vyhovoval mým preferencím týkajícím se udržitelných investic.</0>'
					+ '<0>Na základě této informace jsem se rozhodl/a upravit své preference a\u00a0vybrat si z\u00a0produktů Fondee.</0>',
			},
			logout: 'Děkujeme za Váš zájem o\u00a0Fondee!',
		},
		documents: {
			title: 'Ověření Vaší totožnosti',
			childTitle: 'Ověření totožnosti dítěte',
			error: 'Omlouváme se, při ověřování totožnosti došlo k chybě.',
			redirect: {
				title: 'Ověřit přes Veriff',
				text: 'Veriff je náš partner, skrze kterého můžete kontrolu dokončit během pár minut. Doporučujeme '
					+ 'proces provést na\u00a0mobilním telefonu a\u00a0další tipy najdete v\u00a0otazníčku níže.',
				listTitle: 'Je možné nahrát následující typy dokladů:',
				list: '<0>Občanský průkaz</0>'
					+ '<0>Cestovní pas</0>'
					+ '<0>Řidičský průkaz</0>',
				listChild: '<0>Občanský průkaz</0>'
					+ '<0>Cestovní pas</0>',
			},
			upload: {
				title: 'Manuální ověření',
				frontSide: 'Přední strana',
				backSide: 'Zadní strana',
				list: {
					formats: '<0>Podporované formáty:</0>'
						+ '<1>PNG, JPG</1>',
					size: '<0>Velikost:</0>'
						+ '<1>Maximální velikost 4MB</1>',
				},
				fileTypes: {
					birthCertificate: 'Rodný list',
					drivingLicense: 'Řidičský průkaz',
					identification: 'Občanský průkaz',
					passport: 'Cestovní pas',
				},
				submit: 'Potvrdit',
			},
			tooltip: '<0>Pár tipů na úspěšné focení:</0>'
				+ '<1><0>povolte použití kamery na PC nebo telefonu</0>'
				+ '<0>mějte dobré světelné podmínky</0></1>'
				+ '<0>Čemu se vyhnout:</0>'
				+ '<1><0>odrazům světla na dokladu</0>'
				+ '<0>špatnému zaostření</0>'
				+ '<0>focení v tmavé místnosti</0></1>'
				+ '<0>Pokud jste vyzkoušeli všechny výše uvedené tipy a\u00a0stále se Vám nedaří ověření totožnosti, dejte nám vědět '
				+ 'přes chat nebo e-mailem na info@fondee.cz. Rádi Vám pomůžeme.</0>',
		},
		childData: {
			title: 'Registrace dítěte',
			form: {
				personalIdentificationNumber: {
					title: 'Rodné číslo dítěte',
					text: 'Zadejte prosím rodné číslo dítěte bez\u00a0lomítka.',
				},
				bankAccount: {
					title: 'Číslo účtu dítěte (nepovinné)',
				},
			},
		},
		childEmailVerification: {
			title: 'Ověření e-mailu dítěte',
			text: 'Na uvedenou e-mailovou adresu jsme Vám odeslali instrukce, jak provést její ověření. Zabere to jen chvíli.',
			form: {
				code: {
					label: 'Ověřovací kód',
					cancel: 'Vymazat dětský e-mail',
					confirm: 'Potvrdit',
					skip: 'Preskočit ověření, vymazat dětský e-mail a pokračovat v registraci.',
					invalid: 'Neplatný ověřovací kód',
				},
				email: {
					label: 'E-mailová adresa',
					change: 'Změnit e-mail',
					success: 'Odesláno na nový e-mail',
				},
			},
		},
		personalData: {
			title: 'Kontrola osobních údajů',
			active: 'Zde zkontrolujte a\u00a0doplňte Vaše údaje.',
			student: {
				confirm: 'Mám ISIC/ITIC kartu',
				error: 'Pro pokračování je nutné zaškrtnout.',
				modal: {
					title: 'Upozornění',
					text: 'Bohužel je vám přes 26\u00a0let a\u00a0nesplňujete tedy podmínky pro založení studentského účtu. '
						+ 'Registraci ale můžete dokončit jako standardní účet, kde budete mít '
						+ 'všechny možnosti jako u\u00a0studentského kromě sníženého poplatku.',
					confirm: 'Pokračovat bez ISIC',
				},
			},
			form: {
				name: {
					title: 'Jméno',
					text: 'Investiční účet zaregistrujeme na jméno:',
				},
				address: {
					title: 'Adresa trvalého pobytu',
				},
				contactAddress: {
					add: 'Chci uvést jinou kontaktní adresu',
					title: 'Kontaktní adresa',
					confirm: 'Prohlašuji, že zadaná adresa je správná.',
				},
				birthDate: {
					title: 'Datum narození',
				},
				placeOfBirth: {
					title: 'Místo narození',
				},
				nationality: {
					title: 'Státní občanství',
				},
				personalIdentificationNumber: {
					title: 'Rodné číslo',
					text: 'Zadejte prosím své rodné číslo bez\u00a0lomítka.',
				},
				bankAccount: {
					title: 'Číslo účtu',
					text: 'Pro spárování platby s\u00a0Vaším investičním účtem potřebujeme znát číslo bankovního účtu, '
						+ 'ze kterého budete peníze posílat.',
					warning: 'Upozorňujeme, že účet musí být veden na Vaše <0>vlastní jméno</0>. Proto <0>neakceptujeme platby služeb třetích '
						+ 'stran</0> jako jsou např. Wise, Revolut či\u00a0Monese.',
					childWarning: 'Pokud má Vaše dítě vlastní bankovní účet, můžete ho vložit zde. V\u00a0takovém případě by měl být účet vedený '
						+ 'přímo na\u00a0jméno dítěte. Z\u00a0tohoto důvodu není možné využít služeb jako např. Wise, Revolut či\u00a0Monese.',
					ibanText: 'Pokud si přejete přidat <0>zahraniční účet</0>, vložte ho jako IBAN',
				},
				confirm: {
					label: 'Potvrzuji, že údaje, které jsem uvedl/a výše, jsou pravdivé a\u00a0aktuálně platné.',
					error: 'Pro pokračování je nutné zaškrtnout.',
				},
				submit: 'Potvrdit',
			},
		},
		agreement: {
			title: 'Podpis smlouvy',
			active: 'Už jsme skoro u\u00a0konce. Smlouva o\u00a0investičním zastupování nám umožní investovat Vaše peníze podle Vámi '
				+ 'zvoleného investičního profilu.',
			signAgreementActive: 'Kvůli přechodu k\u00a0novému brokerovi se ve smlouvě mění pár legislativních '
				+ 'termínů a\u00a0drobností. Fakticky se pro Vás ale nic nemění, Fondee bude dál investovat Vaše '
				+ 'peníze podle zvoleného profilu. Nic\u00a0víc.',
			signNewAgreementActive: 'Smlouva upravuje náležitosti týkající se investování s\u00a0Fondee. V\u00a0přílohách '
				+ 'najdete obchodní podmínky, ceník a\u00a0reklamační řád. Stáhněte si ji, pečlivě pročtěte a\u00a0odsouhlaste '
				+ 'níže. K\u00a0ověření identity Vám zašleme SMS\u00a0kód.',
			download: 'Stáhnout smlouvu v PDF',
			timeOutText: 'Po 20\u00a0minutách nečinnosti Vás z\u00a0bezpečnostních důvodů odhlásíme. Pokud chcete věnovat studiu smlouvy '
				+ 'více času, jen do toho. Když se opět přihlásíte, můžete pokračovat v\u00a0registraci tam, kde jste skončili.',
			formAgreement: {
				infoText: 'Připravili jsme pro Vás smlouvu. Stáhněte si ji, pečlivě pročtěte a\u00a0odsouhlaste '
					+ 'níže. K\u00a0ověření identity Vám zašleme SMS\u00a0kód.',
				readAgreement: {
					label: 'Smlouvu jsem si přečetl/a',
					blocked: 'Nejprve si prosím přečtěte smlouvu.',
					required: 'Je třeba odsouhlasit, že jste smlouvu přečetli.',
				},
				smsSend: 'Odeslat kód',
				smsBlocked: 'Ověřovací kód jsme odeslali. Pokud potřebujete nový, počkejte prosím minutu.',
				smsText: 'Na číslo <0>{{ phoneNumber }}</0> Vám odešleme SMSku s\u00a0ověřovacím kódem, kterým smlouvu podepíšete.',
				phoneChange: {
					text: 'Pokud jste zadali nesprávné telefonní číslo, můžete si ho změnit <0>tady</0>. Po uložení nového čísla znovu '
						+ 'klikněte na tlačítko Odeslat kód. Nový kód pro podpis smlouvy je možné odeslat po uplynutí 1\u00a0minuty.',
					modal: {
						title: 'Změna telefonního čísla',
						placeholder: 'Nové telefonní číslo',
						button: 'Uložit',
					},
				},
				legalRepresentative: {
					label: 'Jsem zákonný zástupce',
					download: 'Stáhnout prohlášení zákonného zástupce',
				},
			},
			formCode: {
				code: {
					label: 'Vložit ověřovací kód',
					invalid: 'Ověřovací kód má 6 znaků.',
					error: 'Zapomněli jste vyplnit ověřovací kód.',
				},
				error: 'Zadaný kód je neplatný. Zkontrolujte si ho prosím nebo si vyžádejte nový. Pokud máte problémy s podpisem, obraťte se na <0>$t(email.address)</0>.',
				submit: 'Podepsat smlouvu',
			},
		},
		payment: {
			title: 'Odeslání první platby',
			active: 'Zbývá jen poslat první platbu a\u00a0celá registrace je hotová!',
			info: '<0>Kolik?</0>'
				+ '<1><0>Kolik budete chtít</0>'
				+ '<0>Od 1 000 Kč vklad zainvestujeme</0>'
				+ '<0>Od 10 000 Kč Vám sestavíme dobře diverzifikované portfolio</0></1>',
			sentMoney: {
				label: 'Potvrzuji, že jsem úvodní platbu odeslal dle výše uvedených pokynů',
				error: 'Pro pokračování je nutné odeslat platbu.',
			},
			emailInfo: 'O\u00a0přijetí vkladu a\u00a0jeho zainvestování Vás budeme informovat e-mailem.',
			submit: 'Dokončit registraci',
		},
	},
	final: {
		title: 'Registrace dokončena!',
		payment: {
			title: 'Čekáme na zpracování platby',
			text: 'Nyní čekáme, až bude Vaše první platba připsána na investiční účet u\u00a0brokera. '
				+ 'To bude pravděpodobně následující pracovní den. '
				+ 'Hned pak peníze zainvestujeme podle Vámi zvoleného investičního profilu. '
				+ 'Před zainvestováním ještě zkontrolujeme všechny náležitosti ohledně praní špinavých peněz, které nám ukládá zákon. '
				+ 'Pokud budeme potřebovat více informací, ozveme se Vám.',
		},
		studentConfirmation: {
			title: 'Čekáme na ověření potvrzení o\u00a0studiu',
			text: 'Ověřujeme platnost Vašeho potvrzení o\u00a0studiu, což může trvat pár pracovních dnů. '
				+ 'V\u00a0případě jakýchkoli dotazů nás neváhejte kontaktovat na\u00a0chatu nebo na\u00a0<0>info@fondee.cz</0>.',
		},
		profile: {
			title: 'Chcete změnit investiční profil?',
			text: 'Pokud chcete změnit svůj investiční profil ještě před první zainvestováním, můžete tak učinit po aktivaci '
				+ 'Vašeho účtu v sekci \u201eMůj účet\u201c. Další změny profilu se projeví vždy při nejbližším rebalancování portfolia.',
		},
		topUp: {
			title: 'Nejste si jistí, zda jste správně odeslali první platbu?',
			text: 'Pokud jste dokončili všechny kroky registrace před více než dvěma pracovními dny, zkontrolujte prosím, zda '
				+ 'v\u00a0pořádku odešla první platba z\u00a0účtu {{ account }}. Pokud ne, odešlete platbu znovu podle pokynů <0>zde</0>.',
			newAccount: 'Pokud jste náhodou odeslali ověřovací platbu z\u00a0jiného účtu, můžete si ho přidat přes tlačítko níže. '
				+ 'Pro všechny další účty platí stejné podmínky, musí být tedy vedené na Vaše jméno u\u00a0bankovní instituce se '
				+ 'sídlem v\u00a0EU. Odeslanou platbu poté dohledáme a\u00a0spárujeme s\u00a0Vaším novým účtem.',
		},
		agreement: {
			title: 'Zapomněli jste, kam jste si stáhli smlouvu?',
			text: 'Smlouvu máte připravenou ke stažení v\u00a0sekci <0>Dokumenty</0>.',
		},
		contact: {
			title: 'Chcete vědět cokoli dalšího?',
			text: 'Pište nám do chatu vpravo dole nebo pošlete mail na adresu <0>$t(email.address)</0>.',
		},
		feedback: {
			title: 'Budeme rádi za zpětnou vazbu',
			done: 'Děkujeme za zpětnou vazbu!',
			form: {
				intro: 'Dejte nám vědět, jak jste byli spokojeni s\u00a0registračním procesem:',
				statusGood: 'Naprosto spokojen/a',
				statusBad: 'Naprosto nespokojen/a',
				message: 'A\u00a0můžete nám něco vzkázat i slovně:',
				submit: 'Odeslat zpětnou vazbu',
			},
		},
	},
	profilePreview: {
		text: 'Zvolený investiční profil:',
		info: 'Investiční profil je možné kdykoliv v\u00a0budoucnu změnit po dokončení registrace.',
	},
};

const ourApproachAccount = {
	title: 'Eva a Honza s Fondee investují také',
	text: 'Takhle vypadá jejich reálné portfolio',
};

const ourApproachHero = {
	title: 'Zjistěte, kdo stojí za Fondee',
	text: 'Investování je o důvěře, pojďte se seznámit',
	list: [
		{
			title: '17\u00a0000+ klientů, 3\u00a0země',
			text: '17\u00a0000+ klientů ze 3\u00a0zemí s\u00a0námi aktivně investuje. Svěřilo své portfolio Fondee '
				+ 'a\u00a0zhodnocují peníze jednoduše, transparentně a\u00a0s\u00a0nízkými poplatky. Přidáte se?',
		},
		{
			title: 'Dohled České národní banky',
			text: 'Stejně jako na ostatní české finanční instituce, tak i na nás dohlíží ČNB. Spolu s\u00a0Garančním '
				+ 'fondem obchodníků s\u00a0cennými papíry zajišťuje, že jsou u\u00a0nás vaše peníze v\u00a0bezpečí.',
		},
		{
			title: 'Spolupráce s J&T Bankou',
			text: 'J&T Banka v\u00a0nás vidí potenciál. Podporuje Fondee finančně i\u00a0sdílením zkušeností z\u00a0oboru '
				+ 'a\u00a0přispívá tak k\u00a0rychlejšímu rozvoji moderní investiční aplikace, která tady chyběla.',
		},
	],
	anchor: 'Koho byste vybrali',
};

const ourApproachTeam = {
	title: 'Koho byste si vybrali, aby se staral o\u00a0Vaše\u00a0finance?',
	team: {
		eva: {
			name: 'Eva Hlavsová',
			text: '<0>Třeba někoho, kdo vystudoval <1>ekonomii na\u00a0Cambridge</1> a\u00a0na\u00a0IES v\u00a0Praze a\u00a0má za sebou '
				+ 'roky praxe jako <1>viceprezident analýzy investičních rizik</1> v\u00a0Morgan\u00a0Stanley? Nebo někoho, kdo pracoval '
				+ 'na\u00a0Ministerstvu financí a\u00a0v\u00a0Evropské bance pro obnovu a\u00a0rozvoj?</0>'
				+ '<0>Tohle všechno má za sebou Eva, zakladatelka Fondee.</0>',
			link: 'Evin profil na\u00a0LinkedIn',
		},
		honza: {
			name: 'Honza Hlavsa',
			text: '<0>A\u00a0nebo někoho, kdo vystudoval <1>ekonomii na\u00a0LSE v\u00a0Londýně</1> a\u00a0na\u00a0IES v\u00a0Praze, '
				+ 'mnoho let pracoval ve financích v\u00a0londýnské City a\u00a0staral se o\u00a0portfolia <1>v\u00a0miliardách dolarů</1>?</0>'
				+ '<0>To jsou zkušenosti Honzy, zakladatele Fondee.</0>',
			link: 'Honzův profil na\u00a0LinkedIn',
		},
		pavel: {
			name: 'Pavel Štěpánek',
			text: '<0>A\u00a0co takhle Pavel? Působil jako člen bankovní rady <1>České národní banky</1>, ředitel <1>České bankovní '
				+ 'asociace</1>, člen Rady ředitelů Evropské banky pro obnovu a\u00a0rozvoj nebo jako náměstek ministra financí. '
				+ 'Dnes mimo jiné přednáší na\u00a0VŠE.</0>'
				+ '<0>Do Fondee vnesl <1>přes 30 let zkušeností</1> ve financích a\u00a0pomáhá nám jít správným směrem.</0>',
			link: 'Pavlův profil na\u00a0LinkedIn',
		},
	},
};

const portfolio = {
	overview: {
		valueTitle_one: 'Celková hodnota portfolia',
		valueTitle_few: 'Celková hodnota portfolií',
		valueTitle_other: 'Celková hodnota portfolií',
		netGrowthTitle: 'Pohyb hodnoty',
		netGrowthTitleTooltip: 'Pohyb hodnoty portfolia očištěný od vkladů, výběrů a\u00a0odměn od Fondee.',
		netGrowthRatioTitle: 'Pohyb hodnoty v\u00a0%',
		netGrowthRatioTitleTooltip: 'Procentuální vývoj hodnoty portfolia se vypočítává z\u00a0podílu aktuální hodnoty '
			+ 'portfolia na výši vkladu očištěného od výběrů a\u00a0odměn. Pokud bylo v\u00a0rámci období více vkladů, výběrů nebo '
			+ 'odměn, započítává se jejich vážená hodnota podle toho, kdy proběhly. Např. pokud jste před šesti měsíci začali '
			+ 's\u00a0investicí 100\u00a0tisíc\u00a0Kč, včera jste vložili dalších 100\u00a0tisíc\u00a0Kč a\u00a0aktuální hodnota '
			+ 'vašeho portfolia je 210\u00a0tisíc Kč, bude pohyb hodnoty zhruba ve výši 10\u00a0%, protože nově vložených '
			+ '100\u00a0tisíc\u00a0Kč se ještě nestihlo zhodnotit.',
		noActivePortfolio: 'Nemáte aktivní žádné portfolio',
	},
	select: {
		title: 'Moje portfolia',
		button: 'Založit portfolio',
		disabledTooltip: 'Uživatel bude aktivován po kontrole údajů a\u00a0alokaci prvního vkladu.',
		back: 'Zpět',
		archive: {
			button: 'Zobrazit archivovaná portfolia',
			title: 'Seznam archivovaných portfolií',
			name: 'Název portfolia',
			select: 'Zobrazit',
			back: 'Zpět na\u00a0portfolia',
		},
	},
	new: {
		modal: {
			title: 'Zvolte typ portfolia',
			subtitle: 'Máte na výběr možnost založení penzjního portfolia.',
			input: 'Název portfolia',
			nameTooltip: 'Název portfolia slouží jen pro lepší orientaci.',
			childInfo: 'Pokud založení dětského portfolia nedokončíte, můžete se k\u00a0němu kdykoliv vrátit v\u00a0sekci '
				+ 'Můj účet -\u00a0Moje děti.',
			add: 'Vytvořit portfolio',
			close: 'Zavřít',
			currency: 'Měna zobrazení',
			currencyTooltip: 'Jedná se měnu, ve které chcete vidět hodnotu svého portfolia na\u00a0stránce Portfolio a\u00a0Historie.'
				+ ' Měnu zobrazení můžete kdykoliv změnit a\u00a0nemá vliv na\u00a0složení portfolia.',
			additional: 'Dodatečná nastavení',
			rebalancingActive: 'Zapnout možnost automatického rebalancování',
			rebalancingTooltip: 'Zaškrtněte toto pole, pokud chcete, abychom jednou za měsíc Vaše portfolio automaticky vyvážili '
				+ 'podle vybraného investičního profilu. Volbu můžete kdykoliv změnit v\u00a0nastavení profilu. '
				+ 'Tato volba nemá vliv na\u00a0zainvestování vkladů a\u00a0zpracování výběrů.',
			newConsent: 'Souhlasím s novými <0>obchodními podmínkami</0>.',
			newConsentPension: 'Souhlasím s novými <0>obchodními podmínkami</0> pro založení penzijního účtu.',
			errorClosure: 'Není možné založit nové portfolio, protože máte zadané zrušení Vašeho účtu. Zrušení můžete '
				+ 'stornovat v\u00a0sekci <0>Můj účet</0>.',
			warning: {
				limit: {
					title: 'Nové portfolio nelze založit',
					text: 'Omlouváme se, ale maximální počet portfolií na jeden uživatelský účet je 5.',
				},
				limitChild: {
					title: 'Nové portfolio nelze založit',
					text: 'Omlouváme se, ale maximální počet portfolií na jeden dětský účet je 3.',
				},
				limitPension: {
					title: 'Nové portfolio nelze založit',
					text: 'Aktuálně je možné mít pouze jeden penzijní účet.',
				},
			},
			success: {
				title: 'Nové portfolio založeno',
				text: 'Máte nové portfolio! Aby začalo být aktivní, je potřeba do něj poslat vklad. Klikněte v\u00a0menu na záložku Portfolio a\u00a0vyberte ho. Na stránce Portfolio zvolte Chci vložit peníze.',
			},
		},
		childList: {
			title: 'Jméno dítěte',
			select: 'Zvolit',
			addChild: 'Pozvat další dítě',
		},
		form: {
			name: {
				empty: 'Zapomněli jste vyplnit název portfolia',
				error: 'Název portfolia nemůže začínat mezerou',
				unique: 'Tento název portfolia již používáte',
			},
		},
		create: 'Přidat portfolio',
	},
	settings: {
		title: 'Nastavení portfolia',
		name: {
			label: 'Upravit název portfolia',
			button: 'Změnit název',
		},
		currency: {
			title: 'Měna zobrazení portfolia',
			success: 'Měna zobrazení portfolia změněna. Pokud si přejete posílat vklady v\u00a0jiné měně, podívejte se na instrukce <0>sem</0>.',
		},
		additional: {
			title: 'Dodatečná nastavení',
		},
		pensionConfirmation: {
			account: 'Potvrzení pro zaměstnavatele o\u00a0sjednání DIP si můžete stáhnout <0>zde</0>.',
			deposit: '<0>Potvrzení</0> o Vašich vkladech na DIP.',
			employerDeposit: '<0>Potvrzení</0> o vkladech zaměstnavatele na DIP.',
		},
	},
};

const portfolioComposition = {
	title: 'Rozložení portfolia',
	profile: 'Investiční profil:',
	plannedProfile: 'Plánovaný investiční profil:',
	profileTooltip: 'Investiční profil si můžete změnit v\u00a0<0>nastavení svého účtu</0>. Změna profilu se '
		+ 'projeví až po vyvážení portfolia (rebalancingu). Aktuální informace najdete v\u00a0bloku Obchodování.',
	table: {
		columns: {
			name: 'Název',
			isin: 'ISIN',
			amount: 'Kusů',
			pricePerVolume: 'Cena za kus',
			totalValue: 'Celková hodnota',
			information: 'Klíčové informace pro\u00a0investora',
		},
		rows: {
			cash: 'Hotovost na účtu',
			exchange: 'Směnný kurz',
			total: 'Celková hodnota Vašeho portfolia',
			totalTooltip: 'Kvůli zaokrouhlení na řádcích nemusí celková hodnota portfolia přesně odpovídat součtu jednotlivých hodnot.',
		},
	},
};

const portfolioFeesEtfs = {
	title: 'Jak se počítají ETF poplatky',
	text: 'Každý fond ETF má své vlastní poplatky, které se pohybují od 0,05\u00a0% do 0,47\u00a0% z\u00a0objemu '
		+ 'zainvestovaných prostředků za rok. Fondee skládá investiční portfolia až ze 14\u00a0různých ETF. Celkové poplatky, '
		+ 'které zaplatíte za ETF, tedy záleží na poplatku jednotlivých ETF a\u00a0jejich zastoupení ve Vašem portfoliu. '
		+ 'Obvykle se poplatky pohybují okolo 0,15\u00a0% z\u00a0celkového objemu investic za\u00a0rok a\u00a0jsou již '
		+ 'zahrnuty v\u00a0hodnotě ETF, takže je neuvidíte v\u00a0přehledu historie změn svého investičního portfolia.',
	userFee: 'Váš aktuální celkový poplatek za ETF je {{ fee }} ročně.',
	link: 'Zobrazit tabulku s kompletním přehledem.',
	modal: {
		title: 'ETF poplatky',
		name: 'Název',
		ter: 'Roční poplatek (TER) za ETF',
	},
};

const portfolioFeesOverview = {
	title: 'Poplatky za správu portfolia',
	table: {
		columns: {
			year: 'Ročně',
			month: 'Tento měsíc *',
		},
		rows: {
			fondee: 'Poplatky Fondee',
			etf: 'Poplatky ETFek',
			total: 'Celkové poplatky',
		},
		discount: 'sleva do {{ date }}',
	},
	note: '*měsíční poplatky se vypočítávají z\u00a0konečné hodnoty portfolia poslední obchodní den '
		+ 'v\u00a0měsíci a\u00a0jsou strženy na\u00a0začátku následujícího měsíce',
	text: 'Poplatky za ETF jsou již zahrnuty v\u00a0jejich hodnotě, na\u00a0výpisu investičního '
		+ 'účtu proto nejsou vidět. Uvádíme je zde pro Vaši představu o\u00a0celkových poplatcích. Poplatky pro '
		+ 'každé ETF lze dohledat v\u00a0tzv. Klíčových informacích\u00a0investora.',
};

const portfolioTable = {
	title_one: 'Portfolio',
	title_few: 'Portfolia',
	title_other: 'Portfolia',
	select: {
		label: 'Zhodnocení za:',
	},
	table: {
		columns: {
			name: 'Název portfolia',
			value: 'Hodnota',
			appreciation: 'Zhodnocení',
		},
		more: 'Více',
		add: 'Přidat portfolio',
	},
};

const privacyPolicy = {
	title: 'Zásady ochrany osobních údajů',
	intro: '<0>Společnost Fondee a.s., se sídlem Rumunská 22/28, Vinohrady, 120\u00a000 Praha\u00a02, IČO: '
		+ '06691862, spisová značka B\u00a027322 vedená u Městského soudu v\u00a0Praze, jako správce '
		+ 'osobních údajů (dále jen „<0>Správce</0>“),</0>'
		+ '<0>by tímto ráda v\u00a0souladu s\u00a0ustanovením čl.\u00a013 Nařízení Evropského parlamentu a\u00a0Rady (EU) '
		+ 'č.\u00a02016/679 ze dne 27.\u00a0dubna 2016 o\u00a0ochraně fyzických osob v\u00a0souvislosti se zpracováním '
		+ 'osobních údajů a\u00a0o volném pohybu těchto údajů a\u00a0o zrušení směrnice 95/46/ES (obecného '
		+ 'nařízení o\u00a0ochraně osobních údajů) (dále jen „<0>Nařízení GDPR</0>“),</0>'
		+ '<0>informovala své zákazníky a\u00a0potenciální zákazníky (dále jen „<0>Subjekty údajů</0>“, jednotlivě '
		+ '„<0>Subjekt údajů</0>“) o\u00a0následujícím:</0>',
	text: '<0>Správce je v\u00a0souladu s\u00a0povolením České národní banky oprávněn nabízet a\u00a0poskytovat svým '
		+ 'zákazníkům následující hlavní investiční služby: (i) přijímání a\u00a0předávání pokynů týkajících se '
		+ 'investičních nástrojů ve smyslu §\u00a04\u00a0odst.\u00a02\u00a0písm.\u00a0a) zákona č.\u00a0256/2004 Sb., o\u00a0podnikání na '
		+ 'kapitálovém trhu, ve znění pozdějších předpisů (dále jen „<0>ZPKT</0>“), (ii) obhospodařování '
		+ 'majetku zákazníka, jehož součástí je investiční nástroj, na základě volné úvahy v\u00a0rámci '
		+ 'smluvního ujednání ve smyslu §\u00a04\u00a0odst.\u00a02\u00a0písm.\u00a0d) ZPKT, a\u00a0(iii) investiční poradenství týkající '
		+ 'se investičních nástrojů ve smyslu §\u00a04\u00a0odst.\u00a02\u00a0písm.\u00a0e) ZPKT, a\u00a0to ve vztahu k cenným papírům '
		+ 'kolektivního investování a\u00a0k investičním nástrojům podle §\u00a03\u00a0odst.\u00a01\u00a0písm.\u00a0d) ZPKT (všechny '
		+ 'tyto investiční služby dále jen „<0>Činnost</0>“).</0>'
		+ '<0>Osobní údaje předané Subjektem údajů, budou Správcem zpracovávány pouze v\u00a0rozsahu '
		+ 'potřebném pro daný účel a\u00a0po dobu, která je nezbytná pro splnění tohoto účelu.</0> '
		+ '<0>Správce zpracovává osobní údaje Subjektů údajů za účelem</0>'
		+ '<1><0>jeho Činnosti, tedy jednání o\u00a0uzavření nebo změně smlouvy a\u00a0splnění smlouvy, '
		+ 'a to včetně vyřizování případných nároků a\u00a0žádostí Subjektu údajů;</0>'
		+ '<0>plnění právních povinností Správce, např. dle právních předpisů upravujících '
		+ 'činnost Správce, dále především zákonné povinnosti v\u00a0souvislosti s\u00a0ochranou '
		+ 'spotřebitele, archivací dokumentů, vedením účetnictví a\u00a0správy daní;</0>'
		+ '<0>oprávněného zájmu Správce, jedná se zejména o\u00a0zpracování osobních údajů za '
		+ 'účelem vedení soudních sporů, správních či obdobných řízení, případně vymáhání '
		+ 'pohledávek, avšak za předpokladu, že zájmy a\u00a0základní práva a\u00a0svobody Subjektu '
		+ 'údajů nepřevažují oprávněný zájem Správce. Z důvodu oprávněného zájmu dále '
		+ 'zpracovává Správce osobní údaje pro vnitřní administrativní účely (např. interní '
		+ 'evidence, reporting atp.), pro řízení kvality poskytovaných služeb a\u00a0vztahů se '
		+ 'Subjekty údajů a\u00a0pro řízení rizik; nebo</0>'
		+ '<0>stanoveném v\u00a0souhlasu se zpracováním osobních údajů udělené Subjektem '
		+ 'údajů. Ve vztahu k souborům cookie lze souhlas udělit pro všechny skupiny '
		+ 'souborů cookie nebo pro vybranou skupinu souborů cookie. Tento souhlas se '
		+ 'zpracováním osobních údajů je dobrovolný a\u00a0Subjekt údajů jej může kdykoliv '
		+ 'odvolat. Se souhlasem zpracovává Správce zvláštní kategorie osobních údajů '
		+ '(zdravotní stav) a\u00a0osobní údaje pro marketingové účely.</0></1>'
		+ '<0>Po splnění původního účelu (například plnění smlouvy) může Správce osobní údaje '
		+ 'zpracovávat pro jiné účely (například pro plnění zákonné archivační doby).</0>'
		+ '<0>Pro výše uvedené účely Správce zpracovává zejména následující osobní údaje</0>'
		+ '<1><0>identifikační a\u00a0adresní údaje: akademický titul, jméno, příjmení, datum narození, '
		+ 'místo narození, rodné číslo, údaje o\u00a0dokladech totožnosti (včetně případného '
		+ 'pořízení fotokopie dokladu totožnosti při vzniku obchodního vztahu nebo v\u00a0jeho '
		+ 'průběhu v\u00a0souladu s\u00a0právními předpisy), pohlaví, adresa trvalého bydliště, '
		+ 'doručovací nebo jiná kontaktní adresa;</0>'
		+ '<0>další údaje o\u00a0Subjektu údajů nebo se Subjektem údajů související, např. rodinný '
		+ 'stav či referenční kód pro registraci u Správce;</0>'
		+ '<0>elektronické kontaktní údaje: (mobilní) telefonní číslo, e-mailová adresa;</0>'
		+ '<0>jiné elektronické údaje: IP adresa, lokační údaje užívaného zařízení;</0>'
		+ '<0>další osobní údaje potřebné pro plnění smlouvy: číslo bankovního účtu, výše plateb'
		+ 'a jejich historie;</0>'
		+ '<0>informace o\u00a0Subjektu údajů z důvodu plnění právních povinností (např. podle '
		+ 'zákona č. 253/2008 Sb., o\u00a0některých opatřeních proti legalizaci výnosů z trestné'
		+ 'činnosti a\u00a0financování terorismu, ve znění pozdějších předpisů (dále jen <0>„AML'
		+ 'zákon</0>“)), a\u00a0to včetně fotografie pořízené Subjektem údajů, Správcem nebo jemu'
		+ 'smluvně zavázanou třetí osobou za účelem ověření shody podoby s\u00a0vyobrazením'
		+ 'v\u00a0dokladu totožnosti ve smyslu AML zákona, informace z veřejně dostupných i'
		+ 'neveřejných rejstříků a\u00a0evidencí;</0>'
		+ '<0>další osobní údaje poskytnuté Subjektem údajů ve smlouvě či v\u00a0jiných'
		+ 'dokumentech, při telefonické či e-mailové komunikaci a\u00a0</0>'
		+ '<0>záznamy telefonických hovorů a\u00a0informace z těchto záznamů</0></1>'
		+ '<0>Osobní údaje Správce získává přímo od Subjektů údajů (zejména v\u00a0případě jednání o\u00a0smlouvě '
		+ 'a při jiné komunikaci se Subjektem údajů), od třetích osob (zejména od orgánů státní správy '
		+ 'při plnění zákonných povinností nebo na základě zvláštních právních předpisů), z veřejně '
		+ 'dostupných zdrojů (zejména veřejných rejstříků), nebo z vlastní činnosti (zejména '
		+ 'vyhodnocením a\u00a0analýzou osobních údajů získaných z jiných, dříve uvedených, zdrojů). '
		+ 'Správce získává osobní údaje v\u00a0omezeném rozsahu automaticky také používáním souborů '
		+ 'cookie na webových stránkách Správce. </0>'
		+ '<0>Osobní údaje Subjektu údajů budou zpracovány korektně a\u00a0zákonným a\u00a0transparentním '
		+ 'způsobem. Budou zpracovány pouze takové osobní údaje, které jsou přiměřené, relevantní a\u00a0'
		+ 'omezené na nezbytný rozsah ve vztahu k účelu, pro který jsou zpracovávány.</0>'
		+ '<0>Osobní údaje Subjektu údajů budou zpracovány způsobem, který zajistí jejich náležité '
		+ 'zabezpečení, včetně jejich ochrany pomocí vhodných technických nebo organizačních '
		+ 'opatření, před neoprávněným či protiprávním zpracováním a\u00a0před náhodnou ztrátou, zničením '
		+ 'nebo poškozením. </0>'
		+ '<0>Správce zpracovává pouze přesné osobní údaje získané v\u00a0souladu s\u00a0Nařízením GDPR a\u00a0má '
		+ 'povinnost zpracovávané osobní údaje aktualizovat. Za tím účelem jsou Subjekty údajů, jejichž '
		+ 'osobní údaje jsou zpracovávány, povinni bezodkladně nahlásit Správci jakoukoliv jejich '
		+ 'změnu a\u00a0předložit Správci dokumenty změnu prokazující. </0>'
		+ '<0>Osobní údaje Subjektu údajů budou zpracovávány po dobu platnosti smlouvy či její správy '
		+ 'Správcem a\u00a0následně dokud trvá oprávněný zájem (zejména po dobu běhu promlčecí lhůty '
		+ 'na plnění ze smlouvy (max. 10 let) od jejího ukončení či ode dne ukončení správy a\u00a0v\u00a0případě '
		+ 'zahájení soudního, rozhodčího, exekučního nebo jiného obdobného řízení po dobu tohoto '
		+ 'řízení a\u00a0následně po dobu běhu lhůt pro podání mimořádných opravných prostředků a\u00a0během '
		+ 'řízení o\u00a0podaných mimořádných opravných prostředcích), dále po dobu udělení souhlasu '
		+ 'Subjektu údajů se zpracováním, pokud byl souhlas udělen, dále po dobu existence právní '
		+ 'povinnosti, která se na Správce vztahuje (finanční správa, dozorový orgán, archivační '
		+ 'předpisy) či pro účely oprávněných zájmů Správce (soudní spor). Správce zpracovává osobní '
		+ 'údaje výhradně pro vymezené účely v\u00a0souladu s\u00a0existujícím právním titulem pro zpracování. '
		+ 'Nedojde-li k uzavření smlouvy se Subjektem údajů, zpracovává Správce osobní údaje, které '
		+ 'v\u00a0souvislosti s\u00a0uzavíráním smlouvy získal, po dobu pěti let. Záznamy hovorů na zákaznickou '
		+ 'linku zaznamenává Správce po dobu pěti let.</0>'
		+ '<0>Subjekt údajů má právo odvolat kdykoliv udělený souhlas se zpracováním svých osobních '
		+ 'údajů či výslovný souhlas se zpracováním zvláštní kategorie osobních údajů, aniž je tím '
		+ 'dotčena zákonnost zpracování založená na souhlasu uděleném před jeho odvoláním. '
		+ 'Podrobnější informace o\u00a0odvolání souhlasu s\u00a0použitím souborů cookie jsou uvedeny '
		+ 'v\u00a0<0>Zásadách používání souborů cookie.</0></0>'
		+ '<0>Osobní údaje Subjektu údajů mohou být pro účely a\u00a0v\u00a0souvislosti s\u00a0Činností Správce '
		+ 'poskytnuty subjektům poskytujícím investiční služby a\u00a0dalším finančním institucím ve '
		+ 'smluvním vztahu se Správcem, oprávněným zaměstnancům a\u00a0pracovníkům Správce a\u00a0dále '
		+ 'poskytovatelům poštovních a\u00a0archivačních služeb a\u00a0osobám, které Správci poskytují IT, '
		+ 'programátorské, právní, účetní a\u00a0poradenské služby a\u00a0dále jiným osobám, je-li to v\u00a0zájmu '
		+ 'zajištění řádného plnění povinností stanovených obecně závaznými právními předpisy '
		+ 'a/nebo smluvními závazky, případně ukládají-li poskytnutí osobních údajů právní předpisy '
		+ 'nebo je to nutné pro ochranu oprávněných zájmů Správce. Osobní údaje dále mohou být '
		+ 'rovněž poskytnuty dle zákona orgánům činným v\u00a0trestním řízení, soudům, ČNB, správci daně, '
		+ 'finanční správě, exekutorům či insolvenčním správcům, ČKP a\u00a0jiným orgánům veřejné moci.</0>'
		+ '<0>Jsou-li Správci poskytnuty při plnění smlouvy Subjektem údajů i osobní údaje dalších '
		+ 'fyzických osob, k jejichž zpracování ze strany Správce dochází, nese odpovědnost za splnění '
		+ 'povinností v\u00a0oblasti ochrany osobních údajů vůči těmto fyzickým osobám (zejména jejich '
		+ 'informování o\u00a0zpracování osobních údajů) daný Subjekt údajů. </0>'
		+ '<0>Správce nemá v\u00a0úmyslu předat osobní údaje Subjektu údajů do třetí země (tzn. mimo '
		+ 'Evropskou unii nebo Evropský hospodářský prostor) ani mezinárodní organizaci. Pokud však '
		+ 'Subjekt údajů přijal či vybral cookie soubory, které jsou poskytovány společností Google, '
		+ 'Facebook, Twitter či YouTube, vyjádřil tím svůj výslovný souhlas s\u00a0předáváním osobních '
		+ 'údajů do třetích zemí (především do USA). Správce však upozorňuje na skutečnost, že po '
		+ 'předání osobních údajů do třetích zemí nemá Správce dostatečné právní a\u00a0faktické nástroje '
		+ 'k zajištění bezpečnosti osobních údajů uživatelů.</0>'
		+ '<0>Správce nejmenoval pověřence pro ochranu osobních údajů, ale určil osobu, která má tuto '
		+ 'oblast na starost a\u00a0která dohlíží nad řádným zpracováním osobních údajů Subjektu údajů.</0>'
		+ '<0>Subjekt údajů má právo požadovat od Správce přístup ke svým osobním údajům a\u00a0na pořízení '
		+ 'kopie osobních údajů, které o\u00a0něm Správce zpracovává, jejich opravu nebo doplnění, jejich '
		+ 'výmaz, popřípadě omezení zpracování, jakož i má právo vznést námitku proti zpracování a\u00a0'
		+ 'má právo na přenositelnost těchto údajů k jinému správci. Subjekt údajů má dále právo podat '
		+ 'stížnost u Úřadu pro ochranu osobních údajů (Pplk. Sochora\u00a027, 170\u00a000 Praha\u00a07, webová '
		+ 'stránka: <1>www.uoou.cz</1>) má-li za to, že Správce při zpracování osobních údajů postupuje '
		+ 'v\u00a0rozporu s\u00a0Nařízením GDPR, případně také může uplatňovat svá práv u příslušného soudu.</0>'
		+ '<0>Kontaktní údaje Správce pro zpracování osobních údajů jsou:</0>',
	contact: '<0>Jan Hlavsa</0>'
		+ '<0>Email: <0>honza@fondee.cz</0></0>',
	consent: {
		title: 'Pro odvolání souhlasu se zpracováním osobních údajů odešlete následující údaje na '
			+ 'adresu <0>info@fondee.cz</0>:',
		text: '<0>Odvolání souhlasu se zpracováním osobních údajů</0>'
			+ '<1>Jméno:</1>'
			+ '<1>Příjmení:</1>'
			+ '<1>Email:</1>'
			+ '<1>Telefon:</1>',
		email: 'info@fondee.cz',
	},
};

const products = {
	child: {
		title: 'Investice pro děti',
		text: 'Nenechte úspory pro děti ležet v\u00a0bance, kde ztrácejí hodnotu. Založte jim investiční portfolio online '
			+ 'a\u00a0se <0>sníženým poplatkem</0>. Jeden trvalý příkaz a\u00a0máte vyřešeno!',
		button: 'Chci investovat pro děti',
		profileDetail: {
			title: 'Prozkoumejte portfolia pro děti',
		},
		fees: {
			title: 'Poplatek',
			text: '<0>Výše poplatku se počítá z\u00a0hodnoty portfolia a\u00a0jednou měsíčně se strhne 1/12 ročního poplatku. V\u00a0ceně získáváte:</0>',
			list: '<0>zpracování <0>vkladů a\u00a0výběrů</0> v\u00a0neomezeném množství</0>'
				+ '<0>neomezený počet <0>nákupů a\u00a0prodejů ETF</0></0>'
				+ '<0><1>rebalancování</1> (pokud si ho nechcete dobrovolně vypnout)</0>'
				+ '<0>zpracování <0>příchozích dividend</0> (týká se klasických portfolií)</0>'
				+ '<0>měnové konverze EURO↔CZK za <0>aktuální tržní kurz</0></0>'
				+ '<0><0>daňové podklady</0> pro případné podání daňového přiznání</0>'
				+ '<0>zákaznickou podporu na chatu, e-mailu i telefonu</0>',
		},
		terms: {
			title: 'Podmínky',
			text: '<0>Přístupy a\u00a0vlastnictví investic</0>'
				+ '<1>Dětský investiční účet zakládá rodič (nebo jiný zákonný zástupce). Dítě má <0>své přístupové údaje</0>, aby mohlo '
				+ 'sledovat a\u00a0učit se, jak se investice chovají. V\u00a0den 18.\u00a0narozenin se z\u00a0dětského účtu stává '
				+ 'běžný investiční účet s\u00a0běžnou výší poplatku a\u00a0přechází <0>pod plnou správu dítěte</0>. To se pak se rozhodne, '
				+ 'jestli si celou sumu vybere, případně část peněz nebo všechny nechá <0>dále zhodnocovat</0>.</1>'
				+ '<0>Portfolia a\u00a0limity pro vklady</0>'
				+ '<1>Chcete dítěti investovat na několik cílů a\u00a0nestačí vám jedno portfolio? Založit můžete <0>až 3\u00a0portfolia</0> '
				+ 's\u00a0různě odvážným investičním profilem. Přispívat do nich navíc mohou <0>nejen rodiče, ale i\u00a0další příbuzní</0>! '
				+ 'Do poznámky k\u00a0platbě jen vloží číslo portfolia, aby se platba správně připsala. Zakladatel dětského účtu '
				+ 'a\u00a0klienti Fondee s\u00a0ověřeným bankovním účtem posílají vklady do dětských portfolií <0>bez omezení</0>. '
				+ 'Osoby, které nejsou klienty Fondee, mohou každému dítěti poslat <0>maximálně 23\u00a0000 Kč ročně</0> z\u00a0jednoho '
				+ 'bankovního účtu.</1>'
				+ '<0>Výběr peněz před 18.\u00a0narozeninami</0>'
				+ '<1>Kdybyste v\u00a0krajní situaci peníze potřebovali vybrat předtím, než vaše ratolest sfoukne 18\u00a0svíček na '
				+ 'dortu, není to problém –\u00a0poplatek se pouze <0>dopočítá do naší běžné výše 1,09\u00a0% ročně</0> a\u00a0peníze máte '
				+ 'do\u00a0týdne na\u00a0účtu.</1>',
		},
		benefits: {
			title: 'Proč investovat pro děti s Fondee?',
			list: [
				'<0>Nižší poplatky</0>'
				+ '<1>Rádi vás podpoříme v\u00a0dlouhodobém investování pro děti, proto na dětských účtech nabízíme <0>zvýhodněný '
				+ 'poplatek 0,24\u00a0%</0> (o\u00a00,85\u00a0% nižší než u\u00a0ostatních Fondee investic!).</1>',
				'<0>Zajištění budoucnosti</0>'
				+ '<1>Předejte dětem částku, která jim v\u00a0budoucnu <0>opravdu pomůže</0>. Za pár let si za dnešní tisícovku nekoupí '
				+ 'to co teď. Investice mají narozdíl od spoření nebo stavebka šanci <0>porazit</0> <4>inflaci</4>.</1>',
				'<0>Vzdělávání dětí</0>'
				+ '<1>Není to jen o\u00a0penězích na školu nebo první bydlení. Děti mohou mít svůj přístup do Fondee a\u00a0sledovat, '
				+ 'jak se investici daří. <0>Učí se tak o\u00a0financích</0> a\u00a0získávají cenné zkušenosti.</1>',
			],
		},
	},
	classic: {
		title: 'Investice na míru',
		text: 'Investice tak jednoduché, že fungují samy. Zhodnoťte své peníze online, s\u00a0nízkými poplatky a\u00a0<0>už od '
			+ '1\u00a0000\u00a0Kč</0>. Vyberte si ze 7\u00a0klasických portfolií a\u00a0investujete do stovek akcií a\u00a0dluhopisů '
			+ 'z\u00a0celého světa.',
		button: 'Chci investovat',
		profileDetail: {
			title: 'Prozkoumejte klasická portfolia',
		},
		fees: {
			title: 'Poplatek',
			text: '<0>Výše poplatku se počítá z\u00a0hodnoty portfolia a\u00a0jednou měsíčně se strhne 1/12 ročního poplatku. V\u00a0ceně získáváte:</0>',
			list: '<0>zpracování <0>vkladů a\u00a0výběrů</0> v\u00a0neomezeném množství</0>'
				+ '<0>neomezený počet <0>nákupů a\u00a0prodejů ETF</0></0>'
				+ '<0><1>rebalancování</1> (pokud si ho nechcete dobrovolně vypnout)</0>'
				+ '<0>zpracování <0>příchozích dividend</0> (týká se klasických portfolií)</0>'
				+ '<0>měnové konverze EURO↔CZK za <0>aktuální tržní kurz</0></0>'
				+ '<0><0>daňové podklady</0> pro případné podání daňového přiznání</0>'
				+ '<0>zákaznickou podporu na chatu, e-mailu i telefonu</0>',
		},
		benefits: {
			title: 'Proč investovat s Fondee?',
			list: [
				'<0>Diverzifikace</0>'
				+ '<1>Nesázejte na jednu kartu. Fondee portfolia obsahují stovky akcií a\u00a0dluhopisů <0>z\u00a0různých zemí '
				+ 'i\u00a0oborů</0>. Díky kvalitní diverzifikaci hodnotu vaší investice neohrozí neúspěch jedné firmy nebo odvětví.</1>',
				'<0>Flexibilita</0>'
				+ '<1>Fondee se vám přizpůsobí. Investujte, kolik chcete. Investujte, kdy chcete. Založte si až <0>5\u00a0různých '
				+ 'portfolií zároveň</0>. A\u00a0když se rozhodnete peníze vybrat, <0>do\u00a0týdne</0> je máte na účtu.</1>',
				'<0>Bezpečnost</0>'
				+ '<1>Na nás dohlíží <0>Česká národní banka</0> a\u00a0vaše údaje střeží šifrovaná komunikace. Kdybyste přišli '
				+ 'o\u00a0heslo, peníze <0>nelze poslat na cizí účet</0> ani změnit osobní data bez autorizace a\u00a0předložení dokladu.</1>',
			],
		},
	},
	esg: {
		title: 'Udržitelné investice',
		text: 'Výnosy, nebo odpovědnost a\u00a0hodnoty? Získejte obojí! Investujte udržitelně do ESG portfolií '
			+ 'a\u00a0zhodnoťte své peníze s\u00a0dobrým pocitem, že přispíváte k\u00a0<0>lepší budoucnosti</0> – své i\u00a0nás všech.',
		button: 'Chci investovat udržitelně',
		profileDetail: {
			title: 'Prozkoumejte ESG portfolia',
		},
		fees: {
			title: 'Poplatek',
			text: '<0>Výše poplatku se počítá z\u00a0hodnoty portfolia a\u00a0jednou měsíčně se strhne 1/12 ročního poplatku. V\u00a0ceně získáváte:</0>',
			list: '<0>zpracování <0>vkladů a\u00a0výběrů</0> v\u00a0neomezeném množství</0>'
				+ '<0>neomezený počet <0>nákupů a\u00a0prodejů ETF</0></0>'
				+ '<0><1>rebalancování</1> (pokud si ho nechcete dobrovolně vypnout)</0>'
				+ '<0>měnové konverze EURO↔CZK za <0>aktuální tržní kurz</0></0>'
				+ '<0><0>daňové podklady</0> pro případné podání daňového přiznání</0>'
				+ '<0>zákaznickou podporu na chatu, e-mailu i telefonu</0>',
		},
		benefits: {
			title: 'Proč investovat udržitelně?',
			list: [
				'<0>Správné hodnoty</0>'
				+ '<1>Chcete investovat do společností, které mají dobré finanční výsledky, a\u00a0zároveň přispívají '
				+ '<0>k\u00a0udržitelnější budoucnosti</0>? Investujte do odpovědných portfolií sestavených z\u00a0ETF s\u00a0nejlepším <1>ESG ratingem</1>.</1>',
				'<0>Výnosy</0>'
				+ '<1>Udržitelná ETF v\u00a0poslední době <2>porážejí ta klasická</2>. ESG dlouhodobě zlepšuje finanční výsledky firem. '
				+ 'ETFka v\u00a0našich portfoliích navíc nevyplácí, ale <0>reinvestují dividendy</0> –\u00a0a\u00a0tím roste jejich hodnota.</1>',
				'<0>Nižší rizika</0>'
				+ '<1>ESG zohledňuje <0>rizika, která běžná finanční analýza neřeší</0>, přitom mohou ovlivnit výkonnost celé firmy '
				+ 'i\u00a0hodnotu vašeho portfolia. Navíc se ukazuje, že odpovědné investice <3>dlouhodobě méně kolísají</3>.</1>',
			],
		},
	},
	pension: {
		title: 'Penzijní investiční účet (DIP)',
		text: 'Zařiďte si stáří po svém! Nespoléhejte na stát a\u00a0řekněte sbohem drahému penzijku s\u00a0mizernými výnosy. Odkládejte si na důchod '
			+ 'snadno, s\u00a0možností příspěvků od zaměstnavatele, <0>daňovým zvýhodněním a\u00a0sníženým poplatkem</0> oproti našim běžným portfoliím.',
		button: 'Chci bohatší důchod',
		profileDetail: {
			title: 'Prozkoumejte penzijní portfolia',
		},
		fees: {
			title: 'Poplatek',
			text: '<0>Výše poplatku se počítá z hodnoty portfolia a jednou měsíčně se strhne 1/12 ročního poplatku. V ceně získáváte:</0>',
			list: '<0>zpracování <0>vkladů a\u00a0výběrů</0> v\u00a0neomezeném množství</0>'
				+ '<0>neomezený počet <0>nákupů a\u00a0prodejů ETF</0></0>'
				+ '<0><1>rebalancování</1> (pokud si ho nechcete dobrovolně vypnout)</0>'
				+ '<0>zpracování <0>příchozích dividend</0> (týká se klasických portfolií)</0>'
				+ '<0>měnové konverze EURO↔CZK za <0>aktuální tržní kurz</0></0>'
				+ '<0><0>daňové podklady</0> pro případné podání daňového přiznání</0>'
				+ '<0>zákaznickou podporu na chatu, e-mailu i telefonu</0>',
		},
		terms: {
			title: 'Podmínky',
			text: '<0>Limity pro věk a počet portfolií</0>'
				+ '<1>Už jste oslavili <0>18. narozeniny</0>? Pak vám v otevření penzijního účtu od Fondee nic nebrání '
				+ 'a\u00a0směle do toho! Můžete si založit <0>jedno penzijní portfolio</0>, díky kterému investujete na své stáří '
				+ 's\u00a0<0>ještě výhodnějším poplatkem</0> oproti našim běžným portfoliím.</1>'
				+ '<0>Výběr a\u00a0přesun prostředků</0>'
				+ '<1>Penzijní portfolio slouží pro <0>dlouhodobé investování</0>. Peníze proto můžete bezplatně vybrat '
				+ 'a\u00a0začít si je užívat <0>po vašich 60.\u00a0narozeninách</0> za předpokladu, že uběhlo alespoň <0>10\u00a0let '
				+ '(120\u00a0měsíců) od založení penzijního účtu</0>. Je na vás, jestli si necháte rovnou vyplatit celou částku, nebo '
				+ 'ji vyčerpáte postupně a\u00a0část necháte dál zhodnocovat. Přesun peněz mezi penzijním a\u00a0jinými Fondee portfolii však není možný.</1>'
				+ '<0>Daňové úspory a\u00a0příspěvky od zaměstnavatele</0>'
				+ '<1>Náš penzijní účet funguje jako <1>dlouhodobý investiční produkt</1> <0>(DIP)</0>\u00a0–\u00a0nový '
				+ 'státem podporovaný nástroj pro zajištění na stáří. Do penzijního portfolia ve Fondee vám díky tomu může přispívat '
				+ 'zaměstnavatel podobně jako do klasického penzijka. Vklady na DIP si navíc odečtete od základu daně, a\u00a0ušetříte '
				+ 'tak <0>až 7\u00a0200\u00a0Kč za rok</0>.</1>'
				+ '<0>Předčasný výběr a\u00a0zrušení portfolia</0>'
				+ '<1>Kdybyste peníze potřebovali dříve než v\u00a060 letech a\u00a0před 10.\u00a0výročím smlouvy, můžete penzijní '
				+ 'portfolio <0>předčasně zrušit</0>. Tím však dojde k\u00a0porušení podmínky zvýhodněného poplatku, proto ho '
				+ 'dopočítáme <0>do naší běžné výše 1,09\u00a0% ročně</0> a\u00a0rozdíl odečteme od vyplacené částky.</1>'
				+ '<1>Pokud penzijní účet využijete jako dlouhodobý investiční produkt, budete čerpat daňové výhody a\u00a0rozhodnete '
				+ 'se ho předčasně zrušit, bude třeba <0>podat daňové přiznání a\u00a0tyto úspory dodanit</0> podobně jako '
				+ 'u\u00a0penzijního spoření nebo připojištění.</1>',
		},
		benefits: {
			title: 'Proč investovat na důchod s Fondee?',
			list: [
				'<0>Zajištění na stáří</0>'
				+ '<1>Už taky nevěříte pohádkám o\u00a0státním důchodu a\u00a0víte, že <5>spoření inflaci nepřepere</5>? Je čas '
				+ 'vzít budoucnost do vlastních rukou. Zajistěte si klidný důchod a\u00a0začněte investovat –\u00a0<0>s\u00a0námi to zvládnete</0>.</1>',
				'<0>Nižší poplatky</0>'
				+ '<1>Máme radost, že si chcete dopřát bohatší důchod. Abychom vás v\u00a0tom podpořili, u\u00a0penzijních '
				+ 'portfolií nabízíme <0>zvýhodněný poplatek 0,61\u00a0%</0> ročně (o\u00a00,5\u00a0% nižší než u ostatních Fondee investic!).</1>',
				'<0>Dlouhodobý investiční produkt</0>'
				+ '<1>Vyměňte drahé penzijní připojištění s\u00a0mizernými výnosy za penzijní účet od Fondee. Funguje '
				+ 'jako <6>dlouhodobý investiční produkt</6> s\u00a0<0>daňovým zvýhodněním</0> a\u00a0možností <0>příspěvků od zaměstnavatele</0>.</1>',
			],
		},
	},
	student: {
		title: 'Investice pro studenty',
		text: 'Investujte už při škole přes Fondee účet pro studenty. Zhodnocujte své úspory chytře v\u00a0ETF portfoliích '
			+ '<0>se sníženým poplatkem</0>, možností příspěvků od blízkých a\u00a0100\u00a0Kč bonusem za\u00a0ISIC.',
		button: 'Chci začít investovat',
		profileDetail: {
			title: 'Prozkoumejte studentská portfolia',
		},
		fees: {
			title: 'Poplatek',
			text: '<0>Výše poplatku se počítá z\u00a0hodnoty portfolia a\u00a0jednou měsíčně se strhne 1/12 ročního poplatku. V\u00a0ceně získáváte:</0>',
			list: '<0>zpracování <0>vkladů a\u00a0výběrů</0> v\u00a0neomezeném množství</0>'
				+ '<0>neomezený počet <0>nákupů a\u00a0prodejů ETF</0></0>'
				+ '<0><1>rebalancování</1> (pokud si ho nechcete dobrovolně vypnout)</0>'
				+ '<0>zpracování <0>příchozích dividend</0> (týká se klasických portfolií)</0>'
				+ '<0>měnové konverze EURO↔CZK za <0>aktuální tržní kurz</0></0>'
				+ '<0><0>daňové podklady</0> pro případné podání daňového přiznání</0>'
				+ '<0>zákaznickou podporu na chatu, e-mailu i telefonu</0>',
		},
		terms: {
			title: 'Podmínky',
			text: '<0>Postup založení studentského účtu</0>'
				+ '<1>Je vám <0>mezi 18 a\u00a026\u00a0lety</0>? Pojďte s\u00a0námi skrze <0><0>ETFka</0></0> investovat do '
				+ 'akcií a\u00a0dluhopisů z\u00a0celého světa, online a\u00a0se sníženým poplatkem. Připravte si <0>číslo své ISIC karty</0>'
				+ ' nebo <0>potvrzení o\u00a0studiu</0> s\u00a0razítkem školy a\u00a0nahrajte ho:</1>'
				+ '<1><2>'
				+ '<0><0>Do příslušné kolonky v\u00a0klientské zóně</0>, pokud už <0>jste</0> klientem Fondee '
				+ '(máte u\u00a0nás účet se standardním poplatkem) – zařídíme převod vašeho účtu na\u00a0studentský.</0>'
				+ '<0><0>V\u00a0průběhu <0>registrace</0></0>, pokud ještě <0>nejste</0> klientem Fondee (nemáte u\u00a0nás účet).</0>'
				+ '</2></1>'
				+ '<1>Náš tým zkontroluje zadané údaje a\u00a0<0>aktivuje vám výhody</0>. Studium <0>prokážete pouze jednou</0> '
				+ 'a\u00a0studentský účet pak můžete využívat <0>až do svých 26\u00a0let</0>. Pokud jste vyplnili číslo ISIC, '
				+ 'získáte navíc <0>bonus 100\u00a0Kč do začátku!</0> A\u00a0to je dobrý deal, no ne?</1>'
				+ '<0>Vklady a\u00a0příspěvky od rodičů a\u00a0příbuzných</0>'
				+ '<1>Ve Fondee máte na\u00a0výběr ze 7\u00a0portfolií od konzervativních až po odvážné <0>v\u00a0klasické nebo <2>udržitelné ESG variantě</2></0> '
				+ '–\u00a0otevřít si můžete až 5\u00a0různých portfolií najednou. Pak posíláte vklady <0>v\u00a0libovolné frekvenci a\u00a0výši</0>. '
				+ 'Nejvíc se vyplácí investovat dlouhodobě a\u00a0pravidelně, ale když se vám někdy podaří odložit více a\u00a0jindy méně, nic se neděje. '
				+ 'Každý podle svých možností, <0>nejdůležitější je začít</0>.</1>'
				+ '<1>Do investování v\u00a0rámci studentského účtu můžete <0>zapojit i\u00a0rodiče a\u00a0příbuzné</0> '
				+ '(dost možná o\u00a0tom uslyší radši než o\u00a0nákupu dalších bot nebo iPhonu). Mohou vám posílat příspěvky '
				+ '<0>přímo do Fondee</0>, jen jim předáte <0>platební údaje</0> uvedené v\u00a0klientské zóně a\u00a0informaci, ať '
				+ 'do poznámky k\u00a0platbě vloží <0>číslo vašeho portfolia</0>, aby se peníze správně připsaly.</1>'
				+ '<1><2><0>Každý, kdo <0>je klientem Fondee</0>, má možnost vám z\u00a0bankovního účtu propojeného s\u00a0Fondee '
				+ 'posílat vklady <0>v neomezené výši</0>.</0>'
				+ '<0>Všichni ostatní vám mohou přispět <0>až 23\u00a0000\u00a0Kč za rok</0> z\u00a0jednoho čísla účtu.</0></2></1>'
				+ '<0>Výběry peněz a\u00a0přechod na standardní účet</0>'
				+ '<1>Díky účtu pro studenty až do 26\u00a0let investujete se <0>zvýhodněným poplatkem</0> '
				+ '(0,61\u00a0%\u00a0ročně) a\u00a0<0>neomezenými výběry</0>. Chápeme, že v\u00a0mládí není lehké našetřit '
				+ 'dostatečnou <0><3>finanční rezervu</3></0> a\u00a0během studia se může cokoli přihodit, proto výběry peněz '
				+ 'nijak nelimitujeme a\u00a0neovlivní ani výši poplatku. Jen k\u00a0nim přistupujte s\u00a0rozmyslem '
				+ '–\u00a0investicím se nejlépe daří, když pro vás mohou <0>pracovat dlouhodobě</0>.</1>'
				+ '<1>Jakmile oslavíte 26.\u00a0narozeniny, přepne se váš Fondee účet <0>ze studentského na\u00a0standardní</0> '
				+ '(s\u00a0poplatkem 1,09\u00a0%\u00a0ročně).</1>',
		},
		benefits: {
			title: 'Proč investovat jako student s Fondee?',
			list: [
				'<0>Čas je na vaší straně</0>'
				+ '<1>Užívejte si studium, nechte pracovat <7>složené úročení</7>. Díky němu můžete mít už za pár let '
				+ 'pěkný balík (a\u00a0co teprve za desítky let!). Navíc si od mládí vybudujete <0>zdravé finanční návyky</0>. '
				+ 'Zbývá jen první krok –\u00a0začít.</1>',
				'<0>Halíře dělají talíře</0>'
				+ '<1><8>Student life is tough enough</8>. Proto na\u00a0účtech pro mladé nabízíme '
				+ '<0>snížený poplatek 0,61\u00a0%\u00a0ročně</0> místo běžných 1,09\u00a0%. Určitě nemusíte hned odkládat '
				+ 'tisíce, i\u00a0malé částky a příspěvky od blízkých se počítají.</1>',
				'<0>Není to žádná věda</0>'
				+ '<1>Na investování není nikdy moc brzy a\u00a0nepotřebujete ani titul z\u00a0ekonomie. Stačí mít mobil '
				+ 'a\u00a0<0>chuť to vyzkoušet</0>. Fondee je intuitivní appka, mluví lidsky a\u00a0kdyby něco, jsme tu pro vás '
				+ '<0>na\u00a0chatu i\u00a0na\u00a0sítích</0>.</1>',
			],
		},
	},
	list: {
		title: 'Ostatní produkty',
		classic: {
			title: 'Investice na míru',
			text: 'Jeden vklad = stovky akcií a\u00a0dluhopisů z\u00a0celého světa. Investujte do ETF online, jednoduše a\u00a0srozumitelně.',
			button: 'Více informací',
		},
		esg: {
			title: 'Udržitelné investice',
			text: 'Hodnoty a\u00a0výnosy si rozumí. Investujte udržitelně do portfolií, která v\u00a0posledních letech porážejí klasické investice.',
			button: 'Více informací',
		},
		child: {
			title: 'Investice pro děti',
			text: 'Nenechte úspory pro děti ležet v\u00a0bance, kde ztrácejí hodnotu. Založte investiční portfolio se sníženým poplatkem.',
			button: 'Více informací',
		},
		pension: {
			title: 'Penzijní investiční účet (DIP)',
			text: 'Zajistěte se na stáří díky chytrým investicím s\u00a0nízkým poplatkem. Nespoléhejte na stát a\u00a0řekněte sbohem drahým fondům.',
			button: 'Více informací',
		},
		student: {
			title: 'Investice pro studenty',
			text: 'Na investování není nikdy moc brzy! Začněte už při škole –\u00a0otevřete si studentský účet se '
				+ 'zvýhodněným poplatkem a\u00a0bonusem za ISIC.',
			button: 'Více informací',
		},
	},
};

const productAccount = {
	title: 'Historická výnosnost',
	toggleOptions: [
		{
			label: 'Hodnota',
			value: 'value',
		},
		{
			label: 'Zhodnocení',
			value: 'evaluation',
		},
		{
			label: 'Zhodnocení v\u00a0%',
			value: 'evaluationPercent',
		},
	],
	note: 'Historický výnos vychází z\u00a0průměrného zhodnocení ETF zahrnutých v\u00a0portfoliu (nebo z\u00a0vývoje jejich '
		+ 'podkladových indexů) za vybrané období, v\u00a0českých korunách a\u00a0před započtením poplatků. Nezapomeňte, '
		+ 'že historické zhodnocení není zárukou budoucích výnosů. Dalši informace o\u00a0rizicích investování se dozvíte '
		+ 'na stránce <0>Jak to funguje</0>.',
};

const productSelect = {
	standard: 'Klasický/ESG',
	child: 'Dětský účet',
	pension: 'Penzijní (DIP)',
	student: 'Studentský',
	studentBadge: 'Student',
};

const profileAvatar = {
	faces: {
		esg: {
			0: 'Profilový obrázek 1',
			1: 'Profilový obrázek 2',
			2: 'Profilový obrázek 3',
			3: 'Profilový obrázek 4',
			4: 'Profilový obrázek 5',
			5: 'Profilový obrázek 6',
			6: 'Profilový obrázek 7',
			7: 'Profilový obrázek 8',
			8: 'Profilový obrázek 9',
			9: 'Profilový obrázek 10',
			10: 'Profilový obrázek 11',
			11: 'Profilový obrázek 12',
			12: 'Profilový obrázek 13',
			13: 'Profilový obrázek 14',
		},
		classic: {
			0: 'Profilový obrázek 1',
			1: 'Profilový obrázek 2',
			2: 'Profilový obrázek 3',
			3: 'Profilový obrázek 4',
			4: 'Profilový obrázek 5',
			5: 'Profilový obrázek 6',
			6: 'Profilový obrázek 7',
			7: 'Profilový obrázek 8',
			8: 'Profilový obrázek 9',
			9: 'Profilový obrázek 10',
			10: 'Profilový obrázek 11',
			11: 'Profilový obrázek 12',
			12: 'Profilový obrázek 13',
			13: 'Profilový obrázek 14',
		},
	},
};

const referrals = {
	title: 'Doporučte Fondee výměnou za dobrý pocit!',
	text: 'Jestli vám přijde Fondee jako dobrá služba, budeme moc rádi, když nás doporučíte. '
		+ 'Nic vám za to nedáme. Ale když někdo využije váš referenční kód, tak ho odměníme '
		+ '<0>3\u00a0měsíci bez poplatků</0>, aby si mohl Fondee v\u00a0klidu vyzkoušet.',
	code: 'Referenční kód',
	copyLink: 'Zkopírovat odkaz na web Fondee s předvyplněným referenčním kódem',
	form: {
		email: {
			label: 'E-mail, kam doporučení pošleme',
			error: 'Zapomněli jste vyplnit emailovou adresu.',
		},
		message: {
			label: 'Vaše zpráva',
			error: 'Zapomněli jste vyplnit zprávu.',
		},
		messageText: 'Gratulujeme, máte ty nejlepší kamarády! Kdo jiný by Vám mohl doporučit investování u\u00a0Fondee?\n'
			+ 'Navíc, když zadáte referenční kód {{ code }} při registraci na www.fondee.cz a\u00a0založíte si '
			+ 'investiční účet, odpustíme Vám poplatky za první 3\u00a0měsíce! A\u00a0to je přece skvělá příležitost, '
			+ 'jak si službu bez starostí vyzkoušet, no ne?\n'
			+ 'Kdybychom Vám mohli v\u00a0rámci investování u\u00a0Fondee s\u00a0čímkoliv pomoct, určitě se ozvěte '
			+ 'Irys a\u00a0Kasie na info@fondee.cz!',
		send: 'Odeslat',
		success: 'Děkujeme, že sdílíte svůj referenční kód. Pošlete ho i\u00a0dalším kamarádům.',
	},
	emailStats: {
		title: 'E-mail s referenčním kódem odeslán na:',
		used: 'Kód využit!',
	},
	socialShare: {
		facebook: 'Fondee - s bonus kódem. Začněte investovat díky bonusu od kamaráda.',
		twitter: 'Tohle je můj referenční kód u Fondee: {{ code }}. Proč ho sdílím? Protože když si přes něj vytvoříte '
			+ 'investiční účet u Fondee, dostanete bonus: 3 měsíce budete mít u Fondee bez poplatků. A to se vyplatí!',
		sendVia: 'Poslat přes',
	},
	stats: {
		title: 'Počet klientů, kteří se registrovali\ns\u00a0vaším referenčním kódem:',
	},
};

const register = {
	title: 'Chci začít investovat',
	text: 'K investování s Fondee vede jen pár jednoduchých kroků',
	button: 'Registrovat se',
	info: 'Vyplňte vaše osobní údaje. Údaje dítěte v\u00a0případě dětského účtu se vyplňují v\u00a0dalším kroku registrace.',
	social: {
		or: 'nebo',
		title: 'Registrovat se přes:',
	},
	emailAlias: {
		title: 'Přihlašte se pro propojení účtu',
		text: 'Pokud již máte existující účet, můžete ho propojit.',
		button: 'Propojit účet',
	},
	privateModal: {
		title: 'Pozor, přihlašujete se přes anonymní Apple email',
		text: 'Jste si jistí, že chcete vytvořit nový účet přes anonymní Apple email?',
		button: 'Propojit účet',
		close: 'Účet nemám, chci ho vytvořit. <0>Registrovat se</0>.',
	},
};

const resetPassword = {
	title: 'Změna hesla',
	form: {
		button: 'Změnit heslo',
	},
};

const scrollToTop = {
	title: 'Zpět nahoru',
};

const signAgreement = {
	title: 'Podpis nové smlouvy a\u00a0doplnění informací',
	text: 'Protože u J&T\u00a0banky budeme obchodovat pod naší novou OCP\u00a0licencí, rádi bychom Vás požádali '
		+ 'o\u00a0vyplnění upraveného dotazníku a\u00a0podpis nové smlouvy. Víme, že je to otrava, ale zákon nám bohužel '
		+ 'nedává na\u00a0výběr.',
	info: 'Pokud máte jakékoliv otázky, neváhejte napsat Karol a\u00a0Davidovi na\u00a0<0>$t(email.address)</0>',
	done: 'Děkujeme za vyplnění dotazníku a\u00a0podpis nové smlouvy.\nNic dalšího od Vás v\u00a0tuto chvíli '
		+ 'nepotřebujeme. Jsme rádi, že jste s\u00a0námi! Vaše\u00a0Fondee',
};

const signNewAgreement = {
	title: 'Podpis nové smlouvy',
	text: {
		standard: 'Pokud máte zájem o\u00a0naše standardní produkty, podepište prosím novou smlouvu. Smlouva a její '
			+ 'přílohy upravují základní náležitosti jako založení účtu, průběh investování a poplatky za služby.',
		pension: 'Naše penzijní portfolio funguje jako dlouhodobý investiční produkt (DIP). Připravili jsme pro Vás '
			+ 'novou smlouvu, která upravuje podmínky fungování DIP a\u00a0kterou můžete v\u00a0případě potřeby '
			+ 'předložit svému zaměstnavateli.',
	},
	child: {
		title: 'Podpis dětské smlouvy',
		description: 'Na této stránce můžete podepsat novou smlouvu k\u00a0dětskému účtu v\u00a0případě '
			+ 'změny v\u00a0kontaktních nebo osobních údajích.',
	},
	info: 'Pokud máte jakékoliv otázky, neváhejte nám napsat na\u00a0<0>$t(email.address)</0>.',
	contractTypes: {
		standard: 'Moje standardní smlouva',
		pension: 'Moje penzijní smlouva',
	},
};

const studentConfirmation = {
	account: {
		title: 'Zažádat o\u00a0studentský účet',
		text: 'Studujete na\u00a0střední nebo vysoké škole a\u00a0je vám do 26\u00a0let? Máte možnost '
			+ 'zažádat o\u00a0převedení na\u00a0Studentský účet s\u00a0nižšími poplatky. Stačí nahrát platnou ISIC kartu '
			+ 'nebo potvrzení o\u00a0studiu. ISIC schválíme hned, potvrzení o\u00a0studiu schválíme do několika '
			+ 'pracovních dnů. Více informací v\u00a0sekci Časté dotazy.',
		verification: {
			title: 'Čekáme na ověření potvrzení o\u00a0studiu',
			text: 'Ověřujeme platnost Vašeho potvrzení o\u00a0studiu, což může trvat pár pracovních dnů. '
				+ 'V\u00a0případě jakýchkoli dotazů nás neváhejte kontaktovat na\u00a0chatu nebo na\u00a0<0>info@fondee.cz</0>.',
		},
	},
	onboarding: {
		text: 'Zde nahrajte sken nebo fotku platného potvrzení o\u00a0studiu.',
		subText: 'Potvrzení o\u00a0studiu budeme muset ověřit, což obvykle zabere několik dnů. Registraci ale '
			+ 'můžete v\u00a0klidu dokončit. Zatím vám otevřeme standardní účet a\u00a0jakmile bude potvrzení o\u00a0studiu '
			+ 'ověřeno, převedeme vám standardní účet na\u00a0studentský.',
	},
	success: 'Váš účet byl změněn na studentský',
	toggle: {
		ISIC: 'ISIC',
		confirmation: 'Potvrzení o\u00a0studiu',
	},
	upload: {
		file: 'Nahrát',
		confirm: 'Odeslat',
		list: {
			formats: '<0>Podporované formáty:</0>'
				+ '<1>PNG, JPG, PDF</1>',
			size: '<0>Velikost:</0>'
				+ '<1>Maximální velikost 4MB</1>',
		},
		wrongFormat: 'Špatný formát potvrzení o studiu',
	},
	manual: {
		code: 'ISIC',
		confirm: 'Odeslat',
	},
};

const survey = {
	close: 'Zavřit',
	other: 'Něco jiného:',
	otherPlaceholder: 'Vaše odpověď…',
	validateError: 'Vyplňte prosím alespoň jednu možnost.',
	submit: 'Odeslat',
	thanks: 'Děkujeme!',
};

const taxes = {
	intro: '<0>Otázka zdanění investic může být zapeklitá. <0>Fondee pro Vás v\u00a0klientské zóně založilo '
		+ 'samostatnou daňovou sekci</0>, aby Vám pomohlo se v\u00a0této problematice lépe zorientovat.</0>'
		+ '<0><0>Naleznete tu data z\u00a0obchodování v\u00a0uplynulých letech</0> zpracovaná tak, aby pro Vás byla co '
		+ 'nejlepším podkladem při podávání daňového přiznání (máte-li povinnost ho podat).</0>',
	link: 'Fondee daňový výpis za rok\u00a02024',
	linkDip: 'Potvrzení o vkladech na DIP za\u00a02024',
	title: '<0>Abychom Vám pomohli zjistit, jestli musíte nebo nemusíte podávat daňové přiznání, a\u00a0kam do něj '
		+ 'případně zařadit Vaše příjmy z\u00a0investování s\u00a0Fondee, připravili jsme pro Vás následující '
		+ 'dotazník.</0>',
	text: '<0>Pokud Vás zajímá, jak obecně funguje zdanění investic, můžete se o\u00a0tom více dozvědět <0>zde</0>.</0>'
		+ '<0>O\u00a0zdanění příjmů z\u00a0investování s\u00a0Fondee se více dozvíte v\u00a0rozšířené sekci '
		+ '<1>\u201eNejčastější\u00a0dotazy\u201c</1> níže. Jenom upozorňujeme, že všechny informace jsou relevantní '
		+ 'pouze pro daňové rezidenty České republiky.</0>'
		+ '<0>O danění investic máme také <2>článek</2> na našem blogu.</0>',
	infoLink: '',
	modal: {
		title: 'Jak funguje zdanění investic?',
		text: '<0>Velmi velmi zjednodušeně se na to můžeme podívat takto. Příjmy z\u00a0investic mohou být od daně '
			+ 'osvobozené (není třeba je danit), nebo zdanitelné. Jsou-li Vaše příjmy z\u00a0investic zdanitelné, '
			+ 'stále ještě nemusíte nic danit, pokud se vejdete do limitu pro povinné podání daňového přiznání, který '
			+ 'je:</0>'
			+ '<1>'
			+ '<0>u\u00a0zaměstnanců 20\u00a0000\u00a0Kč -\u00a0jde o\u00a0součet všech zdanitelných příjmů mimo '
			+ 'zaměstnání, a</0>'
			+ '<0>u\u00a0všech ostatních 50\u00a0000\u00a0Kč -\u00a0jde o\u00a0součet veškerých zdanitelných '
			+ 'příjmů.</0>'
			+ '</1>'
			+ '<0>V\u00a0rámci obchodování s\u00a0Fondee Vás mohou potkat 3 různé druhy příjmů.</0>'
			+ '<2><0>Jaké?</0></2>'
			+ '<2><0>1.</0> Příjem z\u00a0dividend / kapitálový příjem -\u00a0je vždy zdanitelný</2>'
			+ '<2><0>2.</0> Příjem z\u00a0prodeje cenných papírů (ETF) -\u00a0hlavním kritériem je, kolik '
			+ 'peněz jste získali prodejem cenných papírů v\u00a0uplynulém roce (nikoliv zisků).</2>'
			+ '<3><0>a.</0> Pokud je příjem nižší než 100\u00a0000\u00a0Kč za všechny cenné papíry (v\u00a0součtu '
			+ 's\u00a0dalšími prodeji mimo Fondee, tj. mohou sem spadat i\u00a0prodeje Vašich akcií, dluhopisů, '
			+ 'podílových listů, atd., a\u00a0do celkové částky se počítají i\u00a0osvobozené prodeje na základě '
			+ 'časového testu, viz.bod c.), pak je osvobozeno a\u00a0nic nedaníte.</3>'
			+ '<3><0>b.</0> Pokud je příjem vyšší než 100\u00a0000\u00a0Kč, pak je zdanitelný, ale daníte zisk, neboli '
			+ 'rozdíl mezi prodejní a\u00a0nákupní cenou za všechny cenné papíry, které jste v\u00a0uplynulém roce '
			+ 'prodal/a. Pokud je výsledkem ztráta, uplatnit ji nemůžete.</3>'
			+ '<3><0>c.</0> Pokud čas mezi nákupem a\u00a0prodejem cenného papíru přesáhl 3\u00a0roky (tzv. časový '
			+ 'test), pak je tento prodej osvobozen od daně (tzn. zisk se nedaní, případná ztráta se neuplatňuje).</3>'
			+ '<2><0>3.</0> Další příjem -\u00a0za uplynulý rok jsme Vám mohli na investiční účet připsat mimořádný '
			+ 'bonus, například za doporučení jiného klienta. Ten se daní jako příjem z příležitostných činností, '
			+ 'přičemž pokud je součet všech Vašich příležitostných příjmů nižší než 50\u00a0000 Kč, pak jsou '
			+ 'osvobozené a\u00a0nedaní se.</2>',
	},
};

const taxQuestionnaire = {
	start: 'Spustit dotazník',
	redo: 'Vyplnit dotazník znovu',
	introText: {
		1: {
			text: 'Děkujeme za vyplnění dotazníku! Na\u00a0základě Vašich obchodních výsledků při investování s\u00a0Fondee '
				+ 'v\u00a0uplynulém roce jste překročil/a hranici osvobození u\u00a0příjmů z\u00a0prodeje cenných papírů '
				+ 'i\u00a0hranici osvobození u\u00a0příjmů příležitostných a <0>máte povinnost podat daňové přiznání</0>. '
				+ 'Vaše kapitálové i\u00a0ostatní příjmy jsou zobrazeny v\u00a0přiloženém podkladu pro přípravu daňového přiznání.',
			note: '',
		},
		2: {
			text: 'Na základě Vašich obchodních výsledků při investování s\u00a0Fondee v\u00a0uplynulém roce '
				+ 'jste překročil/a hranici osvobození u\u00a0příjmů z\u00a0prodeje cenných papírů a Vaše celkové '
				+ 'zdanitelné příjmy překročily hranici 50\u00a0000\u00a0Kč. <0>Máte povinnost podat daňové přiznání.</0>',
			note: 'Prosím, pokračujte v\u00a0dotazníku, abychom pro Vás mohli připravit podklad pro přípravu daňového přiznání.',
		},
		4: {
			text: 'Na základě Vašich obchodních výsledků při investování s\u00a0Fondee v\u00a0uplynulém roce <0>máte povinnost '
				+ 'podat daňové přiznání</0>. Překročil/a jste hranici zdanitelných příjmů ve výši 50\u00a0000\u00a0Kč.',
			note: 'Prosím, pokračujte v\u00a0dotazníku, abychom pro Vás mohli připravit podklad pro přípravu daňového přiznání.',
		},
		5: {
			text: 'Na základě Vašich obchodních výsledků při investování s\u00a0Fondee v\u00a0uplynulém roce <0>můžete mít '
				+ 'povinnost podat daňové přiznání</0>. Další průběh dotazníku Vám dá konečnou odpověď.',
			note: 'Prosím, pokračujte v\u00a0dotazníku, abychom pro Vás mohli připravit podklad pro přípravu daňového přiznání.',
		},
		6: {
			text: 'Pouze na základě Vašich obchodních výsledků při investování s\u00a0Fondee v\u00a0uplynulém roce <0>nemáte '
				+ 'povinnost podat daňové přiznání</0>.',
			note: 'Prosím, pokračujte v\u00a0dotazníku, abychom pro Vás mohli připravit podklad pro přípravu daňového přiznání, '
				+ 'budete-li ho podávat z\u00a0jiných důvodů (například jste-li podnikatel nebo máte příjmy z nájmu).',
		},
	},
	text: {
		nonresident: 'Tímto pro Vás dotazník končí, neboť připravuje podklady pro podání daňového přiznání v\u00a0ČR. '
			+ 'Pokud jste daňovým rezidentem v\u00a0jiné zemi než je ČR, budete své příjmy z\u00a0investování s\u00a0Fondee '
			+ 'danit ve státě své daňové rezidence dle tamních předpisů. Fondee daňový výpis za uplynulý rok Vám k\u00a0tomu '
			+ 'poskytne potřebné informace.',
		commercial: 'Tímto pro Vás dotazník končí, neboť připravuje podklady pro podání daňového přiznání pro '
			+ 'soukromé investory. Vzhledem k\u00a0tomu, že jste zařadil/a své investiční portfolio u\u00a0Fondee do '
			+ 'obchodního majetku, zdaňujete je v\u00a0rámci své samostatné činnosti (dle §7 ZDP). Fondee daňový výpis '
			+ 'za uplynulý rok Vám v\u00a0sekci transakčních výpisů poskytne přehled o\u00a0realizovaných příjmech '
			+ 'a\u00a0souvisejících výdajích v uplynulém roce.',
		info: 'Ke kompletaci Vašeho daňového přiznání můžeme doporučit portál <0>www.mojedane.cz</0>, kde lze formulář daňového '
			+ 'přiznání vyplnit a\u00a0zároveň i\u00a0odeslat na finanční úřad elektronicky.',
		infoLink: 'https://www.mojedane.cz/',
	},
	resultText: {
		1: 'Děkujeme za vyplnění dotazníku! Na základě Vašich odpovědí lze usoudit, že Vaše příjmy z\u00a0investování '
			+ 's\u00a0Fondee patří mezi příjmy osvobozené, nebo nepřekračují limit pro podání daňového přiznání.',
		2: 'Děkujeme za vyplnění dotazníku! Na základě Vašich odpovědí jsme Vám připravili kompletní podklad pro '
			+ 'přípravu daňového přiznání, kde jsou zobrazeny Vaše kapitálové i\u00a0ostatní příjmy (příjmy z prodeje cenných '
			+ 'papírů i\u00a0příjmy příležitostné). Věnujte, prosím, pozornost i\u00a0souvisejícím poznámkám.',
		3: 'Děkujeme za vyplnění dotazníku! Na základě Vašich odpovědí jsme Vám připravili podklad pro '
			+ 'přípravu daňového přiznání, kde jsou zobrazeny Vaše kapitálové příjmy a\u00a0příjmy z\u00a0prodeje cenných papírů '
			+ '(příjmy příležitostné splňují limit pro osvobození 50\u00a0000\u00a0Kč).',
		4: 'Děkujeme za vyplnění dotazníku! Na základě Vašich odpovědí jsme Vám připravili podklad pro '
			+ 'přípravu daňového přiznání, kde jsou zobrazeny Vaše kapitálové příjmy a\u00a0příjmy z\u00a0prodeje cenných papírů '
			+ '(příjmy příležitostné splňují limit pro osvobození 50\u00a0000\u00a0Kč). Věnujte, prosím, '
			+ 'pozornost i\u00a0souvisejícím poznámkám.',
		5: 'Děkujeme za vyplnění dotazníku! Na základě Vašich odpovědí jsme Vám připravili podklad pro '
			+ 'přípravu daňového přiznání, kde jsou zobrazeny Vaše kapitálové příjmy a\u00a0příjmy příležitostné '
			+ '(příjmy z\u00a0prodeje cenných papírů splňují limit pro osvobození 100\u00a0000\u00a0Kč).',
		6: 'Děkujeme za vyplnění dotazníku! Na základě Vašich odpovědí jsme Vám připravili podklad pro '
			+ 'přípravu daňového přiznání, kde jsou zobrazeny Vaše kapitálové příjmy a\u00a0příjmy příležitostné '
			+ '(příjmy z\u00a0prodeje cenných papírů splňují limit pro osvobození 100\u00a0000\u00a0Kč). Věnujte, prosím, '
			+ 'pozornost i\u00a0souvisejícím poznámkám.',
		7: 'Děkujeme za vyplnění dotazníku! Na základě Vašich odpovědí jsme Vám připravili podklad pro '
			+ 'přípravu daňového přiznání, kde jsou zobrazeny Vaše kapitálové příjmy (příjmy z\u00a0prodeje cenných papírů '
			+ 'splňují limit pro osvobození 100\u00a0000\u00a0Kč a\u00a0příjmy příležitostné splňují limit pro '
			+ 'osvobození 50\u00a0000\u00a0Kč).',
		8: 'Děkujeme za vyplnění dotazníku! Na základě Vašich odpovědí jsme Vám připravili podklad pro '
			+ 'přípravu daňového přiznání, kde jsou zobrazeny Vaše kapitálové příjmy (příjmy z\u00a0prodeje cenných '
			+ 'papírů splňují limit pro osvobození 100\u00a0000\u00a0Kč a\u00a0příjmy příležitostné splňují limit pro '
			+ 'osvobození 50\u00a0000\u00a0Kč). Věnujte, prosím, pozornost i\u00a0souvisejícím poznámkám.',
	},
	download: 'Stáhnout tiskopis daňového přiznání',
	continue: 'Pokračovat na další krok',
};

const topUp = {
	currency: 'V\u00a0jaké měně chcete vklad poslat?',
	portfolio: 'Na\u00a0které portfolio chcete vložit peníze?',
	modal: {
		title: 'Chci vložit peníze',
		close: 'Zavřít',
	},
	qr: 'Platba QR kódem',
	payment: {
		title: 'Platbu zašlete na tento účet',
		account: {
			title: 'Číslo účtu',
			info: 'Účet brokera J&T Bank',
			iban: 'IBAN',
			swift: 'BIC',
		},
		note: 'Vložte zprávu pro příjemce',
		notePension: 'Vložte zprávu pro příjemce nebo specifický symbol',
		info: {
			owner: 'Jméno majitele účtu: Fondee\u00a0a.\u00a0s.',
			address: 'Adresa: Rumunská\u00a022/28, 120\u00a000 Praha\u00a02',
		},
		warning: {
			account_one: 'Platbu proveďte z\u00a0Vašeho účtu',
			account_few: 'Platbu je nutné provést z\u00a0jednoho z\u00a0těchto účtů',
			account_other: 'Platbu je nutné provést z\u00a0jednoho z\u00a0těchto účtů',
			accountChild: 'Platbu proveďte ze\u00a0svého ověřeného účtu, <0>pokud si nepřejete mít limit vkladů</0>.',
			transfer: '<0>Převod v\u00a0EUR</0> musí být zadán jako SEPA platba.',
			banks: 'Účet musí být veden na Vaše <0>vlastní jméno</0>, a\u00a0proto platby některých služeb třetích stran jako '
				+ 'jsou např. Wise, Revolut či Monese, které pro zasílání plateb používají sběrné účty, nemůžeme zpracovávat. '
				+ 'Chcete-li tyto služby pro investování s\u00a0Fondee používat, doporučujeme kontaktovat <1>info@fondee.cz</1>.',
			note: 'Pokud neuvedete poznámku pro příjemce, bude platba do\u00a02\u00a0dnů automaticky alokována k\u00a0primárnímu '
				+ 'portfoliu. Nastavení je možné změnit v\u00a0sekci Můj účet.',
			pension: 'V\u00a0případě <0>příspěvků od zaměstnavatele</0> přijímáme platby přímo z\u00a0účtu zaměstnavatele, '
				+ 'pokud je jako variabilní symbol (VS) uvedeno IČO zaměstnavatele a\u00a0jako poznámka pro příjemce nebo '
				+ 'specifický symbol (SS) je uvedeno unikátní číslo Vašeho penzijního portfolia DIP. '
				+ 'Jakmile je Vaše penzijní portfolio aktivní, můžete si v\u00a0sekci Dokumenty stáhnout Potvrzení pro '
				+ 'zaměstnavatele, které obsahuje platební údaje pro příspěvky na\u00a0DIP do Fondee.',
			employerNote: 'Pokud Vám Váš zaměstnavatel přispívá nebo bude přispívat na\u00a0penzijní portfolio v\u00a0rámci '
				+ 'DIP, přidejte údaje v\u00a0sekci Můj účet – Moji zaměstnavatelé přispívající do DIP.',
			child: '<0>Na dětská portfolia mohou neomezeně vkládat peníze rodiče, zákonní zástupci, další osoby a\u00a0děti '
				+ 'samotné, pokud mají ověřený bankovní účet u\u00a0Fondee. Vklady od dalších osob, jako jsou příbuzní nebo '
				+ 'známí, jsou limitovány částkou 23\u00a0000\u00a0Kč ročně na\u00a0jedno dítě. Připsání vkladu proběhne '
				+ 'na\u00a0základě uvedení unikátního čísla portfolia dítěte v\u00a0poznámce k\u00a0platbě.</0>',
		},
	},
	triggerButton: 'Chci vložit peníze',
	short: 'Vklad',
	employer: {
		open: 'Platební informace pro zaměstnavatele',
		modal: {
			title: 'Platební informace pro zaměstnavatele',
			send: 'Odeslat na e-mail',
			success: 'Odesláno',
		},
		payment: {
			note: 'Vložte do zprávy pro příjemce nebo jako variabilní symbol',
			warning: {
				note: 'Pokud zaměstnavatel neuvede správnou poznámku pro příjemce, variabilní symbol nebo '
					+ 'specifický symbol, nemusí být možné platbu vložit na portfolio. V takovém případě bude '
					+ 'platba vrácena na účet zaměstnavatele.',
			},
		},
	},
};

const tradingStates = {
	title: 'Obchodování',
	states: {
		initial: 'Čekáme na zainvestování úvodního vkladu',
		deposit: 'Vklad {{ sum }} je připravený k zainvestování {{ scheduled }}.',
		withdrawalPart: 'Výběr {{ sum }} je připraven ke zpracování {{ scheduled }}. Nejprve odprodáme ETF, pokud to bude '
			+ 'potřeba k\u00a0výběru, následně výběr převedeme do požadované měny, a\u00a0poté odešleme na Váš účet.\n'
			+ 'Vybranou částku obdržíte na svůj bankovní účet do\u00a0jednoho týdne.\n'
			+ 'Výběr můžete zrušit <0>níže na této stránce</0>.',
		withdrawalAll: 'Příkaz k\u00a0výběru všech prostředků je připravený ke\u00a0zpracování {{ scheduled }}. Nejprve '
			+ 'odprodáme ETF, následně výběr převedeme do požadované měny, a\u00a0poté odešleme na Váš účet.\n'
			+ 'Vybranou částku obdržíte na svůj bankovní účet do\u00a0jednoho týdne.\n'
			+ 'Výběr můžete zrušit <0>níže na této stránce</0>.',
		withdrawalCancel: 'Příkaz k\u00a0výběru všech prostředků je připravený ke\u00a0zpracování {{ scheduled }}. Nejprve '
			+ 'odprodáme ETF, následně výběr převedeme do požadované měny, a\u00a0poté odešleme na Váš účet.\n'
			+ 'Vybranou částku obdržíte na svůj bankovní účet do\u00a0jednoho týdne. '
			+ 'Následně zrušíme Váš investiční účet.\n'
			+ 'Výběr můžete zrušit <0>níže na této stránce</0>.',
		withdrawalTransferPart: 'Máte zadaný příkaz k\u00a0přesunu části portfolia do\u00a0jiného Vašeho portfolia. '
			+ 'Příkaz lze zrušit <0>níže na\u00a0této stránce</0>.',
		withdrawalTransferAll: 'Máte zadaný příkaz k\u00a0odprodeji ETF a\u00a0přesunu hotovosti do\u00a0jiného Vašeho portfolia. '
			+ 'Příkaz lze zrušit <0>níže na\u00a0této stránce</0>.',
		withdrawalPartClosed: 'Probíhá zpracování výběru {{ sum }}. Nejprve odprodáme ETF, pokud to bude potřeba '
			+ 'k\u00a0výběru, následně výběr převedeme do\u00a0požadované měny, \u00a0poté odešleme na Váš účet.\n'
			+ 'Vybranou částku obdržíte na svůj bankovní účet do\u00a0jednoho týdne.',
		withdrawalAllClosed: 'Probíhá výběr všech prostředků. Nejprve odprodáme ETF, následně výběr převedeme '
			+ 'do požadované měny, a\u00a0poté odešleme na Váš účet. Vybranou částku obdržíte na svůj bankovní '
			+ 'účet do\u00a0jednoho týdne.',
		withdrawalCancelClosed: 'Probíhá výběr všech prostředků. Nejprve odprodáme ETF, následně výběr převedeme '
			+ 'do požadované měny, a\u00a0poté odešleme na Váš účet.\n'
			+ 'Vybranou částku obdržíte na svůj bankovní účet do\u00a0jednoho týdne. Následně '
			+ 'zrušíme Váš investiční účet.',
		withdrawalTransferPartClosed: 'Probíhá zpracování přesunu části portfolia do\u00a0jiného vašeho portfolia. '
			+ 'Více informací naleznete <0>níže na této stránce</0>.',
		withdrawalTransferAllClosed: 'Probíhá odprodej ETF a\u00a0přesun hotovosti do\u00a0jiného vašeho portfolia. '
			+ 'Více informací naleznete <0>níže na této stránce</0>.',
		depositAndWithdrawal: 'Vklady a výběry plánované na stejný den budou vyřízeny zároveň.',
		profileChange: 'Máte zadanou změnu investičního profilu z\u00a0{{ profile }} na {{ planned }}. '
			+ 'V\u00a0nejbližší obchodní den provedeme vyvážení portfolia, aby odpovídalo novému profilu.',
		rebalancing: 'Příští rebalancing (úprava rozložení portfolia, aby odpovídalo zvolenému investičnímu profilu) '
			+ 'proběhne {{ date }}.',
		rebalancingSkip: 'Máte vypnutý rebalancing. To znamená, že vám budeme investovat přijaté vklady '
			+ 'a\u00a0odešleme výběry, ale nebudeme automaticky vyvažovat Vaše portfolio. Rebalancing si znovu můžete '
			+ 'zapnout <0>níže na této stránce</0>.',
		cash: 'Máte zadanou změnu poměru držené hotovosti z\u00a0{{ cash }} na {{ planned }}. V\u00a0nejbližší '
			+ 'obchodní den provedeme vyvážení portfolia, aby odpovídalo nově nastavenému poměru hotovosti.',
		cashOver: 'Máte upravený poměr držené hotovosti na {{ cash }}. Poměr můžete opět změnit <0>níže na této stránce</0>. '
			+ 'Změna se projeví při dalším rebalancingu.',
		allocatedDeposit: 'Přijali jsme Váš vklad ve výši {{ sum }}, který čeká na zainvestování. K\u00a0tomu dojde nejbližší '
			+ 'obchodní den, což je {{ date }}.',
	},
};

const transparentAccount = {
	pretitle: 'Hodnota skutečného portfolia Evy a Honzy',
	netGrowth: 'Pohyb hodnoty',
	netGrowthRatio: 'Pohyb hodnoty v\u00a0%',
	type: 'Typ investora',
	initialDeposit: 'Počáteční vklad',
	monthlyDeposit: 'Měsíční vklad',
	note: '',
};

const unsubscribe = {
	default: {
		title: 'Odhlásit z\u00a0marketingových mailů',
		text: 'Opravdu se chcete odhlásit z\u00a0marketingových mailů? Znovu přihlásit k\u00a0odběru '
			+ 'se můžete v\u00a0<0>klientské\u00a0zóně</0>.',
		button: 'Odhlásit',
	},
	success: {
		title: 'Odhlášeno',
		text: 'Pokud se rozhodnete znovu odebírat marketingové maily můžete si je aktivovat '
			+ 'v\u00a0klientské\u00a0zóně v\u00a0části <0>Můj\u00a0účet</0>.',
		button: 'Přihlásit se',
	},
};

export {
	account,
	ageCheckBlock,
	allPortfoliosCheck,
	anchors,
	appBanner,
	blog,
	blogPost,
	campaign,
	childAgeCheck,
	clientCheck,
	clientHeader,
	contact,
	contactForm,
	contactUs,
	cookieConsent,
	cookies,
	countryCheck,
	dashboard,
	documents,
	documentUrls,
	email,
	emailUs,
	emailVerification,
	employer,
	error,
	error404,
	faq,
	feesChart,
	feesComparison,
	feesHero,
	feesProducts,
	findOutInfoBox,
	footer,
	footerCta,
	forgotPassword,
	forms,
	general,
	header,
	historyChart,
	historyFilter,
	historyOverview,
	historyProgress,
	historyTransactions,
	homepageAbout,
	homepageHero,
	homepageJT,
	homepageProducts,
	homepageServices,
	howItWorksHero,
	howItWorksInvestment,
	howItWorksPrinciples,
	howItWorksRebalancing,
	howItWorksSchema,
	investing,
	investingInflation,
	investingPortfolio,
	investingTable,
	investingTips,
	investingTryout,
	investorProfileAvatar,
	investorProfileChoice,
	investorProfileDetail,
	investorProfilePreview,
	languageSwitcher,
	login,
	media,
	meta,
	newspaper,
	offers,
	onboarding,
	ourApproachAccount,
	ourApproachHero,
	ourApproachTeam,
	portfolio,
	portfolioComposition,
	portfolioFeesEtfs,
	portfolioFeesOverview,
	portfolioTable,
	privacyPolicy,
	products,
	productAccount,
	productSelect,
	profileAvatar,
	referrals,
	register,
	resetPassword,
	scrollToTop,
	signAgreement,
	signNewAgreement,
	studentConfirmation,
	survey,
	taxes,
	taxQuestionnaire,
	topUp,
	tradingStates,
	transparentAccount,
	unsubscribe,
};
