/* global document, window */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { useUserContext, useUserLogout } from '../../../contexts/UserContext';
import { fetchChildrenList, fetchOffers } from '../../../utils/api';
import isScreenWidthUp from '../../../utils/isScreenWidthUp';
import useFetchWithReload from '../../../utils/useFetchWithReload';
import useWindowSize from '../../../utils/useWindowSize';
import { ButtonLink, Logo } from '../../ui';
import AppBanner from '../AppBanner';
import InfoBox from '../InfoBox';
import LanguageToggle from '../LanguageToggle';
import PortfolioSelect from '../PortfolioSelect';

import styles from './Header.module.scss';

const navPublicList = (language) => [
	{
		label: 'howItWorks',
	},
	{
		label: 'products',
		subnav: [
			{
				label: 'classicProduct',
				icon:
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
		<g clipPath="url(#classic-nav-a)">
			<path fill="#F3920B" fillRule="evenodd" d="M15.838.738C7.418.738.568 7.588.568 16.006c0 8.418 6.85 15.27 15.27 15.27s15.27-6.85 15.27-15.27S24.258.738 15.838.738Zm0 27.239c-6.6 0-11.97-5.369-11.97-11.97 0-6.603 5.37-11.969 11.97-11.969 6.6 0 11.97 5.369 11.97 11.968 0 6.6-5.368 11.97-11.97 11.97Z" clipRule="evenodd" />
			<mask id="classic-nav-b" width="17" height="5" x="7" y="19" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
				<path fill="#F3920B" d="M23.687 19.551H7.19v2.113c0 .023.003.047.008.07.152.973 3.495 2.029 8.24 2.029s8.087-1.056 8.24-2.029a.307.307 0 0 0 .008-.07V19.55Z" />
			</mask>
			<g mask="url(#classic-nav-b)">
				<path fill="#F3920B" d="M23.687 19.551H7.19v2.113c0 .023.003.047.008.07.152.973 3.495 2.029 8.24 2.029s8.087-1.056 8.24-2.029a.307.307 0 0 0 .008-.07V19.55Z" />
				<path fill="#9C5B00" d="M7.086 19.601h2.905v4.226H7.086z" opacity=".9" />
				<path fill="#9C5B00" d="M9.991 19.601h2.641v4.226H9.991z" opacity=".7" />
				<path fill="#9C5B00" d="M12.632 19.601h2.641v4.226h-2.641z" opacity=".6" />
				<path fill="#9C5B00" d="M15.273 19.601h2.641v4.226h-2.641z" opacity=".4" />
				<path fill="#9C5B00" d="M17.913 19.601h2.905v4.226h-2.905z" opacity=".2" />
			</g>
			<path fill="#FCC10A" d="M23.687 19.551a.305.305 0 0 1-.009.07c-.152.973-3.494 2.03-8.24 2.03-4.744 0-8.087-1.057-8.239-2.03a.303.303 0 0 1-.008-.07c0-.054.011-.108.033-.162a.678.678 0 0 1 .144-.236c.75-.868 3.856-1.7 8.07-1.7 4.216 0 7.321.832 8.072 1.7a.68.68 0 0 1 .143.236.43.43 0 0 1 .034.162Z" />
			<mask id="classic-nav-c" width="18" height="5" x="8" y="15" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
				<path fill="#F3920B" d="M25.15 15.318H8.655v2.113c0 .024.002.047.008.07.152.973 3.495 2.03 8.24 2.03 4.744 0 8.087-1.057 8.239-2.03a.305.305 0 0 0 .008-.07v-2.113Z" />
			</mask>
			<g mask="url(#classic-nav-c)">
				<path fill="#F3920B" d="M25.15 15.318H8.655v2.113c0 .024.002.047.008.07.152.973 3.495 2.03 8.24 2.03 4.744 0 8.087-1.057 8.239-2.03a.305.305 0 0 0 .008-.07v-2.113Z" />
				<path fill="#9C5B00" d="M8.549 15.368h2.905v4.226H8.549z" opacity=".9" />
				<path fill="#9C5B00" d="M11.454 15.368h2.641v4.226h-2.641z" opacity=".7" />
				<path fill="#9C5B00" d="M14.095 15.368h2.641v4.226h-2.641z" opacity=".6" />
				<path fill="#9C5B00" d="M16.736 15.368h2.641v4.226h-2.641z" opacity=".4" />
				<path fill="#9C5B00" d="M19.377 15.368h2.905v4.226h-2.905z" opacity=".2" />
			</g>
			<path fill="#FCC10A" d="M25.15 15.319a.307.307 0 0 1-.008.07c-.152.973-3.495 2.029-8.24 2.029s-8.087-1.056-8.24-2.03a.305.305 0 0 1-.007-.07c0-.054.01-.108.033-.162a.679.679 0 0 1 .143-.236c.751-.868 3.857-1.7 8.072-1.7 4.215 0 7.32.832 8.07 1.7.07.08.12.156.144.236a.428.428 0 0 1 .033.163Z" />
			<mask id="classic-nav-d" width="17" height="5" x="7" y="11" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
				<path fill="#F3920B" d="M23.964 11.046H7.468v2.113c0 .024.003.047.008.07.152.973 3.495 2.029 8.24 2.029s8.088-1.056 8.24-2.029a.306.306 0 0 0 .008-.07v-2.113Z" />
			</mask>
			<g mask="url(#classic-nav-d)">
				<path fill="#F3920B" d="M23.964 11.046H7.468v2.113c0 .024.003.047.008.07.152.973 3.495 2.029 8.24 2.029s8.088-1.056 8.24-2.029a.306.306 0 0 0 .008-.07v-2.113Z" />
				<path fill="#9C5B00" d="M7.362 11.096h2.905v4.226H7.362z" opacity=".9" />
				<path fill="#9C5B00" d="M10.268 11.096h2.641v4.226h-2.641z" opacity=".7" />
				<path fill="#9C5B00" d="M12.909 11.096h2.641v4.226h-2.641z" opacity=".6" />
				<path fill="#9C5B00" d="M15.55 11.096h2.641v4.226H15.55z" opacity=".4" />
				<path fill="#9C5B00" d="M18.19 11.096h2.905v4.226H18.19z" opacity=".2" />
			</g>
			<path fill="#FCC10A" d="M23.964 11.046a.307.307 0 0 1-.009.07c-.151.974-3.494 2.03-8.24 2.03-4.744 0-8.087-1.056-8.239-2.03a.304.304 0 0 1-.008-.07c0-.054.011-.108.033-.162a.679.679 0 0 1 .144-.236c.75-.868 3.856-1.7 8.07-1.7 4.216 0 7.321.832 8.072 1.7.069.08.119.156.143.236a.428.428 0 0 1 .034.162Z" />
		</g>
		<defs>
			<clipPath id="classic-nav-a">
				<path fill="#fff" d="M0 0h32v32H0z" />
			</clipPath>
		</defs>
	</svg>,
			},
			{
				label: 'esgProduct',
				icon:
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
		<g clipPath="url(#esg-nav-a)">
			<path fill="#AECC53" fillRule="evenodd" d="M.732 16.028c0-8.42 6.85-15.27 15.27-15.27s15.27 6.85 15.27 15.27-6.85 15.27-15.27 15.27-15.27-6.85-15.27-15.27Zm15.27 11.239c-6.199 0-11.24-5.042-11.24-11.239 0-6.196 5.041-11.24 11.24-11.24 6.2 0 11.241 5.041 11.241 11.24 0 6.2-5.044 11.239-11.24 11.239Z" clipRule="evenodd" />
			<path fill="#AECC53" d="M9.091 20.402C7.337 10.737 14.01 7.519 24.106 8.807c-2.813 2.278-2.738 2.9-3.448 5.598-.669 2.543-2.918 7.716-11.567 5.997Z" />
			<path fill="#629218" d="M8.89 23.608c.994-4.052 3.474-10.03 10.866-13.18-3.86.536-9.093 3.842-11.768 12.8l.901.38Z" />
		</g>
		<defs>
			<clipPath id="esg-nav-a">
				<path fill="#fff" d="M0 0h32v32H0z" />
			</clipPath>
		</defs>
	</svg>,
			},
			{
				label: 'childProduct',
				icon:
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
		<g fill="#A6516C" fillRule="evenodd" clipPath="url(#child-nav-a)" clipRule="evenodd">
			<path d="M16 .738C7.58.738.73 7.588.73 16.008S7.58 31.279 16 31.279s15.27-6.85 15.27-15.27S24.42.739 16 .739ZM4.76 16.008c0 6.197 5.043 11.239 11.24 11.239s11.24-5.04 11.24-11.238c0-6.2-5.043-11.241-11.24-11.241S4.76 9.812 4.76 16.008Z" />
			<path d="M16.282 11.1c1.49-1.396 3.268-1.698 5.143-.878 1.72.752 2.713 2.194 2.799 4.06v.019c-.015 1.526-.438 2.656-1.325 3.553l-.496.503a87.874 87.874 0 0 1-2.305 2.283c-1.096 1.032-2.105 2.118-3.218 3.332-.204.223-.462.454-.804.46h-.012c-.34 0-.61-.23-.809-.43a296.046 296.046 0 0 0-2.78-2.78l-.152-.15c-1.054-1.044-2.14-2.12-3.188-3.199a4.73 4.73 0 0 1-1.187-4.59 4.697 4.697 0 0 1 3.344-3.314c1.656-.445 3.185-.061 4.423 1.108.151.143.224.212.297.211.068 0 .136-.064.27-.189Z" />
		</g>
		<defs>
			<clipPath id="child-nav-a">
				<path fill="#fff" d="M0 0h32v32H0z" />
			</clipPath>
		</defs>
	</svg>,
			},
			{
				label: 'pensionProduct',
				icon:
	<svg id="url(#pension-other-product-a)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.24 128.03">
		<path fill="#a46114" d="m64.54,1.97C30.24,1.97,2.43,29.78,2.43,64.08s27.81,62.11,62.11,62.11,62.11-27.81,62.11-62.11S98.84,1.97,64.54,1.97Zm.12,110.41c-26.71,0-48.36-21.66-48.36-48.36S37.95,15.66,64.66,15.66s48.36,21.65,48.36,48.36-21.65,48.36-48.36,48.36Z" />
		<path fill="#a46114" d="m90.82,36.18v-2.78c0-.41-.33-.75-.75-.75h-51.06c-.41,0-.75.34-.75.75v2.78c0,.42.34.75.75.75h6.38c-.02.06-.04.12-.04.19l-.2,15.96c0,.22.09.42.26.57l12.09,10.72-12.09,10.73c-.17.14-.26.35-.26.57l.19,15.55h-6.33c-.41,0-.75.34-.75.75v2.78c0,.42.34.75.75.75h51.06c.42,0,.75-.33.75-.75v-2.78c0-.41-.33-.75-.75-.75h-5.93l.18-15.55c0-.22-.09-.43-.25-.57l-12.09-10.73,12.09-10.72c.16-.15.26-.35.25-.57l-.19-15.96c0-.07-.02-.13-.04-.19h5.98c.42,0,.75-.33.75-.75Zm-8.01,16.58l-12.46,11.05c-.11.1-.18.23-.21.36-.03.07-.04.13-.04.2,0,.22.09.42.25.57l12.47,11.06-.11,9.03-.07,5.84h-35.8l-.08-6.24-.1-8.63,12.47-11.06c.16-.15.25-.35.25-.57,0-.16-.05-.31-.14-.44-.03-.04-.07-.08-.11-.12l-12.47-11.06v-.05l.18-14.82h35.8l.18,14.87h-.01Z" />
		<path fill="#fcc00" d="m66.08,76.8l16.63,8.23-.07,5.84h-35.8l-.08-6.24,16.9-7.66v-9.12l-4.42-3.92s-.07-.08-.11-.12l-12.47-11.06v-.05l36.15.06-12.46,11.05c-.11.1-.18.23-.21.36l-4.06,3.62v9.01Z" />
	</svg>,
			},
			{
				label: 'studentProduct',
				icon:
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.24 128.03">
		<path fill="#60b5e6" d="M126.65 64.02a62.11 62.11 0 1 1-124.23 0 62.11 62.11 0 0 1 124.23 0" />
		<path fill="#fff" d="M113.25 64.02a48.36 48.36 0 1 1-96.74 0 48.36 48.36 0 0 1 96.75 0" />
		<path fill="#1e0e09" d="M90.27 61.18v17.2c0 5.22-11.42 9.45-25.5 9.45-7.65 0-14.51-1.25-19.18-3.23V63.92l13.23 5.26 5.67 2.26 5.77-2.3 20.01-7.96Z" />
		<path fill="#3c4347" d="m107.93 54.15-17.66 7.03-20.01 7.96-5.77 2.3-5.67-2.26-13.23-5.26v-.1l7.5-2.9 14.34-5.55-7.81-2.59-22.11 7.92-16.47-6.55 43.45-14.34 43.44 14.34z" />
		<path fill="#e84917" d="m67.43 55.37-14.34 5.55-7.5 2.9v32.84l-8.44-4.48.01-31.35.35-.13 22.11-7.92 7.81 2.59z" />
	</svg>,
			},
		],
	},
	{
		label: 'ourApproach',
	},
	{
		label: 'fees',
	},
	{
		label: 'contact',
	},
	language !== 'en' && {
		label: 'blog',
	},
].filter(Boolean);
const navProspectZoneList = [
	{
		label: 'onboarding',
	},
	{
		label: 'referrals',
	},
	{
		label: 'offers',
	},
];
const navClientZoneList = (user, childrenList, offers) => [
	{
		label: 'dashboard',
	},
	{
		label: 'portfolio',
		portfolioSelect: true,
	},
	{
		label: 'history',
		portfolioSelect: true,
	},
	{
		label: 'referrals',
	},
	offers.length > 0 && {
		label: 'offers',
		notification: user?.affiliate_offer_unseen_count > 0,
	},
	(user === null || user.activated_at === null || new Date(user.activated_at) < new Date(2025, 0, 1)) && {
		label: 'taxes',
	},
	user !== null && !user.child && (
		user.aml_check_due
		|| user.document_expiry_check_due
		|| user.personal_information_check_due
		|| user.questionnaire_check_due
		|| user.child_check_due
	) && {
		label: 'clientCheck',
		notification: true,
	},
	user !== null && !user.child && !!(
		Object.values(user.documents?.contractMissing ?? {}).some(Boolean)
		|| childrenList?.some(({ documents }) => !!documents?.contractMissing)
	) && {
		label: 'signNewAgreement',
		notification: true,
	},
].filter(Boolean);

const useFetchOffers = useFetchWithReload(fetchOffers);
const useFetchChildrenList = useFetchWithReload(fetchChildrenList);

const dropdownRefInitial = {
	products: null,
	portfolio: null,
	history: null,
};

const dropdownOpenInitial = {
	products: [false, null],
	portfolio: [false, null],
	history: [false, null],
};

export default function Header({
	accountUrl,
	baseUrls,
	childOnboardingUrl,
	conditionsUrl,
	clientZone,
	future,
	parentOnboardingUrl,
	signNewAgreementUrl,
	urls,
}) {
	const headerInnerEl = useRef(null);
	const navToggleEl = useRef(null);
	const navWrapEl = useRef(null);
	const dropdownRef = useRef(dropdownRefInitial);
	const lastUserRef = useRef(null);
	const [dropdownOpen, setDropdownOpen] = useState(dropdownOpenInitial);
	const [navWrapOpen, setNavWrapOpen] = useState(false);
	const [headerHeight, setHeaderHeight] = useState(null);
	const [navWrapHeight, setNavWrapHeight] = useState(null);
	const [hasAppBanner, setHasAppBanner] = useState(false);
	const [hasInfoBox, setHasInfoBox] = useState(false);
	const [t] = useTranslation();
	const [user] = useUserContext();
	const logout = useUserLogout();
	const windowSize = useWindowSize();
	const hasBox = hasAppBanner || hasInfoBox;
	const hasWindow = typeof window === 'object';
	const country = useCountry();
	const language = useLanguage();
	const [childrenList, , , , reloadChildrenList] = useFetchChildrenList(null, user?.child);
	const [offers, , , , reloadOffers] = useFetchOffers([], country, language);

	useEffect(() => {
		if (lastUserRef.current === user) {
			return;
		}

		if (lastUserRef.current !== null) {
			reloadChildrenList();
			reloadOffers();
		}
		lastUserRef.current = user;
	}, [user]);

	const handleResize = () => {
		setHeaderHeight(headerInnerEl.current?.clientHeight || null);
	};

	const handleNavLinkClick = (e) => {
		e.currentTarget.blur();
		setNavWrapOpen(false);
		setDropdownOpen(dropdownOpenInitial);
	};

	const handleDropdownClick = (e, label) => {
		e.currentTarget.blur();
		setDropdownOpen((prevDropDownOpen) => ({
			...prevDropDownOpen,
			[label]: [!prevDropDownOpen[label][0], null],
		}));
	};

	const handleBackClick = (e, label) => {
		e.currentTarget.blur();
		setDropdownOpen((prevDropDownOpen) => ({
			...prevDropDownOpen,
			[label]: prevDropDownOpen[label][1] !== null
				? [prevDropDownOpen[label][0], null]
				: [false, null],
		}));
	};

	const handleSubNavClick = (e, label, item) => {
		e.currentTarget.blur();
		setDropdownOpen((prevDropDownOpen) => ({
			...prevDropDownOpen,
			[label]: [prevDropDownOpen[label][0], item],
		}));
	};

	const handleLogout = () => {
		logout(clientZone ? urls.homepage.url : null);
	};

	useEffect(() => {
		setNavWrapHeight(navWrapOpen ? navWrapEl.current.scrollHeight : null);
	}, [navWrapOpen]);

	useEffect(handleResize, [hasBox, offers]);

	useEffect(() => {
		if (!hasWindow) {
			return undefined;
		}
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [hasWindow]);

	useEffect(() => {
		if (!navWrapOpen) {
			return undefined;
		}

		const handleClick = (e) => {
			if (navWrapEl.current.contains(e.target) || e.target === navToggleEl.current) {
				return;
			}
			setNavWrapOpen(false);
		};

		document.addEventListener('mousedown', handleClick);
		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, [navWrapOpen]);

	useEffect(() => {
		const handleClick = (e) => {
			const clicked = Object.keys(dropdownRef.current).reduce((acc, key) => {
				const el = dropdownRef.current[key] ?? null;
				acc[key] = el !== null && (el.contains(e.target) || e.target === el);
				return acc;
			}, {});

			setDropdownOpen((prevDropDownOpen) => Object.keys(prevDropDownOpen).reduce((acc, key) => {
				acc[key] = clicked[key] ? prevDropDownOpen[key] : [false, null];
				return acc;
			}, {}));
		};

		document.addEventListener('mousedown', handleClick);
		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	let navList;
	if (!clientZone) {
		navList = navPublicList(language);
	} else if (user !== null && !user.active_client) {
		navList = navProspectZoneList;
	} else if (user !== null && user.legacy_client) {
		navList = [{ label: 'signAgreement' }];
	} else if (user !== null && user.contract_missing) {
		navList = [{ label: 'signNewAgreement' }];
	} else if (user !== null && user.client_zone_locked) {
		navList = [{ label: 'dashboard' }, { label: 'clientCheck' }];
	} else {
		navList = navClientZoneList(user, childrenList, offers);
	}

	const futureClass = future && isScreenWidthUp(windowSize.width, 'md') ? styles.white : '';

	const userNav = user !== null ? (
		<ul className={`${styles.navUser} ${styles.navAccount}`}>
			<li>
				<Link
					className={`${styles.navButton} ${styles.navLogin}`}
					onClick={handleNavLinkClick}
					to={user.active_client ? urls.account.url : urls.onboarding.url}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 448 512"
					>
						<path
							fill="currentColor"
							d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
						/>
					</svg>
					{t('header.myAccount')}
				</Link>
			</li>
			<li>
				<button className={styles.navButton} onClick={handleLogout} title={t('header.logout')} type="button">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 471.2 471.2" height="20" width="20" fill="#fff">
						<path d="M227.619 444.2h-122.9c-33.4 0-60.5-27.2-60.5-60.5V87.5c0-33.4 27.2-60.5 60.5-60.5h124.9c7.5 0 13.5-6 13.5-13.5s-6-13.5-13.5-13.5h-124.9c-48.3 0-87.5 39.3-87.5 87.5v296.2c0 48.3 39.3 87.5 87.5 87.5h122.9c7.5 0 13.5-6 13.5-13.5s-6.1-13.5-13.5-13.5z" />
						<path d="M450.019 226.1l-85.8-85.8c-5.3-5.3-13.8-5.3-19.1 0-5.3 5.3-5.3 13.8 0 19.1l62.8 62.8h-273.9c-7.5 0-13.5 6-13.5 13.5s6 13.5 13.5 13.5h273.9l-62.8 62.8c-5.3 5.3-5.3 13.8 0 19.1 2.6 2.6 6.1 4 9.5 4s6.9-1.3 9.5-4l85.8-85.8c5.4-5.4 5.4-14 .1-19.2z" />
					</svg>
				</button>
			</li>
			{isScreenWidthUp(windowSize.width, 'md') && (
				<li className={styles.languageToggleWrap}>
					<LanguageToggle allCountries={!clientZone} baseUrls={baseUrls} />
				</li>
			)}
		</ul>
	) : (
		<ul className={styles.navUser}>
			<li>
				<Link
					className={`${styles.navButton} ${styles.navLogin}`}
					onClick={handleNavLinkClick}
					to={urls.login.url}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 448 512"
					>
						<path
							fill="currentColor"
							d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
						/>
					</svg>
					{t('header.login')}
				</Link>
			</li>
			<li>
				<Link
					className={styles.navButton}
					onClick={handleNavLinkClick}
					to={urls.registration.url}
				>
					{t('header.register')}
				</Link>
			</li>
			{isScreenWidthUp(windowSize.width, 'md') && (
				<li className={styles.languageToggleWrap}>
					<LanguageToggle allCountries={!clientZone} baseUrls={baseUrls} />
				</li>
			)}
		</ul>
	);

	const nav = (
		<ul className={styles.nav}>
			{!clientZone && user !== null && (
				<li>
					<Link
						className={`${styles.navLink} ${futureClass}`.trim()}
						onClick={handleNavLinkClick}
						to={user.active_client ? urls.portfolio.url : urls.onboarding.url}
					>
						{t(user.active_client ? 'header.ownPortfolio' : 'header.onboarding')}
					</Link>
				</li>
			)}
			{navList.map((item, i) => {
				const key = `navItem${i}`;

				return !item.subnav ? (item.url ?? urls[item.label]?.url ?? null) !== null && (
					<li key={key}>
						{item.portfolioSelect ? (
							<div
								ref={(el) => { dropdownRef.current[item.label] = el; }}
								className={styles.dropdownWrap}
							>
								<button
									className={`
										${styles.navLink}
										${styles.dropdown}
										${futureClass}
										${urls[item.label].active ? styles.isNavLinkActive : ''}
									`.trim()}
									onClick={(e) => handleDropdownClick(e, item.label)}
									type="button"
								>
									{t(`header.${item.label}`)}
								</button>
								<PortfolioSelect
									accountUrl={accountUrl}
									childOnboardingUrl={childOnboardingUrl}
									childrenList={childrenList}
									conditionsUrl={conditionsUrl}
									isOpen={dropdownOpen[item.label][0]}
									label={item.label}
									onBackClick={handleBackClick}
									onNavLinkClick={handleNavLinkClick}
									onSubNavClick={handleSubNavClick}
									pageUrl={item.url ?? urls[item.label].url}
									parentOnboardingUrl={parentOnboardingUrl}
									signNewAgreementUrl={signNewAgreementUrl}
									subNavOpen={dropdownOpen[item.label][1]}
								/>
							</div>
						) : (
							<Link
								className={`
									${styles.navLink}
									${futureClass}
									${item.notification ? styles.newOffer : ''}
									${urls[item.label].active ? styles.isNavLinkActive : ''}
								`.trim()}
								onClick={handleNavLinkClick}
								to={item.url ?? urls[item.label].url}
							>
								{t(`header.${item.label}`)}
							</Link>
						)}
					</li>
				) : (
					<li
						key={key}
						ref={(el) => { dropdownRef.current[item.label] = el; }}
						className={`${dropdownOpen[item.label][0] ? styles.isDropdownOpen : ''}`.trim()}
					>
						<button
							className={`
								${styles.navLink}
								${styles.dropdown}
								${futureClass}
							`.trim()}
							onClick={(e) => handleDropdownClick(e, item.label)}
							type="button"
						>
							{t(`header.${item.label}`)}
						</button>
						<ul className={styles.subNav}>
							<button
								className={styles.subNavClose}
								onClick={(e) => handleBackClick(e, item.label)}
								type="button"
							>
								{t('header.productsList.button')}
							</button>
							{item.subnav.map((subItem) => (urls[subItem.label]?.url ?? null) !== null && (
								<li className={styles.subNavItem} key={subItem.label}>
									<Link
										className={styles.subNavLink}
										onClick={handleNavLinkClick}
										to={urls[subItem.label].url}
									>
										<span className={styles.subNavLinkIcon}>
											{subItem.icon}
										</span>
										<div className={styles.subNavContent}>
											<p className={styles.subNavTitle}>{t(`header.productsList.${subItem.label}.title`)}</p>
											<p className={styles.subNavSubTitle}>{t(`header.productsList.${subItem.label}.subTitle`)}</p>
										</div>
									</Link>
								</li>
							))}
						</ul>
					</li>
				);
			})}
			{user !== null && (
				<li>
					<button
						className={`${styles.navLink} ${styles.navLogout}`}
						onClick={handleLogout}
						type="button"
					>
						{t('header.logout')}
					</button>
				</li>
			)}
			{!isScreenWidthUp(windowSize.width, 'md') && (
				<li className={styles.languageToggleWrap}>
					<LanguageToggle allCountries={!clientZone} baseUrls={baseUrls} setNavWrapOpen={setNavWrapOpen} />
				</li>
			)}
		</ul>
	);

	return (
		<div
			className={`${styles.root} ${urls.newspaper.active ? styles.newspaperHomepage : ''} ${hasBox ? styles.rootHasBox : ''}`.trim()}
			style={{ height: urls.homepage.active ? null : headerHeight }}
		>
			<div ref={headerInnerEl} className={`${styles.inner} ${(clientZone && urls.dashboard.active) ? styles.innerHasShadow : ''}`.trim()}>
				<AppBanner
					setHasAppBanner={setHasAppBanner}
				/>
				<InfoBox
					clientZone={clientZone}
					isLogged={user !== null}
					setHasHeaderInfoBox={setHasInfoBox}
				/>
				<div className={styles.container}>
					<div className={styles.logoWrap}>
						<Link
							className={`${styles.logo} ${futureClass}`.trim()}
							onClick={(e) => e.currentTarget.blur()}
							title="Fondee"
							to={urls.homepage.url}
						>
							<Logo />
						</Link>
					</div>
					{user !== null && !navWrapOpen && (
						<div className={styles.navLoginWrap}>
							<Link
								className={`${styles.navButton} ${styles.navLogin}`}
								onClick={handleNavLinkClick}
								to={user.active_client ? urls.account.url : urls.onboarding.url}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 448 512"
								>
									<path
										fill="currentColor"
										d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
									/>
								</svg>
							</Link>
						</div>
					)}
					{user === null && !isScreenWidthUp(windowSize.width, 'md') && (
						<div className={styles.registrationButtonWrap}>
							<ButtonLink
								label={t('header.register')}
								onClick={(e) => e.currentTarget.blur()}
								to={urls.registration.url}
								isSmall
							/>
						</div>
					)}
					<button
						ref={navToggleEl}
						className={styles.navToggle}
						onClick={() => setNavWrapOpen(!navWrapOpen)}
						title={t('header.menu')}
						type="button"
					>
						{navWrapOpen ? (
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={24} height={24} viewBox="0 0 24 24">
								<mask id="opened-menu-icon-a" width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
									<path fill="#D9D9D9" d="M0 0h24v24H0z" />
								</mask>
								<g mask="url(#opened-menu-icon-a)">
									<path fill="#47202A" d="M18.66 6.34c0 .27-.1.51-.3.71L13.41 12l4.95 4.95a.97.97 0 0 1 0 1.41.97.97 0 0 1-1.41 0L12 13.41l-4.95 4.95a.97.97 0 0 1-1.41 0 .97.97 0 0 1-.3-.7c0-.27.1-.51.3-.71L10.59 12 5.64 7.05a.97.97 0 0 1-.3-.7c0-.28.1-.51.3-.71a.97.97 0 0 1 1.41 0L12 10.59l4.95-4.95a.97.97 0 0 1 1.41 0c.2.2.3.43.3.7Z" />
								</g>
							</svg>
						) : (
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={24} height={24} viewBox="0 0 24 24">
								<mask id="closed-menu-icon-a" width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
									<path fill="#D9D9D9" d="M0 0h24v24H0z" />
								</mask>
								<g mask="url(#closed-menu-icon-a)">
									<path fill="#47202A" d="M4 18a.97.97 0 0 1-.71-.29A.97.97 0 0 1 3 17c0-.28.1-.52.29-.71.19-.2.43-.29.71-.29h16c.28 0 .52.1.71.29.2.19.29.43.29.71 0 .28-.1.52-.29.71A.94.94 0 0 1 20 18H4Zm0-5a.97.97 0 0 1-.71-.29A.97.97 0 0 1 3 12c0-.28.1-.52.29-.71.19-.2.43-.29.71-.29h16c.28 0 .52.1.71.29.2.19.29.43.29.71 0 .28-.1.52-.29.71A.94.94 0 0 1 20 13H4Zm0-5a.97.97 0 0 1-.71-.29A.97.97 0 0 1 3 7c0-.28.1-.52.29-.71.19-.2.43-.29.71-.29h16c.28 0 .52.1.71.29.2.19.29.43.29.71 0 .28-.1.52-.29.71A.94.94 0 0 1 20 8H4Z" />
								</g>
							</svg>
						)}
					</button>
					<nav
						ref={navWrapEl}
						className={styles.navWrap}
						style={{ maxHeight: navWrapHeight }}
					>
						<div className={styles.navWrapInner}>
							{userNav}
							{nav}
						</div>
					</nav>
				</div>
			</div>
		</div>
	);
}

Header.propTypes = {
	accountUrl: PropTypes.string.isRequired,
	baseUrls: PropTypes.objectOf(PropTypes.string.isRequired),
	childOnboardingUrl: PropTypes.string.isRequired,
	conditionsUrl: PropTypes.string.isRequired,
	clientZone: PropTypes.bool,
	future: PropTypes.bool,
	parentOnboardingUrl: PropTypes.string.isRequired,
	signNewAgreementUrl: PropTypes.string.isRequired,
	urls: PropTypes.shape(
		PropTypes.shape({
			url: PropTypes.string.isRequired,
			active: PropTypes.bool.isRequired,
		}).isRequired,
	).isRequired,
};

Header.defaultProps = {
	baseUrls: null,
	clientZone: false,
	future: false,
};
