import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { useUserContext } from '../../../contexts/UserContext';
import { fetchProfileContract, getPensionConfirmation, getProfileContract } from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import useFetchAuth from '../../../utils/useFetchAuth';
import { Container, Section } from '../../layout';
import DocumentFile from '../DocumentFile';

import styles from './Documents.module.scss';

const useFetchProfileContract = useFetchAuth(fetchProfileContract);

export default function Documents({
	complaintsProcedureUrl,
	conditionsUrl,
	esgInfolistUrl,
	esgSustainabilityUrl,
	informationSheetUrl,
	personalInfoAgreementUrl,
	sectionWrap,
	termsUrl,
}) {
	const [profileContract, loading, error] = useFetchProfileContract(null);
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const trackGTMEvent = useTrackGTMEvent();
	const [user] = useUserContext();
	const pensionPortfolioId = user?.pensionPortfolioId ?? null;

	const agreementTemplates = [
		'standard',
		'child',
		t('documentUrls.agreementTemplate.pension') !== t('documentUrls.agreementTemplate.standard') && 'pension',
	].filter(Boolean).map((category) => ({
		category,
		label: t(`documents.agreementTemplate.${category}`),
		url: t(`documentUrls.agreementTemplate.${category}`),
	}));

	const annualReportUrl2021 = t('documentUrls.annualReport2021');
	const annualReportUrl2022 = t('documentUrls.annualReport2022');
	const annualReportUrl2023 = t('documentUrls.annualReport2023');

	const annualReportTemplatesUrl2021 = t('documentUrls.annualReportTemplates2021');
	const annualReportTemplatesUrl2022 = t('documentUrls.annualReportTemplates2022');
	const annualReportTemplatesUrl2023 = t('documentUrls.annualReportTemplates2023');

	const tradingVenuesUrl = t('documentUrls.tradingVenues');

	const ordersExecutionUrl = t('documentUrls.ordersExecution');

	const list = (
		<div className={styles.list}>
			<div>
				<h2 className={styles.title}>{t('documents.title')}</h2>
				<p className={styles.subtitle}>{t('documents.subtitle')}</p>
				<ul>
					{!loading && !error && profileContract?.length > 0
						&& profileContract.map((contract) => (
							<li key={contract.id} className={styles.item}>
								<DocumentFile
									childContract={contract.child}
									firstName={contract.firstName}
									label={t(contract.type === 'legalRepresentativeDeclarationSigned' ? 'documents.legalRepresentativeDeclaration' : 'documents.contract')}
									onClick={() => {
										trackGTMEvent('interactions', {
											eventCategory: 'interactions',
											eventAction: 'linkClick',
											eventLabel: contract.name,
										});
									}}
									to={getProfileContract(contract.id, contract.type, country, language)}
								/>
							</li>
						))}
					{pensionPortfolioId && (
						<>
							<li className={styles.item}>
								<DocumentFile
									label={t('documents.pensionConfirmationAccount')}
									onClick={() => {
										trackGTMEvent('interactions', {
											eventCategory: 'interactions',
											eventAction: 'linkClick',
											eventLabel: 'pension-confirmation',
										});
									}}
									to={getPensionConfirmation(pensionPortfolioId, 'account')}
								/>
							</li>
							<li className={styles.item}>
								<DocumentFile
									label={t('documents.pensionConfirmationDeposit')}
									onClick={() => {
										trackGTMEvent('interactions', {
											eventCategory: 'interactions',
											eventAction: 'linkClick',
											eventLabel: 'pension-confirmation-deposit',
										});
									}}
									to={getPensionConfirmation(pensionPortfolioId, 'deposit')}
								/>
							</li>
							{!!user?.pensionEmployerDepositConfirmation && (
								<li className={styles.item}>
									<DocumentFile
										label={t('documents.pensionConfirmationEmployerDeposit')}
										onClick={() => {
											trackGTMEvent('interactions', {
												eventCategory: 'interactions',
												eventAction: 'linkClick',
												eventLabel: 'pension-confirmation-employer-deposit',
											});
										}}
										to={getPensionConfirmation(pensionPortfolioId, 'employerDeposit')}
									/>
								</li>
							)}
						</>
					)}
					<li className={styles.item}>
						<DocumentFile
							label={t('documents.conditions')}
							onClick={() => {
								trackGTMEvent('interactions', {
									eventCategory: 'interactions',
									eventAction: 'linkClick',
									eventLabel: conditionsUrl.substring(conditionsUrl.lastIndexOf('/') + 1)
										.replace(/\.[^/.]+$/, ''),
								});
							}}
							to={conditionsUrl}
						/>
					</li>
					<li className={styles.item}>
						<DocumentFile
							label={t('documents.terms')}
							onClick={() => {
								trackGTMEvent('interactions', {
									eventCategory: 'interactions',
									eventAction: 'linkClick',
									eventLabel: termsUrl.substring(termsUrl.lastIndexOf('/') + 1)
										.replace(/\.[^/.]+$/, ''),
								});
							}}
							to={termsUrl}
						/>
					</li>
					<li className={styles.item}>
						<DocumentFile
							label={t('documents.personalInfoAgreement')}
							onClick={() => {
								trackGTMEvent('interactions', {
									eventCategory: 'interactions',
									eventAction: 'linkClick',
									eventLabel: personalInfoAgreementUrl.substring(personalInfoAgreementUrl.lastIndexOf('/') + 1)
										.replace(/\.[^/.]+$/, ''),
								});
							}}
							to={personalInfoAgreementUrl}
						/>
					</li>
					<li className={styles.item}>
						<DocumentFile
							label={t('documents.informationSheet')}
							onClick={() => {
								trackGTMEvent('interactions', {
									eventCategory: 'interactions',
									eventAction: 'linkClick',
									eventLabel: informationSheetUrl.substring(informationSheetUrl.lastIndexOf('/') + 1)
										.replace(/\.[^/.]+$/, ''),
								});
							}}
							to={informationSheetUrl}
						/>
					</li>
					{agreementTemplates.map(({
						category,
						label,
						url,
					}) => (
						<li key={category} className={styles.item}>
							<DocumentFile
								label={label}
								onClick={() => {
									trackGTMEvent('interactions', {
										eventCategory: 'interactions',
										eventAction: 'linkClick',
										eventLabel: url.substring(url.lastIndexOf('/') + 1)
											.replace(/\.[^/.]+$/, ''),
									});
								}}
								to={url}
							/>
						</li>
					))}
					<li className={styles.item}>
						<DocumentFile
							label={t('documents.complaintsProcedure')}
							onClick={() => {
								trackGTMEvent('interactions', {
									eventCategory: 'interactions',
									eventAction: 'linkClick',
									eventLabel: complaintsProcedureUrl.substring(complaintsProcedureUrl.lastIndexOf('/') + 1)
										.replace(/\.[^/.]+$/, ''),
								});
							}}
							to={complaintsProcedureUrl}
						/>
					</li>
				</ul>
			</div>
			<div>
				<h2 className={styles.title}>{t('documents.secondTitle')}</h2>
				<p className={styles.subtitle}>{t('documents.reportsTitle')}</p>
				<ul>
					<li className={styles.item}>
						<DocumentFile
							label={t('documents.annualReport2023')}
							onClick={() => {
								trackGTMEvent('interactions', {
									eventCategory: 'interactions',
									eventAction: 'linkClick',
									eventLabel: annualReportUrl2023.substring(annualReportUrl2023.lastIndexOf('/') + 1)
										.replace(/\.[^/.]+$/, ''),
								});
							}}
							to={annualReportUrl2023}
						/>
					</li>
					<li className={styles.item}>
						<DocumentFile
							label={t('documents.annualReport2022')}
							onClick={() => {
								trackGTMEvent('interactions', {
									eventCategory: 'interactions',
									eventAction: 'linkClick',
									eventLabel: annualReportUrl2022.substring(annualReportUrl2022.lastIndexOf('/') + 1)
										.replace(/\.[^/.]+$/, ''),
								});
							}}
							to={annualReportUrl2022}
						/>
					</li>
					<li className={styles.item}>
						<DocumentFile
							label={t('documents.annualReport2021')}
							onClick={() => {
								trackGTMEvent('interactions', {
									eventCategory: 'interactions',
									eventAction: 'linkClick',
									eventLabel: annualReportUrl2021.substring(annualReportUrl2021.lastIndexOf('/') + 1)
										.replace(/\.[^/.]+$/, ''),
								});
							}}
							to={annualReportUrl2021}
						/>
					</li>
				</ul>

				<p className={styles.subtitle}>{t('documents.otherTitle')}</p>
				<ul>
					<li className={styles.item}>
						<DocumentFile
							label={t('documents.ordersExecution')}
							onClick={() => {
								trackGTMEvent('interactions', {
									eventCategory: 'interactions',
									eventAction: 'linkClick',
									eventLabel: ordersExecutionUrl.substring(ordersExecutionUrl.lastIndexOf('/') + 1)
										.replace(/\.[^/.]+$/, ''),
								});
							}}
							to={ordersExecutionUrl}
							pdf
						/>
					</li>
					<li className={styles.item}>
						<DocumentFile
							label={t('documents.annualReportTemplates2023')}
							onClick={() => {
								trackGTMEvent('interactions', {
									eventCategory: 'interactions',
									eventAction: 'linkClick',
									eventLabel: annualReportTemplatesUrl2023.substring(annualReportTemplatesUrl2023.lastIndexOf('/') + 1)
										.replace(/\.[^/.]+$/, ''),
								});
							}}
							to={annualReportTemplatesUrl2023}
							xlsx
						/>
					</li>
					<li className={styles.item}>
						<DocumentFile
							label={t('documents.annualReportTemplates2022')}
							onClick={() => {
								trackGTMEvent('interactions', {
									eventCategory: 'interactions',
									eventAction: 'linkClick',
									eventLabel: annualReportTemplatesUrl2022.substring(annualReportTemplatesUrl2022.lastIndexOf('/') + 1)
										.replace(/\.[^/.]+$/, ''),
								});
							}}
							to={annualReportTemplatesUrl2022}
							xlsx
						/>
					</li>
					<li className={styles.item}>
						<DocumentFile
							label={t('documents.annualReportTemplates2021')}
							onClick={() => {
								trackGTMEvent('interactions', {
									eventCategory: 'interactions',
									eventAction: 'linkClick',
									eventLabel: annualReportTemplatesUrl2021.substring(annualReportTemplatesUrl2021.lastIndexOf('/') + 1)
										.replace(/\.[^/.]+$/, ''),
								});
							}}
							to={annualReportTemplatesUrl2021}
							xlsx
						/>
					</li>
					<li className={styles.item}>
						<DocumentFile
							label={t('documents.tradingVenues')}
							onClick={() => {
								trackGTMEvent('interactions', {
									eventCategory: 'interactions',
									eventAction: 'linkClick',
									eventLabel: tradingVenuesUrl.substring(tradingVenuesUrl.lastIndexOf('/') + 1)
										.replace(/\.[^/.]+$/, ''),
								});
							}}
							to={tradingVenuesUrl}
						/>
					</li>
				</ul>
			</div>
			<div>
				<h2 className={styles.title}>{t('documents.esgTitle')}</h2>
				<ul>
					<li className={styles.item}>
						<DocumentFile
							label={t('documents.esgInfolist')}
							onClick={() => {
								trackGTMEvent('interactions', {
									eventCategory: 'interactions',
									eventAction: 'linkClick',
									eventLabel: esgInfolistUrl.substring(esgInfolistUrl.lastIndexOf('/') + 1)
										.replace(/\.[^/.]+$/, ''),
								});
							}}
							to={esgInfolistUrl}
						/>
					</li>
					<li className={styles.item}>
						<DocumentFile
							label={t('documents.esgSustainability')}
							onClick={() => {
								trackGTMEvent('interactions', {
									eventCategory: 'interactions',
									eventAction: 'linkClick',
									eventLabel: esgSustainabilityUrl.substring(esgSustainabilityUrl.lastIndexOf('/') + 1)
										.replace(/\.[^/.]+$/, ''),
								});
							}}
							to={esgSustainabilityUrl}
						/>
					</li>
				</ul>
			</div>
		</div>
	);

	return (
		(sectionWrap ? (
			<Section>
				<Container>
					{list}
				</Container>
			</Section>
		) : (
			<Container>
				{list}
			</Container>
		))
	);
}

Documents.propTypes = {
	complaintsProcedureUrl: PropTypes.string.isRequired,
	conditionsUrl: PropTypes.string.isRequired,
	esgInfolistUrl: PropTypes.string.isRequired,
	esgSustainabilityUrl: PropTypes.string.isRequired,
	informationSheetUrl: PropTypes.string.isRequired,
	personalInfoAgreementUrl: PropTypes.string.isRequired,
	sectionWrap: PropTypes.bool,
	termsUrl: PropTypes.string.isRequired,
};

Documents.defaultProps = {
	sectionWrap: true,
};
