/* global document */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
	ClientHeader,
	NoActivePortfolio,
	PortfolioComposition,
	PortfolioFees,
	PortfolioHeader,
	PortfolioOverview,
	PortfolioSettings,
	PortfolioWithdrawals,
	TradingStates,
} from '../../components/common';
import {
	Col,
	Container,
	Row,
	Section,
} from '../../components/layout';
import { Loader } from '../../components/ui';
import { useCountry, useLanguage } from '../../contexts/LocaleContext';
import { useUserContext } from '../../contexts/UserContext';
import {
	fetchProfilePortfolio,
	fetchProfilePortfolioCurrency,
	fetchProfilePortfolioDetail,
	fetchUserCurrency,
} from '../../utils/api';
import { useSessionPortfolio } from '../../utils/sessionPortfolio';
import useFetchAuth from '../../utils/useFetchAuth';
import { useFetchWithHardReload } from '../../utils/useFetchWithReload';

import styles from './Portfolio.module.scss';

const useFetchProfilePortfolio = useFetchWithHardReload(fetchProfilePortfolio);
const useFetchProfilePortfolioCurrency = useFetchAuth(fetchProfilePortfolioCurrency);
const useFetchProfilePortfolioDetail = useFetchAuth(fetchProfilePortfolioDetail);
const useFetchUserCurrency = useFetchAuth(fetchUserCurrency);

const increment = (value) => value + 1;

export default function Portfolio({
	accountUrl,
	childOnboardingUrl,
	conditionsUrl,
	faqUrl,
	parentOnboardingUrl,
	proxyId,
	proxyPortfolioId,
	signNewAgreementUrl,
}) {
	const isProxy = proxyId !== null;
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const [portfolios, loading, error, , reloadPortfolios] = useFetchProfilePortfolio(null, language, null, isProxy);
	const [portfolioId, portfolio] = useSessionPortfolio(!loading && !error ? portfolios : null, proxyPortfolioId);
	const [portfolioDetail, loadingDetail, errorDetail] = useFetchProfilePortfolioDetail(
		null,
		portfolioId,
		language,
		proxyId,
	);
	const [portfolioCurrencies] = useFetchProfilePortfolioCurrency(null, portfolioId, language, isProxy);
	const [userCurrencies] = useFetchUserCurrency(null, language, isProxy);
	const [currencyChange, setCurrencyChange] = useState(false);
	const [topUpModalOpen, setTopUpModalOpen] = useState(false);
	const [withdrawalsReloadCounter, setWithdrawalsReloadCounter] = useState(0);
	const portfolioSettingsAnchor = t('anchors.portfolioSettings');
	const portfolioWithdrawalsAnchor = t('anchors.portfolioWithdrawals');
	const [user] = useUserContext();

	const setCheckPortfolio = useCallback((check) => {
		if (check) {
			setTimeout(() => {
				document.getElementById(portfolioSettingsAnchor)?.scrollIntoView({
					behavior: 'smooth',
				});
			}, 1000);
			reloadPortfolios();
			setWithdrawalsReloadCounter(increment);
		}
	}, [portfolioSettingsAnchor, reloadPortfolios]);

	if (loading || error) {
		return <Loader fullPage />;
	}

	if (!isProxy && portfolios?.active?.length === 0) {
		return (
			<NoActivePortfolio
				accountUrl={accountUrl}
				childOnboardingUrl={childOnboardingUrl}
				conditionsUrl={conditionsUrl}
				currencies={userCurrencies}
				parentOnboardingUrl={parentOnboardingUrl}
				portfolios={portfolios}
				reloadPortfolios={reloadPortfolios}
				signNewAgreementUrl={signNewAgreementUrl}
			/>
		);
	}

	const {
		composition = null,
		feesEtfs = null,
		feesOverview = null,
		overview = null,
		tradingStates = null,
		pensionEmployerDepositConfirmation = false,
	} = (!loadingDetail && !errorDetail ? portfolioDetail : null) ?? {};

	return (
		<div className={styles.root}>
			{!isProxy && (
				<>
					<PortfolioHeader
						portfolio={portfolio}
						portfolios={portfolios}
					/>
					<ClientHeader
						accountUrl={accountUrl}
						faqUrl={faqUrl}
						closeTopUpModal={() => setTopUpModalOpen(false)}
						hasTrading
						isPension={country === 'CZ' && portfolio.categoryName === 'pension'}
						portfolioId={portfolioId}
						portfolios={portfolios?.active ?? null}
						states={tradingStates}
						topUpModalOpen={topUpModalOpen}
					/>
				</>
			)}
			{overview !== null ? (
				<PortfolioOverview portfolio={overview} />
			) : (
				<Loader variant="large" />
			)}
			{composition !== null && (
				<PortfolioComposition
					accountUrl={accountUrl}
					portfolioComposition={composition}
					portfolioCurrency={overview?.currency ?? null}
				/>
			)}
			{tradingStates !== null && (
				<TradingStates
					portfolioCurrency={overview?.currency ?? null}
					portfolioSettingsAnchor={portfolioSettingsAnchor}
					portfolioWithdrawalsAnchor={portfolioWithdrawalsAnchor}
					states={tradingStates}
				/>
			)}
			{!isProxy && portfolio && (
				<Section bordered condensed>
					<Container>
						<Row>
							<Col lg={6}>
								<PortfolioSettings
									country={country}
									currencies={portfolioCurrencies}
									currencyChange={currencyChange}
									isChild={user.child}
									openTopUpModal={() => setTopUpModalOpen(true)}
									portfolio={{
										...portfolio,
										pensionEmployerDepositConfirmation,
									}}
									portfolioSettingsAnchor={portfolioSettingsAnchor}
									setCheckPortfolio={setCheckPortfolio}
									setCurrencyChange={setCurrencyChange}
								/>
							</Col>
							<Col lg={6}>
								<PortfolioWithdrawals
									key={withdrawalsReloadCounter}
									accountUrl={accountUrl}
									currencies={portfolioCurrencies}
									portfolio={portfolio}
									portfolios={portfolios?.active ?? []}
									portfolioTotalValue={overview?.totalValue ?? null}
									portfolioWithdrawalsAnchor={portfolioWithdrawalsAnchor}
									setCheck={setCheckPortfolio}
								/>
							</Col>
						</Row>
					</Container>
				</Section>
			)}
			<PortfolioFees
				feesEtfs={feesEtfs}
				feesOverview={feesOverview}
				isProxy={isProxy}
			/>
		</div>
	);
}

Portfolio.propTypes = {
	accountUrl: PropTypes.string.isRequired,
	childOnboardingUrl: PropTypes.string.isRequired,
	conditionsUrl: PropTypes.string.isRequired,
	faqUrl: PropTypes.string.isRequired,
	parentOnboardingUrl: PropTypes.string.isRequired,
	proxyId: PropTypes.number,
	proxyPortfolioId: PropTypes.number,
	signNewAgreementUrl: PropTypes.string.isRequired,
};

Portfolio.defaultProps = {
	proxyId: null,
	proxyPortfolioId: null,
};
