import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import { getPensionConfirmation } from '../../../../utils/api';
import { ExternalLink } from '../../../ui';

import styles from './PensionConfirmation.module.scss';

export default function PensionConfirmation({
	hasEmployerDeposit,
	portfolioId,
}) {
	return (
		<div className={styles.root}>
			<p className={styles.text}>
				<Trans i18nKey="portfolio.settings.pensionConfirmation.account">
					<ExternalLink
						to={getPensionConfirmation(portfolioId, 'account')}
					/>
				</Trans>
			</p>
			<p className={styles.text}>
				<Trans i18nKey="portfolio.settings.pensionConfirmation.deposit">
					<ExternalLink
						to={getPensionConfirmation(portfolioId, 'deposit')}
					/>
				</Trans>
			</p>
			{hasEmployerDeposit && (
				<p className={styles.text}>
					<Trans i18nKey="portfolio.settings.pensionConfirmation.employerDeposit">
						<ExternalLink
							to={getPensionConfirmation(portfolioId, 'employerDeposit')}
						/>
					</Trans>
				</p>
			)}
		</div>
	);
}

PensionConfirmation.propTypes = {
	hasEmployerDeposit: PropTypes.bool.isRequired,
	portfolioId: PropTypes.number.isRequired,
};
